import React from 'react';

// Styles
import './FieldContainer.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`
    .field-container:hover {
      border-color: ${(props) => props.hoverBorderColor} !important;
    }
`;

// The main component
const FieldContainer = ({ readOnly, onClick, children }) => {
  const { theme } = useTheme();

  return (
    <>
      <InlineStyles
        hoverBorderColor={theme.highlightBackgroundColor}
      />
      <div className={readOnly ? "field-container-read-only" : "field-container"}
        style={{ borderColor: readOnly ? 'transparent' : theme.backgroundColorFaded }}
        onClick={onClick && onClick}>
        {children}
      </div>
    </>
  );
};

export default FieldContainer;
