import React, { createContext, useEffect, useState } from 'react';

export const Global = createContext();

export const GlobalProvider = ({ children }) => {

  // Foundation
  /**
   * Indicates the mode of the form. Possible values are:
   * - ADD
   * - EDIT
   * - VIEW
   */
  const [formMode, setFormMode] = useState("VIEW"); 


  const [appTitle, setAppTitle] = useState("jotspot.ai");
  const [currentUser, setCurrentUser] = useState(null);
  const [eventVisible, setEventVisible] = useState(null);
  const [fieldVisible, setFieldVisible] = useState(false);
  const [invite, setInvite] = useState(null);
  const [loginMode, setLoginMode] = useState("SIGNUP");
  const [loginOpen, setLoginOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [page, setPage] = useState("ROOT");
  const [progressVisible, setProgressVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Ready.");
  const [userRole, setUserRole] = useState(null);
  const [userAccountOpen, setUserAccountOpen] = useState(false);
  const [userSearchVisible, setUserSearchVisible] = useState(false);

  // Studio
  const [currentCanvas, setCurrentCanvas] = useState("FORM");
  const [currentDevice, setCurrentDevice] = useState("MOBILE");
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [rolesCollapsed, setRolesCollapsed] = useState(false);
  const [studio, setStudio] = useState(null);

  // Apps
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);

  // Models
  const [models, setModels] = useState([]);
  const [newModelModalOpen, setNewModelModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  // Fields
  const [appFields, setAppFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldSelectorVisible, setFieldSelectorVisible] = useState(false);
  const [summaryFields, setSummaryFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [fieldFilter, setFieldFilter] = useState("");

  // Roles
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  // Reports
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);

  // Run
  const [appSummaries, setAppSummaries] = useState([]); // By app
  const [changesMade, setChangesMade] = useState(false);
  const [currentObject, setCurrentObject] = useState({}); // Default ADD object is empty
  const [donateVisible, setDonateVisible] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [lastCamera, setLastCamera] = useState(""); // Barcode Scanning
  const [objects, setObjects] = useState([]); // For currently selected model
  const [summaryHeight, setSummaryHeight] = useState("50px");
  const [runHomeVisible, setRunHomeVisible] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [sortField, setSortField] = useState(''); // Object sorting
  const [sortDirection, setSortDirection] = useState('ASC'); // Object sorting
  const [objectsView, setObjectsView] = useState("LIST");

  // Calendar
  const [activeDate, setActiveDate] = useState(null);
  const [backToCalendar, setBackToCalendar] = useState(false);
  const [calendarTitle, setCalendarTitle] = useState("Calendar");
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [defaultEventDate, setDefaultEventDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [monthPickerVisible, setMonthPickerVisible] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState("DAY");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  // Conversations
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);

  // Financials
  const [billingVisible, setBillingVisible] = useState(false);
  const [payAmount, setPayAmount] = useState(0.00);
  const [payMode, setPayMode] = useState("PAYONCE");
  const [payOnceAmount, setPayOnceAmount] = useState(0.00);
  const [payVisible, setPayVisible] = useState(false);
  const [rateVisible, setRateVisible] = useState(false);
  const [targetApp, setTargetApp] = useState("ALL");
  const [timespan, setTimespan] = useState("LAST24"); // CURRENT, LAST60, LAST24, LAST7, LAST30
  const [userApps, setUserApps] = useState([]);

  // Account
  const [accountVisible, setAccountVisible] = useState(false);
  const [accountAboutVisible, setAccountAboutVisible] = useState(false);
  const [accountBillingVisible, setAccountBillingVisible] = useState(false);

  // App Users
  const [appUsers, setAppUsers] = useState([]);
  const [appUsersVisible, setAppUsersVisible] = useState(false);
  const [selectedAppUser, setSelectedAppUser] = useState(null);

  // Reports
  const [reportsVisible, setReportsVisible] = useState(false);

  // Headlines
  const [headlines, setHeadlines] = useState([]);
  const [editingHeadlines, setEditingHeadlines] = useState(false);

  // Designer
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);

  // User Elements
  const [userElements, setUserElements] = useState([]);

  // Groups
  const [groups, setGroups] = useState([]);
  const [selectedLookup, setSelectedLookup] = useState(null);

  // Channels
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelItemAddOpen, setChannelItemAddOpen] = useState(false);

  // Set up the studio theme
  useEffect(() => {

    const data = {
      backgroundColor: "#0f0f0f",
      backgroundColorFaded: "#1F1F1F",
      highlightBackgroundColor: "#5E3023", //"#cc3e20", //"#5087ed"
      highlightForegroundColor: "#FFFFFF", //"#8d4834", 
      foregroundColor: "#FFFFFF",
      foregroundColorFaded: "#999999"
    };

    /* hudl.com
    const data = {
      backgroundColor: "#1a1f24",
      backgroundColorFaded: "#242a30",
      highlightBackgroundColor: "#d7591a", //"#cc3e20", //"#5087ed"
      highlightForegroundColor: "#FFFFFF", //"#8d4834", 
      foregroundColor: "#FFFFFF",
      foregroundColorFaded: "#999999"
    };
    */

    setStudio(data);

  }, []);

  // Method to show the progress panel
  const showProgress = (message) => {
    setStatusMessage(message);
    setProgressVisible(true);
  };

  // Method to hide the progress panel
  const hideProgress = () => {
    setProgressVisible(false);
    setStatusMessage("Ready.");
  };

  const resetVisibility = () => {
    setAccountVisible(false);
    setAppUsersVisible(false);
    setBackToCalendar(false);
    setBillingVisible(false);
    setCalendarVisible(false);
    setDonateVisible(false);
    setFieldSelectorVisible(false);
    setMenuVisible(false);
    setMessagesVisible(false);
    setPayVisible(false);
    setRateVisible(false);
    setReportsVisible(false);
    setRunHomeVisible(false);
    setSelectedAppUser(null);
    setSelectedConversation(null);
    setSelectedModel(null);
    setSelectedObject(null);
    setSettingsVisible(false);
};

  // Navigation
  const changePage = (newPage) => {
    // Check the value of the new page
    if (newPage === "RUN") {
      setSelectedField(null);
    } else if (newPage === "STUDIO") {
      setSelectedModel(null);
      setSelectedObject(null);
    } else if (newPage === "HOME") {
      setSelectedApp(null);
      setSelectedModel(null);
      setSelectedField(null);
      setSelectedModel(null);
      setSelectedObject(null);
    }
    // Finally, set the new page
    setPage(newPage);
  };

  return (
    <Global.Provider value={{

      // Foundation
      formMode, setFormMode,
      appTitle, setAppTitle,
      changePage, // function
      currentUser, setCurrentUser,
      eventVisible, setEventVisible,
      invite, setInvite,
      loginMode, setLoginMode,
      loginOpen, setLoginOpen,
      menuVisible, setMenuVisible,
      page, setPage,
      progressVisible, showProgress, hideProgress,
      statusMessage, setStatusMessage,
      userRole, setUserRole,
      userAccountOpen, setUserAccountOpen,
      userSearchVisible, setUserSearchVisible,
      fieldVisible, setFieldVisible,

      // Studio
      currentCanvas, setCurrentCanvas,
      currentDevice, setCurrentDevice,
      menuCollapsed, setMenuCollapsed,
      rolesCollapsed, setRolesCollapsed,
      studio, setStudio,

      // Apps
      appModalOpen, setAppModalOpen,
      apps, setApps,
      selectedApp, setSelectedApp,

      // Models
      models, setModels,
      newModelModalOpen, setNewModelModalOpen,
      selectedModel, setSelectedModel,

      // Fields
      appFields, setAppFields,
      fields, setFields,
      fieldSelectorVisible, setFieldSelectorVisible,
      summaryFields, setSummaryFields,
      selectedField, setSelectedField,
      fieldFilter, setFieldFilter,

      // Roles
      roles, setRoles,
      selectedRole, setSelectedRole,

      // Reports
      reports, setReports,
      selectedReport, setSelectedReport,

      // Run
      appSummaries, setAppSummaries,
      changesMade, setChangesMade,
      currentObject, setCurrentObject,
      donateVisible, setDonateVisible,
      formFields, setFormFields,
      lastCamera, setLastCamera,
      objects, setObjects,
      runHomeVisible, setRunHomeVisible,
      selectedObject, setSelectedObject,
      settingsVisible, setSettingsVisible,
      summaryHeight, setSummaryHeight,
      sortField, setSortField,
      sortDirection, setSortDirection,
      objectsView, setObjectsView,

      // Calendar
      activeDate, setActiveDate,
      backToCalendar, setBackToCalendar,
      calendarTitle, setCalendarTitle,
      calendarVisible, setCalendarVisible,
      defaultEventDate, setDefaultEventDate,
      events, setEvents,
      messagesVisible, setMessagesVisible,
      monthPickerVisible, setMonthPickerVisible,
      selectedCalendar, setSelectedCalendar,
      selectedEvent, setSelectedEvent,
      currentYear, setCurrentYear,
      currentMonth, setCurrentMonth,
      selectedTime, setSelectedTime,

      // Conversations
      conversations, setConversations,
      selectedConversation, setSelectedConversation,

      // Financials
      billingVisible, setBillingVisible,
      payAmount, setPayAmount,
      payMode, setPayMode,
      payOnceAmount, setPayOnceAmount,
      payVisible, setPayVisible,
      rateVisible, setRateVisible,
      targetApp, setTargetApp,
      timespan, setTimespan,
      userApps, setUserApps,

      // Account
      accountVisible, setAccountVisible,
      accountAboutVisible, setAccountAboutVisible,
      accountBillingVisible, setAccountBillingVisible,

      // App Users
      appUsers, setAppUsers,
      appUsersVisible, setAppUsersVisible,
      selectedAppUser, setSelectedAppUser,

      // Reports
      reportsVisible, setReportsVisible,

      // Headlines
      headlines, setHeadlines,
      editingHeadlines, setEditingHeadlines,

      // Reset Visibility
      resetVisibility,

      // Designer
      elements, setElements,
      selectedElement, setSelectedElement,

      // User Elements
      userElements, setUserElements,

      // Groups
      groups, setGroups,
      selectedLookup, setSelectedLookup,

      // Channels
      channels, setChannels,
      selectedChannel, setSelectedChannel,
      channelItemAddOpen, setChannelItemAddOpen

    }}>
      {children}
    </Global.Provider>
  );
};
