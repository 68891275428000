import { collection, deleteDoc, getDocs, query, where, doc, setDoc } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';
import { activity } from './ActivityManager';

class IndexManager {

    /**
     * Method to add an index record, to assist with object searching
     * within apps or models, or across all models in an app.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the object model type.
     * @param {string} objectKey - The key of the object. Will become the index document ID.
     * @param {string} objectTitle - Title of the object.
     * 
     * @returns {object} - New object.
    */
    async add(appKey, modelKey, objectKey, objectTitle) {

        const data = {
            appKey: appKey,
            modelKey: modelKey,
            objectKey: objectKey,
            objectTitle: objectTitle
        };

        await setDoc(doc(db, collections.index, objectKey), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
     * Deletes an object from the index.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} objectKey - Key of the object being deleted.
    */
    async delete(appKey, objectKey) {
        await deleteDoc(doc(db, collections.index, objectKey));

        activity.log(appKey, 'deletes', 1);
    }

    /**
     * Deletes all object index records for an app.
     * 
     * @param {string} appKey - Key of the app.
    */
    async deleteByApp(appKey) {
        const q = query(collection(db, collections.index), where('appKey', '==', appKey));
        const querySnapshot = await getDocs(q);

        // Iterate over each document in the query result and delete it
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();

        activity.log(appKey, 'deletes', querySnapshot.size);
    }

    /**
     * Deletes all object index records for a model.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} modelKey - Key of the model.
    */
    async deleteByModel(appKey, modelKey) {
        const q = query(collection(db, collections.index), where('modelKey', '==', modelKey));
        const querySnapshot = await getDocs(q);

        // Iterate over each document in the query result and delete it
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();

        activity.log(appKey, 'deletes', querySnapshot.size);
    }

    /**
     * Fetches index records for all objects in an app.
     * 
     * @param {string} appKey - App key.
    */
    async listByApp(appKey) {
        const q = query(collection(db, collections.index), where("appKey", "==", appKey));

        const snapshot = await getDocs(q);

        activity.log(appKey, 'reads', snapshot.docs.length);

        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    /**
     * Fetches index records for all objects in a model.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key to get fields for.
    */
    async listByModel(appKey, modelKey) {
        const q = query(collection(db, collections.index), where("modelKey", "==", modelKey));

        const snapshot = await getDocs(q);

        activity.log(appKey, 'reads', snapshot.docs.length);

        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    /**
     * Searches for all items in the array for a passed-in query string by comparing
     * against the string's presence anywhere in the "objectTitle" value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {Array} entries - Array of object index entries.
     * @param {string} query - Query string to search for.
     * @param {string} modelKey - (Optional) Key of the model.
     * 
     * @returns {Array} - Filtered array of objects matching the query.
     */
    searchByApp(appKey, entries, query, modelKey) {
        if (modelKey) {
            return entries.filter(obj =>
                obj.appKey === appKey && obj.modelKey === modelKey && obj.objectTitle.toLowerCase().includes(query.toLowerCase())
            );
        } else {
            return entries.filter(obj =>
                obj.appKey === appKey && obj.objectTitle.toLowerCase().includes(query.toLowerCase())
            );
        }
    }

    /**
     * Searches for all items in the array for a passed-in query string by comparing
     * against the string's presence anywhere in the "objectTitle" value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} modelKey - Key of the model.
     * @param {Array} entries - Array of object index entries.
     * @param {string} query - Query string to search for.
     * 
     * @returns {Array} - Filtered array of objects matching the query.
     */
    searchByModel(appKey, modelKey, entries, query) {
        return entries.filter(obj =>
            obj.appKey === appKey && obj.modelKey === modelKey && obj.objectTitle.toLowerCase().includes(query.toLowerCase())
        );
    }

    /**
     * Updates an object's title in the index.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} objectKey - Key of the object being updated.
     * @param {string} objectTitle - New title of the object.
    */
    async update(appKey, objectKey, objectTitle) {
        const ref = doc(db, collections.index, objectKey);
    
        // Use setDoc instead of updateDoc
        await setDoc(ref, { objectTitle: objectTitle }, { merge: true });
    
        activity.log(appKey, 'writes', 1);
    }

}

export default IndexManager;
