import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AddButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import PlusIcon from '../../svg/PlusIcon';

const AddButton = () => {
  const { theme } = useTheme();
  const {
    setFormMode,
    setSelectedObject,
    setObjectsView
  } = useContext(Global);

  const handleAdd = async () => {
    setObjectsView("LIST");
    setSelectedObject(null);
    setFormMode("ADD");
  };

  return (
    <>
      <div className="add-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        onClick={handleAdd}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>
    </>
  );
};

export default AddButton;
