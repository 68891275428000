import React from 'react';

// Styles
import './VideoGalleryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import teacher from '../../../../images/teacher.png';

// Images
import BackIcon from '../../../../svg/BackIcon';
import ForwardIcon from '../../../../svg/ForwardIcon';

const VideoGalleryPreview = ({
    selected = false,
    size
}) => {
    const { theme, hexToRgba } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="video-gallery-preview-container">
            <div className="video-gallery-preview-wrapper"
                style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
                <img
                    src={teacher}
                    alt="Nature"
                    className="video-gallery-preview-image"
                />
            </div>
            <div
                className="video-gallery-preview-nav-button previous-button"
                style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                >
                <BackIcon
                    color={theme.foregroundColor}
                    width="20"
                    height="20"
                />
            </div>
            <div
                className="video-gallery-preview-nav-button next-button"
                style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                >
                <ForwardIcon
                    color={theme.foregroundColor}
                    width="20"
                    height="20"
                />
            </div>
            <div className="video-gallery-preview-image-count"
                    style={{
                        color: theme.foregroundColor,
                        backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                    }}>
                    7 of 14
                </div>
        </div>
    );
};

export default VideoGalleryPreview;
