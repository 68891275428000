import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppSettingsBusinessModel.css';

const AppSettingsBusinessModel = ({
    businessModel,
    setBusinessModel,
    subscriptionPrice,
    setSubscriptionPrice,
    seatPrice,
    setSeatPrice
}) => {

    const { studio } = useContext(Global);

    // Hard-coded array of business models
    const models = [
        {
            key: 'FREE',
            title: 'Free',
            description: 'Users of this app may use it free of cost. jotspot.ai usage fees will apply.'
        },
        {
            key: 'DONATION',
            title: 'Donation Supported',
            description: 'A donation option will be made available in the menu for your users. All donations received will be credited to your account.'
        },
        {
            key: 'SUBSCRIBE',
            title: 'Subscription',
            description: 'Apply a required monthly subscription price for an individual to use this app. jotspot.ai will track subscriptions and credit your account each month.'
        },
        {
            key: 'GROUPSUB',
            title: 'Group Subscription',
            description: 'Apply a seat subscription price for use of this app. As the group manager adds and removes users, jotspot.ai will keep track of the subscription revenue. jotspot.ai will then credit your account each month.'
        },
        {
            key: 'LISTING',
            title: 'Listing-Based',
            description: 'Create a listing screen and charge users for each listing they would like to share with other users. jotspot.ai will track listings, transactions between users, and credit your account accordingly.'
        }
    ];

    // Function to handle model click
    const handleModelClick = (key) => {
        setBusinessModel(key);
    };

    return (
        <div className="app-settings-business-model-container">
            <div className="app-settings-business-model-label"
                style={{ color: studio.foregroundColorFaded }}
            >
                Business Model
            </div>
            <div 
                className="app-settings-business-models"
                style={{
                    height: (businessModel === 'SUBSCRIBE' || businessModel === 'GROUPSUB') ? '280px' : '360px'
                }}
            >
                <div className="app-settings-business-models-wrapper">
                    {models.map((model, index) => (
                        <div key={index} onClick={() => handleModelClick(model.key)}
                            className="app-settings-business-model-item"
                            style={{
                                backgroundColor: (model.key === businessModel) ? studio.highlightBackgroundColor : studio.backgroundColorFaded
                            }}
                        >
                            <div className="app-settings-business-model-item-title"
                                style={{ color: studio.foregroundColor }}
                            >
                                {model.title}
                            </div>
                            <div className="app-settings-business-model-item-description"
                                style={{ color: studio.foregroundColorFaded }}
                            >
                                {model.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {businessModel === "SUBSCRIBE" &&
                <>
                    <div className="app-settings-business-model-label"
                        style={{ color: studio.foregroundColorFaded }}
                    >
                        Monthly Subscription Price
                    </div>
                    <input
                        type="text"
                        name="subscriptionPrice"
                        className="app-settings-business-model-input"
                        style={{
                            color: studio.foregroundColor,
                            backgroundColor: studio.backgroundColorFaded,
                            borderColor: studio.backgroundColorFaded,
                            marginBottom: '4px'
                        }}
                        value={subscriptionPrice}
                        onChange={e => setSubscriptionPrice(e.target.value)}
                        placeholder="Price (USD)"
                    />
                </>
            }
            
            {businessModel === "GROUPSUB" &&
                <>
                    <div className="app-settings-business-model-label"
                        style={{ color: studio.foregroundColorFaded }}
                    >
                        Monthly Subscription Price Per Seat
                    </div>
                    <input
                        type="text"
                        name="seatPrice"
                        className="app-settings-business-model-input"
                        style={{
                            color: studio.foregroundColor,
                            backgroundColor: studio.backgroundColorFaded,
                            borderColor: studio.backgroundColorFaded,
                            marginBottom: '4px'
                        }}
                        value={seatPrice}
                        onChange={e => setSeatPrice(e.target.value)}
                        placeholder="Price (USD)"
                    />
                </>
            }
        </div>
    );
};

export default AppSettingsBusinessModel;
