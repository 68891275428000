import React from 'react';

// Styles
import './UserThumbnail.css';

// Components
import UserDot from './UserDot';

const UserThumbnail = ({user, size}) => {

    return (
        <div className="user-thumbnail-container"
            style={{
                height: `${size}px`,
                borderRadius: '50%',
                overflow: 'hidden',
                width: `${size}px`,
            }}>
            {user.photo ? (
                <img
                    src={user.photo}
                    className="user-thumbnail-photo"
                    style={{
                        height: `${size}px`,
                        width: `${size}px`,
                    }}
                    alt="User"
                />
            ) : (
                <UserDot
                    firstInitial={user.username ? user.username.toUpperCase()[0] : ''}
                    size={size}
                />
            )}
        </div>
    );
};

export default UserThumbnail;

