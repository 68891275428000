import React, { useEffect, useRef, useState } from 'react';

// Formatter
import { formatCurrency } from '../../../../utilities/Formatters';

// Styles
import './Currency.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Currency = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false,
}) => {
    const { theme } = useTheme();

    const [value, setValue] = useState(''); // Default ADD mode value

    const inputRef = useRef(null);

    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    // Focus the input when the container is clicked
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        // Apply more specific formatting on blur
        const formattedValue = formatCurrency(value);
        setValue(formattedValue);
        onUpdate(field, formattedValue);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        // Light formatting to remove non-numeric characters, allowing for decimal points
        const formattedValue = value.replace(/[^\d.]/g, '');
        setValue(formattedValue);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* READ-ONLY TEXT */}
                {readOnly ? (
                    <div className='text-value'
                        style={{ color: theme.foregroundColor }}>
                        {value}
                    </div>
                ) : (
                    <>
                        {/* INPUT */}
                        <input
                            type="text"
                            ref={inputRef}
                            className="currency-input"
                            style={{ color: theme.foregroundColor }}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </>
                )}

            </FieldContainer>
        </>
    );
};

export default Currency;
