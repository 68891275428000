import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Rate.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../foundation/Modal';

// Images
import RateOffIcon from '../../svg/RateOffIcon';
import RateOnIcon from '../../svg/RateOnIcon';

const Rate = () => {
  const { theme } = useTheme();
  const {
    rateVisible,
    setRateVisible
  } = useContext(Global);

  const [rating, setRating] = useState(0);

  const handleStarClick = async (index) => {
    const newRating = index + 1;
    setRating(newRating);
  };

  return (
    <Modal title="Rate" isOpen={rateVisible} onClose={() => setRateVisible(false)} width="300px">
      <div className="rate-container">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            onClick={() => handleStarClick(index)}
            style={{ cursor: 'pointer' }}
          >
            {index < rating ? (
              <RateOnIcon color={theme.highlightBackgroundColor} width="40" height="40" />
            ) : (
              <RateOffIcon color={theme.foregroundColorFaded} width="40" height="40" />
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default Rate;
