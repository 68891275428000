import React, { useState, useRef, useEffect } from 'react';

// Styles
import './ImageFilterControl.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import nature from '../../../../../images/nature.jpg';

// Filter definitions
const filters = {
    grayscale: {
        label: 'Grayscale',
        unit: '%',
        defaultValue: 0,
        min: 0,
        max: 100,
    },
    sepia: {
        label: 'Sepia',
        unit: '%',
        defaultValue: 0,
        min: 0,
        max: 100,
    },
    brightness: {
        label: 'Brightness',
        unit: '%',
        defaultValue: 100,
        min: 0,
        max: 200,
    },
    contrast: {
        label: 'Contrast',
        unit: '%',
        defaultValue: 100,
        min: 0,
        max: 200,
    },
};

// Helper function to parse filter string
const parseFilterString = (filterString) => {
    const filterValues = {};
    if (typeof filterString === 'string') {
        const filterArray = filterString.split(' ').filter(f => f);

        filterArray.forEach(filter => {
            const match = filter.match(/(\w+)\(([\d.]+)(%?)\)/);
            if (match) {
                const [, name, value] = match;
                filterValues[name] = parseFloat(value);
            }
        });
    }

    return filterValues;
};

const ImageFilterControl = ({ value, onChange, onBlur, ...props }) => {
    const { theme } = useTheme();

    const [selectedFilter, setSelectedFilter] = useState('');
    const [filterValues, setFilterValues] = useState(parseFilterString(value));
    const [isDragging, setIsDragging] = useState(false);
    const [initialOffset, setInitialOffset] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        setFilterValues(parseFilterString(value));
    }, [value]);

    useEffect(() => {

        const handleMouseMove = (event) => {
            if (!isDragging || !selectedFilter) return;
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = event.clientX - rect.left - initialOffset; // Subtract the initial offset to center the thumb
            const newValue = Math.min(
                filters[selectedFilter].max,
                Math.max(
                    filters[selectedFilter].min,
                    ((offsetX / rect.width) * (filters[selectedFilter].max - filters[selectedFilter].min)) + filters[selectedFilter].min
                )
            );
            const updatedFilterValues = { ...filterValues, [selectedFilter]: newValue };
            setFilterValues(updatedFilterValues);
        };
    
        const handleMouseUp = (event) => {
            if (!isDragging || !selectedFilter) return;
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = event.clientX - rect.left - initialOffset; // Subtract the initial offset to center the thumb
            const newValue = Math.min(
                filters[selectedFilter].max,
                Math.max(
                    filters[selectedFilter].min,
                    ((offsetX / rect.width) * (filters[selectedFilter].max - filters[selectedFilter].min)) + filters[selectedFilter].min
                )
            );
            const updatedFilterValues = { ...filterValues, [selectedFilter]: newValue };
            
            const newFilterString = generateFilterString(updatedFilterValues);
            onChange(newFilterString);
            onBlur(newFilterString);
            setIsDragging(false);
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, filterValues, initialOffset, onBlur, onChange, selectedFilter]);

    const handleThumbMouseDown = (event) => {
        const thumbRect = event.target.getBoundingClientRect();
        const thumbCenterOffset = event.clientX - thumbRect.left; // Adjust for half-width of thumb (8px)
        setInitialOffset(thumbCenterOffset);
        setIsDragging(true);
    };

    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
    };

    const generateFilterString = (values) => {
        return Object.keys(values)
            .map((filter) => `${filter}(${values[filter]}${filters[filter].unit})`)
            .join(' ');
    };

    const filterString = generateFilterString(filterValues);

    const getFilterValue = (filter) => {
        const value = filterValues[filter];
        return typeof value === 'number' ? value.toFixed(2) : filters[filter].defaultValue;
    };

    return (
        <div>
            <div>
                <select
                    className="image-filter-control-select"
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    {...props}
                    >
                    <option value="">-- Select --</option>
                    {Object.keys(filters).map((filter) => (
                        <option key={filter} value={filter}>
                            {filters[filter].label}
                        </option>
                    ))}
                </select>
            </div>

            {selectedFilter && (
                <div className="image-filter-control-slider-wrapper">
                    <div className="image-filter-control-slider" 
                        ref={sliderRef} 
                        style={{ 
                            background: theme.highlightBackgroundColor 
                        }}>
                        <div
                            className="image-filter-control-thumb"
                            style={{
                                left: `${((filterValues[selectedFilter] || filters[selectedFilter].defaultValue) - filters[selectedFilter].min) / (filters[selectedFilter].max - filters[selectedFilter].min) * 100}%`,
                                backgroundColor: theme.foregroundColor,
                            }}
                            onMouseDown={handleThumbMouseDown}
                        />
                    </div>
                    <div 
                        className="image-filter-control-label"
                        style={{ color: theme.foregroundColor }}
                        >
                        {getFilterValue(selectedFilter)}
                        {filters[selectedFilter].unit}
                    </div>
                </div>
            )}

            <div className="image-filter-control-image-container">
                <img
                    src={nature}
                    alt="Nature"
                    className="image-filter-control-image"
                    style={{
                        filter: filterString,
                    }}
                />
            </div>
        </div>
    );
};

export default ImageFilterControl;
