import React from 'react';

// Styles
import './ContextMenuGroup.css';

// Theme
import { useTheme } from '../../ThemeContext';

// The main component
const ContextMenuGroup = ({ title, children }) => {
  const { theme } = useTheme();

  return (
    <>
      <div className="context-menu-group-title"
        style={{ color: theme.foregroundColorFaded }}>
        {title}
      </div>
      <div className="context-menu-group-wrapper">
        {children}
      </div>
    </>
  );
};

export default ContextMenuGroup;
