import React, { useContext, useState } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './AccountPassword.css';

// Theme
import { useTheme } from '../ThemeContext';

/**
 * AccountPassword Component
 * 
 * This component renders an input for a new user's password.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountPassword = ({ onChange }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  // State Variables
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const passwordRequirements = [
    "at least 8 characters long",
    "contain an uppercase letter",
    "contain a lowercase letter",
    "contain a number",
    "contain a special character (!@#$%^&*)"
  ];

  /**
   * Method to validate password requirements.
   * 
   * @param {string} password - Password to validate.
   * @returns {boolean} - True if the password meets all requirements, false otherwise.
   */
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
    return regex.test(password);
  };

  /**
   * Method to handle password input blur.
   * 
   * @param {Event} e - Change event
   */
  const handlePasswordChange = (e) => {
    const value = e.target.value.trim();
    setPassword(value);

    onChange(value, false); // Assume invalid initially

    if (!validatePassword(value)) {
      setPasswordError('Password does not meet the requirements.');
      return;
    }

    if (value !== passwordConfirm) {
      setPasswordError('Passwords do not match.');
      return;
    }

    setPasswordError('');

    // Pass the valid password to the parent component
    onChange(value, true); 
  };

  /**
   * Method to handle password confirm input blur.
   * 
   * @param {Event} e - Change event
   */
  const handlePasswordConfirmChange = (e) => {
    const confirm = e.target.value.trim();
    setPasswordConfirm(confirm);

    onChange(confirm, false); // Assume invalid initially

    if (confirm !== password) {
      setPasswordError('Passwords do not match.');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Password does not meet the requirements.');
      return;
    }

    setPasswordError('');
    
    // Pass the valid password to the parent component
    onChange(confirm, true); 
  };

  return (
    <>

      {/* CONTAINER */}
      <div className="account-password-container">

        {/* PASSWORD INPUT */}
        <input type="password"
          className="account-password-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          onChange={handlePasswordChange}
          placeholder="Password"
          required
        />

        {/* CONFIRM PASSWORD INPUT */}
        <input type="password"
          className="account-password-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          onChange={handlePasswordConfirmChange}
          placeholder="Confirm Password"
          required
        />

        {/* ERROR MESSAGE */}
        {passwordError && (
          <div
            className="account-password-error"
            style={{
              color: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
            }}
          >
            {passwordError}
          </div>
        )}

        {/* PASSWORD REQUIREMENTS */}
        <div className="account-password-requirements"
          style={{
            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
          }}>
          {passwordRequirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </div>

      </div>

    </>
  );
};

export default AccountPassword;
