import React from 'react';

// Styles
import './Menu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import MenuItems from './MenuItems';
import MenuHeader from './MenuHeader';

const Menu = () => {
    const { theme } = useTheme();

    return (
        <div className="menu-container"
            style={{
                backgroundColor: theme.backgroundColor,
                borderRightColor: theme.backgroundColorFaded
            }}>
            <MenuHeader />
            <MenuItems />
        </div>
    );
};

export default Menu;
