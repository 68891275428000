import React from 'react';

// Styles
import './AccountMenuToolBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
//import ViewMenu from './ViewMenu';

const AccountMenuToolBar = () => {
    const { theme } = useTheme();

    return (

        <div className="account-menu-toolbar-container">
            <div className="account-menu-toolbar-left"
                style={{ color: theme.foregroundColorFaded }}
            >
                My Account
            </div>
            <div className="account-menu-toolbar-right" >
                
            </div>
        </div>
    );
};

export default AccountMenuToolBar;
