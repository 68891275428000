import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ selectedApp, children }) => {
    const [currentTheme, setCurrentTheme] = useState('DARK');

    const defaultThemes = {
        dark: {
            backgroundColor: '#000000',
            backgroundColorFaded: '#222222',
            foregroundColor: '#ffffff',
            foregroundColorFaded: '#aaaaaa',
            highlightBackgroundColor: '#ff4500',
            highlightForegroundColor: '#ff4500'
        },
        light: {
            backgroundColor: '#ffffff',
            backgroundColorFaded: '#f0f0f0',
            foregroundColor: '#000000',
            foregroundColorFaded: '#555555',
            highlightBackgroundColor: '#ff0000',
            highlightForegroundColor: '#ff0000'
        }
    };

    useEffect(() => {
        if (selectedApp?.currentTheme) {
            setCurrentTheme(selectedApp.currentTheme);
        }
    }, [selectedApp]);

    const themes = selectedApp?.themes || defaultThemes;
    const theme = currentTheme === 'DARK' ? themes.dark : themes.light;

    // Function to convert hex to rgba
    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r},${g},${b},${alpha})`;
    };

    // Function to map tokens to theme colors
    const getColorFromToken = (token) => {
        switch (token) {
            case '[foregroundColor]':
                return theme.foregroundColor;
            case '[foregroundColorFaded]':
                return theme.foregroundColorFaded;
            case '[highlightBackgroundColor]':
                return theme.highlightBackgroundColor;
            case '[highlightForegroundColor]':
                return theme.highlightForegroundColor;
            case '[backgroundColorFaded]':
                return theme.backgroundColorFaded;
            case '[backgroundColor]':
                return theme.backgroundColor;
            case '[transparent]':
                return 'transparent';
            default:
                console.warn(`Unknown color token: ${token}`);
                return theme.foregroundColor; // Fallback color
        }
    };

    // Function to process styles and replace tokens with actual colors
    const getProcessedStyles = (styles) => {
        const processed = Object.entries(styles).reduce((acc, [key, value]) => {
            if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
                acc[key] = getColorFromToken(value);
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
        return processed;
    };

    // SUMMARY FIELDS
    const fieldDefaults = {
        autonumber: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
        },
        checkbox: {
            width: '34px',
            height: '34px'
        },
        checklist: {
            width: '20%',
            height: '20px',
            color: theme.foregroundColor,
        },
        event: {
            width: '150px',
            height: '40px',
            color: theme.foregroundColor,
        },
        gallery: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        image: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        lookup: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        phone: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        text: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
            fontSize: '14pt',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'left',
            textDecoration: 'none',
            letterSpacing: '0px',
            lineHeight: '1.5em',
            textTransform: 'none',
            textWrap: 'wrap',
            wordSpacing: '0px',
            zIndex: '0'
        },
        year: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
        },
    };

    return (
        <ThemeContext.Provider value={{
            theme,
            currentTheme,
            fieldDefaults,
            setCurrentTheme,
            getColorFromToken,
            getProcessedStyles, // Provide the getProcessedStyles function
            hexToRgba // Provide the hexToRgba function
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);
