import React, { useState, useEffect } from 'react';

// Drag and Drop - Sorting
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './Checklist.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../../svg/PlusIcon';

// Components
import ChecklistAddButton from './ChecklistAddButton';
import ChecklistAddForm from './ChecklistAddForm';
import ChecklistItem from './ChecklistItem';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

/**
 * Checklist Component
 * 
 * Renders a checklist.
 *
 * @param {string} object - Object to render the checklist for.
 * @param {string} field - Field to render the checklist for.
 * @param {function} onUpdate - Function to call when the checklist is updated.
 * @param {boolean} showFieldMenu - Whether to show the field menu.
 * 
 * @return {JSX.Element} - The checklist component.
 */
const Checklist = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false,
}) => {
    const { theme } = useTheme();

    // State variables
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    /**
     * Initializes the items state variable.
     */
    useEffect(() => {
        setItems(object?.[field.key] || []);
    }, [object, field.key]);

    /**
     * Toggles the checked state of an item.
     *
     * @param {string} itemKey - Key of the item to toggle.
     */
    const handleCheckboxChange = (itemKey) => {
        const updatedItems = items.map(item =>
            item.key === itemKey ? { ...item, checked: !item.checked } : item
        );
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Adds a new item to the checklist.
     *
     * @param {object} newItem - New item to add.
     */
    const handleAdd = (newItem) => {
        const updatedItems = [...items, newItem];
        setItems(updatedItems);
        onUpdate(field, updatedItems);
        setModalOpen(false);
    };

    /**
     * Removes an item from the checklist.
     *
     * @param {string} itemKey - Key of the item to remove.
     */
    const handleRemove = (itemKey) => {
        const updatedItems = items.filter(item => item.key !== itemKey);
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Moves an item from one index to another.
     *
     * @param {integer} dragIndex - Index of the item being dragged.
     * @param {integer} hoverIndex - Index of the item being hovered over.
     */
    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = items[dragIndex];
        const updatedItems = [...items];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Renders a single checklist item.
     *
     * @param {object} item - Checklist item to render.
     * @param {integer} index - Index of the item in the items array.
     */
    const renderItem = (item, index) => (
        <div key={item.key} className="checklist-item">
            <ChecklistItem
                key={item.key}
                index={index}
                item={item}
                moveItem={moveItem}
                onChange={handleCheckboxChange}
                onRemove={handleRemove}
                theme={theme}
            />
        </div>
    );

    const options = [
        {
            text: 'Add List Item',
            icon: PlusIcon,
            onClick: () => {
                setModalOpen(true);
            }
        }
    ];

    return (

        <DndProvider backend={HTML5Backend}>

            <>
                {/* CONTAINER */}
                <FieldContainer
                    readOnly={readOnly}
                    field={field}>

                    {/* HEADER */}
                    <FieldHeader
                        field={field}
                        readOnly={readOnly}
                        showFieldMenu={showFieldMenu}
                        additionalOptions={options}
                    />

                    {/* ITEMS */}
                    <div className="checklist-items">
                        {items.map((item, index) => renderItem(item, index))}
                    </div>

                    {/* ADD */}
                    {!readOnly &&
                        <>
                            {/* ADD BUTTON */}
                            < div className='checklist-footer-wrapper'>
                                <ChecklistAddButton
                                    onClick={() => setModalOpen(true)}
                                />
                            </div>

                            {/* ADD FORM */}
                            <ChecklistAddForm
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                onAdd={handleAdd}
                            />

                        </>
                    }

                </FieldContainer>

            </>

        </DndProvider >

    );
};

export default Checklist;
