import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Channels.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ChannelItemAddButton from './ChannelItemAddButton';
import ChannelItem from './ChannelItem';
import ChannelItemAdd from './ChannelItemAdd';

/**
 * Channels Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Channels = () => {
    const { theme } = useTheme();
    const {
        channels,
        selectedChannel,
        setChannelItemAddOpen,
        setSelectedChannel,
        userRole,
    } = useContext(Global);

    /**
     * Handles a channel click event.
     * 
     * @param {object} channel - Channel that was clicked on.
     */
    const handleChannelClick = (channel) => {
        setSelectedChannel(channel);
    };
    /**
     * Handles an add channel button click event.
     */
    const handleAddChannelClick = () => {
        setChannelItemAddOpen(true);
    };

    return (
        <>
            {/* TITLE */}
            <div className="channels-group-title"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                Channels
            </div>

            {/* CHANNEL LIST */}
            <div className="channels-group">
                {channels.map(channel => (
                    <ChannelItem
                        key={channel.key}
                        channel={channel}
                        isActive={selectedChannel && selectedChannel.key === channel.key}
                        onClick={() => handleChannelClick(channel)}
                    />
                ))}

                {/* ADD CHANNEL */}
                {userRole === "ADMIN" &&
                    <>
                        {/* BUTTON */}
                        <ChannelItemAddButton
                            onClick={handleAddChannelClick}
                        />

                        {/* FORM */}
                        <ChannelItemAdd />
                    </>
                }
            </div>
        </>
    );
};

export default Channels;
