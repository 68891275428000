import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Designer.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../foundation/Modal';

// Components
import Elements from './elements/Elements';
import Canvas from './canvas/Canvas';
import Properties from './properties/Properties';

// Managers
import ModelManager from '../../managers/ModelManager';
  
const modelManager = new ModelManager();

/**
 * Designer Component
 * 
 * This component allows users to customize list summaries in an editor.
 * 
 * @param {boolean} visible - Indicates if the editor should be visible or not.
 * @param {function} setVisible - Function to set the visibility of the editor.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Designer = ({ visible, setVisible }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    selectedModel,
    setElements,
    setSelectedModel
  } = useContext(Global);

  // Effect to set the elements for the selected model
  useEffect(() => {
    setElements(selectedModel.elements || []);
  }, [selectedModel, setElements]);

  /**
   * Method to save the design to the model record
   */
  const handleSave = async (elements, containerHeight) => {

    // Save the data to the database
    await modelManager.updateData(selectedApp.key, selectedModel.key, { elements, summaryHeight: containerHeight });

    // Update the selected model in state with the new elements
    setSelectedModel(prev => ({ ...prev, elements, summaryHeight: containerHeight }));

    // Close the designer
    setVisible(false);

  };

  return (

    <Modal
      title={`${selectedModel.title} -  Card Layout`}
      isOpen={visible}
      onClose={() => setVisible(false)}
      height="632px"
      width="920px">

      <div className="designer-container">

        {/* FIELDS */}
        <div className="designer-fields">
          <Elements />
        </div>

        {/* CENTER AREA */}
        <div className="designer-canvas-wrapper"
          style={{
            backgroundColor: theme.backgroundColor,
          }}>

          {/* CENTER CANVAS */}
          <div className="designer-canvas-center"
            style={{
              width: '440px'
            }}>
              <Canvas 
                onSave={handleSave}
              />
          </div>

        </div>

        {/* PROPERTIES */}
        <div className="designer-properties">
          <Properties />
        </div>

      </div>

    </Modal>
  );
};

export default Designer;
