import React, { useEffect, useRef } from 'react';

// Utilities
import { adjustTextareaHeight } from '../../../../utilities/Forms';

// Styles
import './GalleryCaption.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const GalleryCaption = ({
    value,
    setValue,
    currentIndex,
    onBlur,
    readOnly
}) => {
    const { theme } = useTheme();

    // References
    const textareaRef = useRef(null);

    /**
     * Sets the height of the textarea to the height of the text within it
     * whenever the currentIndex changes.
     */
    useEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight(textareaRef);
        }
    }, [currentIndex]);

    /**
     * Handles blur of the textarea.
     */
    const handleCaptionBlur = () => {
        onBlur();
    };

    /**
     * Handles changes within the textarea.
     * 
     * @param {event} event - The event object.
     */
    const handleCaptionChange = (event) => {
        const newCaption = event.target.value;
        const updatedVideos = [...value];
        if (updatedVideos[currentIndex]) {
            updatedVideos[currentIndex].caption = newCaption;
            setValue(updatedVideos);
        }
        adjustTextareaHeight(textareaRef);
    };

    // Ensure the currentIndex is valid and points to an existing video
    const currentVideo = value[currentIndex];

    return (
        <>
            {(readOnly && currentVideo && currentVideo.caption !== undefined && currentVideo.caption.length === 0) ? null : (
                
                <div className="gallery-caption-container">

                    {/* CAPTION */}
                    <textarea
                        className="gallery-caption-input"
                        ref={textareaRef}
                        style={{
                            backgroundColor: 'transparent',
                            color: theme.foregroundColor
                        }}
                        value={currentVideo && (currentVideo.caption !== undefined ? currentVideo.caption : '')}
                        onChange={handleCaptionChange}
                        onBlur={handleCaptionBlur}
                        placeholder="Enter caption..."
                        readOnly={readOnly}
                    />

                </div>

            )}
        </>
    );
};

export default GalleryCaption;
