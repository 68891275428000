import { collection, deleteDoc, getDocs, query, where, doc, setDoc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';
import { activity } from './ActivityManager';

class UserIndexManager {

    /**
     * Method to add an userindex record.
     * 
     * @param {string} appKey - App key.
     * @param {string} userKey - The key of the user. Will become the userindex document ID.
     * @param {string} userName - Name of the user.
     * 
     * @returns {user} - New index entry.
    */
    async add(appKey, userKey, userName) {

        const data = {
            appKey: appKey,
            userKey: userKey,
            userName: userName
        };

        await setDoc(doc(db, collections.userindex, userKey), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
     * Deletes an user from the userindex.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} userKey - Key of the user being deleted.
    */
    async delete(appKey, userKey) {
        await deleteDoc(doc(db, collections.userindex, userKey));

        activity.log(appKey, 'deletes', 1);
    }

    /**
     * Deletes all user userindex records for an app.
     * 
     * @param {string} appKey - Key of the app.
    */
    async deleteByApp(appKey) {
        const q = query(collection(db, collections.userindex), where('appKey', '==', appKey));
        const querySnapshot = await getDocs(q);

        // Iterate over each document in the query result and delete it
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();

        activity.log(appKey, 'deletes', querySnapshot.size);
    }

    /**
     * Fetches userindex records for all users in an app.
     * 
     * @param {string} appKey - App key.
    */
    async listByApp(appKey) {
        const q = query(collection(db, collections.userindex), where("appKey", "==", appKey));

        const snapshot = await getDocs(q);

        activity.log(appKey, 'reads', snapshot.docs.length);

        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    /**
     * Updates an user's name in the userindex.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} userKey - Key of the user being updated.
     * @param {string} userName - New name of the user.
    */
    async update(appKey, userKey, userName) {
        // Reference to the 'userindex' collection
        const userIndexCollection = collection(db, collections.userindex);
        
        // Query to find documents where the userKey matches the provided userKey
        const q = query(userIndexCollection, where('userKey', '==', userKey));
    
        // Execute the query
        const querySnapshot = await getDocs(q);
    
        // Iterate over each document in the query result and update the userName
        querySnapshot.forEach(async (docSnapshot) => {
            // Update the userName field in the document
            await updateDoc(docSnapshot.ref, { userName: userName });
        });
    
        // Log the activity
        activity.log(appKey, 'writes', querySnapshot.size);
    }

    /**
     * Searches for all items in the array for a passed-in query string by comparing
     * against the string's presence anywhere in the "userName" value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {Array} entries - Array of user userindex entries.
     * @param {string} query - Query string to search for.
     * 
     * @returns {Array} - Filtered array of users matching the query.
     */
    searchByApp(appKey, entries, query) {
        return entries.filter(obj =>
            obj.appKey === appKey && obj.userName.toLowerCase().includes(query.toLowerCase())
        );
    }

}

export default UserIndexManager;
