import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Calendar.css';

// Components
import CalendarAll from './CalendarAll';
import CalendarDay from './CalendarDay';
import EventForm from './event/EventForm';
import CalendarMonth from './CalendarMonth';
import CalendarSchedule from './CalendarSchedule';
import CalendarThree from './CalendarThree';
import CalendarToolBar from './CalendarToolBar';
import CalendarWeek from './CalendarWeek';
import CalendarYear from './CalendarYear';

const Calendar = () => {
    const {
        selectedCalendar,
        selectedTime
    } = useContext(Global);

    return (
        <div className="calendar-container">
            <CalendarToolBar />

            {/* SCHEDULE */}
            {selectedCalendar === 'SCHEDULE' && (
                <CalendarSchedule />
            )}

            {/* ALL */}
            {selectedCalendar === 'ALL' && (
                <CalendarAll />
            )}

            {/* YEAR */}
            {selectedCalendar === 'YEAR' && (
                <CalendarYear />
            )}

            {/* MONTH */}
            {selectedCalendar === 'MONTH' && (
                <CalendarMonth />
            )}

            {/* WEEK */}
            {selectedCalendar === 'WEEK' && (
                <CalendarWeek />
            )}

            {/* THREE */}
            {selectedCalendar === 'THREE' && (
                <CalendarThree />
            )}

            {/* DAY */}
            {selectedCalendar === 'DAY' && (
                <CalendarDay />
            )}
            
            <EventForm
                date={selectedTime}
            />

        </div>
    );
};

export default Calendar;
