import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';

// Global
import { Global } from '../../../../Global';

// Barcode Reader
import { BrowserMultiFormatReader } from '@zxing/library';

// Components
import Modal from '../../../../foundation/Modal';

// Styles
import './BarcodeModal.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const BarcodeModal = ({ barcodeModalOpen, setBarcodeModalOpen, onDetected }) => {
  const { theme } = useTheme();
  const { lastCamera, setLastCamera } = useContext(Global);

  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const videoElementRef = useRef(null);
  const codeReaderRef = useRef(new BrowserMultiFormatReader());
  const streamRef = useRef(null);

  const [hasCameraAccess, setHasCameraAccess] = useState(true);

  const startDecoding = useCallback((deviceId) => {
    if (!deviceId) return;

    setLastCamera(deviceId);
    setSelectedDeviceId(deviceId);

    const constraints = {
      video: { deviceId: { exact: deviceId } }
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        streamRef.current = stream;
        videoElementRef.current.srcObject = stream;
        codeReaderRef.current.decodeFromVideoDevice(deviceId, 'video', (result, err) => {
          if (result) {
            onDetected(result.text);
            codeReaderRef.current.reset();
            setBarcodeModalOpen(false);
          }
          if (err) {
            console.error("Error decoding barcode", err);
          }
        });
      })
      .catch((error) => {
        setHasCameraAccess(false)
        console.error("Error accessing the camera", error);
      });
  }, [onDetected, setBarcodeModalOpen, setLastCamera]);

  useEffect(() => {
    if (barcodeModalOpen) {
      codeReaderRef.current.listVideoInputDevices()
        .then((devices) => {
          setVideoInputDevices(devices);
          // Check if the lastCamera is still valid
          if (lastCamera && devices.some(device => device.deviceId === lastCamera)) {
            setSelectedDeviceId(lastCamera);
            startDecoding(lastCamera);
          } else {
            setSelectedDeviceId('');
          }
        })
        .catch((error) => {
          console.error("Error listing video input devices", error);
        });
    }
  }, [barcodeModalOpen, lastCamera, startDecoding]);

  useEffect(() => {
    const currentCodeReader = codeReaderRef.current;

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      currentCodeReader.reset();
    };
  }, []);

  const handleActivate = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoElementRef.current.srcObject = stream;
      })
      .catch((error) => {
        console.error("Failed to get the media stream.", error);
      });
  };

  return (
    <Modal title="Scan Barcode" isOpen={barcodeModalOpen} onClose={() => setBarcodeModalOpen(false)} width="400px">
      <div className="barcode-modal-container">
        <select
          value={selectedDeviceId}
          onChange={(e) => startDecoding(e.target.value)}
          className="barcode-modal-input"
          style={{
            backgroundColor: theme.backgroundColorFaded,
            borderColor: theme.backgroundColorFaded,
            color: theme.foregroundColor
          }}>
          <option key="prompt" value="">Select a Camera...</option>
          {videoInputDevices.map((device, index) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Device ${index + 1}`}
            </option>
          ))}
        </select>
        {selectedDeviceId && !hasCameraAccess ? (
          <div className="barcode-modal-activate-button-wrapper">
            <button onClick={handleActivate} className="barcode-modal-activate-button">Activate Camera</button>
          </div>
        ) : (
          <video ref={videoElementRef} id="video" autoPlay playsInline style={{ width: '100%' }} />
        )}
      </div>
    </Modal>
  );
};

export default BarcodeModal;
