import React, { useContext, useEffect, useRef, useState } from 'react';

// Managers
import EventManager from '../../managers/EventManager';
import ObjectManager from '../../managers/ObjectManager';

// Styles
import './CalendarThree.css';

// Global context and styles
import { Global } from '../../Global';

const CalendarThree = () => {
    const {
        activeDate,
        events,
        monthPickerVisible,
        selectedApp,
        setBackToCalendar,
        setCalendarTitle,
        setCalendarVisible,
        setSelectedObject
    } = useContext(Global);

    const [daysToShow, setDaysToShow] = useState([]);
    const [threeDayEvents, setThreeDayEvents] = useState([]);

    useEffect(() => {
        if (activeDate) {
            // Create new dates without mutating the original activeDate
            const baseDate = new Date(activeDate);
            const newDaysToShow = [
                new Date(baseDate),
                new Date(baseDate.setDate(baseDate.getDate() + 1)),
                new Date(baseDate.setDate(baseDate.getDate() + 1))
            ];
            setDaysToShow(newDaysToShow);
        }
    }, [activeDate]);

    // Scroll to the current hour
    const hourBlocksRef = useRef(null);
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70; // Adjust if needed
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    // Initialize title
    useEffect(() => {
        // Only run this if daysToShow has three days as expected
        if (daysToShow.length === 3) {
            setCalendarTitle(`${formatDateLong(daysToShow[0])} - ${formatDateLong(daysToShow[2])}`);
        }
    }, [daysToShow, setCalendarTitle]);

    // Gather events
    useEffect(() => {
        if (activeDate) {
            const eventManager = new EventManager();
            setThreeDayEvents(eventManager.getThreeDayEvents(events, activeDate));
            console.log(threeDayEvents);
        }
    }, [selectedApp, activeDate, events, threeDayEvents]);

    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    const handleHourBlockClick = (date, hour) => {
        const selectedDate = new Date(date.setHours(hour));
        alert(`Selected time: ${selectedDate.toLocaleString()}`);
    };

    // Function to format the date
    const formatDateShort = (date) => {
        return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    };

    // Function to format the date
    const formatDateLong = (date) => {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    // Calculate the dynamic height based on the state
    const wrapperHeight = `calc(100vh - ${monthPickerVisible ? '365px' : '150px'})`;

    // Use inline style to adjust height dynamically
    const wrapperStyle = {
        height: wrapperHeight,
        width: '100%',
    };

    const handleEventClick = (modelKey, objectKey) => {
        const objectManager = new ObjectManager();
        objectManager.fetch(selectedApp, modelKey, objectKey).then(fetchedObject => {
            setCalendarVisible(false);
            setBackToCalendar(true);
            setSelectedObject(fetchedObject);
        }).catch(error => {
            console.error("Failed to fetch object:", error);
            setSelectedObject(null);
        });
    }

    // Calculate left position for each event
    const calculateLeftPosition = (dayIndex) => {
        const totalWidth = window.innerWidth - 60; // Total width minus the 60px for the hours
        const columnWidth = totalWidth / 3; // There are three days
        return 60 + (columnWidth * dayIndex); // 60px offset + the width of each day times the index
    };

    // Calculate the dynamic height based on the state
    const scrollerHeight = `calc(100vh - ${monthPickerVisible ? '354px' : '140px'})`;

    // Use inline style to adjust height dynamically
    const scrollerStyle = {
        height: scrollerHeight,
        position: 'relative',
        overflowY: 'auto',
        zIndex: '2',
        width: '100%',
    };

    return (
        <div style={wrapperStyle} ref={hourBlocksRef}>
            <div className="calendar-three-days">
                {daysToShow.map((day, index) => (
                    <div key={index} className="calendar-three-day">
                        {formatDateShort(day)} {/* Using the formatDateShort function to display the date */}
                    </div>
                ))}
            </div>
            <div style={scrollerStyle}>
                {hours.map((hour, hourIndex) => (
                    <div className="calendar-three-hour-block" key={hourIndex}>
                        <div className="calendar-three-hour-label">{hour}</div>
                        {daysToShow.map((date, dayIndex) => (
                            <div key={dayIndex} className="calendar-three-hour-content" onClick={() => handleHourBlockClick(date, hourIndex)}>
                            </div>
                        ))}
                    </div>
                ))}

                {/* Events overlay, positioned to overlap the hour blocks but still part of the scrollable content */}
                <div className="calendar-three-events-overlay">

                    {threeDayEvents && threeDayEvents.map((event, index) => {
                        const startTime = event.startDate.toDate();
                        const endTime = event.endDate.toDate();
                        const modelKey = event.modelKey;
                        const objectKey = event.objectKey;

                        // Determine which day the event is on
                        const eventDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
                        const dayIndex = daysToShow.findIndex(d =>
                            d.getFullYear() === eventDate.getFullYear() &&
                            d.getMonth() === eventDate.getMonth() &&
                            d.getDate() === eventDate.getDate());

                        const top = (startTime.getHours() + startTime.getMinutes() / 60) * 70; // Assuming 70px per hour block
                        const height = ((endTime - startTime) / (1000 * 60 * 60)) * 70; // Duration in hours * height per hour
                        const left = calculateLeftPosition(dayIndex);

                        return (
                            <div
                                key={index}
                                className="calendar-three-event"
                                onClick={() => handleEventClick(modelKey, objectKey)}
                                style={{
                                    top: `${top}px`,
                                    height: `${height}px`,
                                    left: `${left}px`, // Set left as a pixel value
                                    position: 'absolute', // Ensure positioning is absolute
                                    width: `calc((100% - 80px) / 3)` // Dynamic width calculation for each day
                                }}
                            >
                                <div className="calendar-three-event-object">
                                    {event.objectTitle}
                                </div>
                                <div className="calendar-three-event-field">
                                    {event.fieldTitle}
                                </div>
                            </div>
                        );
                    })}


                </div>
            </div>
            <div className="calendar-three-days"></div>
        </div>
    );
};

export default CalendarThree;
