import React from 'react';

// Styles
import './CheckboxPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOnIcon from '../../../../svg/CheckOnIcon';

const CheckboxPreview = ({
    selected = false,
    placeholder = ''
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="checkbox-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <CheckOnIcon
                color={theme.highlightBackgroundColor}
                checkColor={theme.foregroundColor}
                width="30"
                height="30"
            />
            <div
                className="checkbox-preview-label"
                style={{ color: theme.foregroundColor }}>
                Complete?
            </div>
        </div>
    );
};

export default CheckboxPreview;
