import React from 'react';

const DocumentIcon = ({ color = "white", width = "38", height = "42" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 38 42">
    <path fill={color}
      d="M644,1332H621a2.99,2.99,0,0,0-2.539,1.42l-8.042,8.04A3,3,0,0,0,609,1344v27a3,3,0,0,0,3,3h32a3,3,0,0,0,3-3v-36A3,3,0,0,0,644,1332Zm-26,6.12V1341h-2.879ZM644,1371H612v-26.9c0.019-.02.043-0.02,0.06-0.04l0.061-.06H621v-8.88l0.061-.06c0.017-.02.023-0.04,0.039-0.06H644v36Zm-6-16H618a1.5,1.5,0,0,0,0,3h20A1.5,1.5,0,0,0,638,1355Zm-3,7H618a1.5,1.5,0,0,0,0,3h17A1.5,1.5,0,0,0,635,1362Zm-4-11h6a3,3,0,0,0,3-3v-6a3,3,0,0,0-3-3h-6a3,3,0,0,0-3,3v6A3,3,0,0,0,631,1351Zm0-9h6v6h-6v-6Z"
      transform="translate(-609 -1332)" />
  </svg>

);

export default DocumentIcon;
