import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './RunLogo.css';

// Theme
import { useTheme } from '../ThemeContext';

const RunLogo = () => {
  const { currentTheme, theme } = useTheme();
  const {
    changePage,
    currentUser,
    resetVisibility,
    selectedApp,
    setCurrentCanvas,
    setCurrentDevice
  } = useContext(Global);

  const handleClick = () => {

    resetVisibility();

    setCurrentDevice("MOBILE");
    setCurrentCanvas("FORM");

    if (currentUser) {
      changePage('HOME');
    } else {
      changePage('WELCOME');
    }
  };

  // Determine which logo to display based on the current theme
  const logoSrc = currentTheme === 'DARK' ? selectedApp.logoDark : selectedApp.logoLight;

  return (
    <div
      onClick={handleClick}
    >
      {logoSrc ? (
        <img src={logoSrc} alt="App Logo" className="run-logo-symbol" />
      ) : (
        <div className="run-logo-container">
          <div
            className="run-logo"
            style={{ color: theme.foregroundColor }}
          >
            {selectedApp.title}
          </div>
        </div>
      )}
    </div>
  );
};

export default RunLogo;