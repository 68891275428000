import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Components
import Modal from '../../foundation/Modal';

// Styles
import './UserSearch.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Managers
import UserIndexManager from '../../managers/UserIndexManager';

const userIndexManager = new UserIndexManager();

// Styled Components
const InlineStyles = createGlobalStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: 0.5; /* Adjusted opacity for better visibility */
  }
`;

const UserSearch = ({ onSearch }) => {
  const { theme } = useTheme();
  const { 
    selectedApp, 
    setUserSearchVisible,
    userSearchVisible
  } = useContext(Global);

  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [entries, setEntries] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);

  // Input reference
  const inputRef = useRef(null);
  const resultsRef = useRef([]);

  // Load all objects when the modal is displayed.
  useEffect(() => {
    if (!userSearchVisible) {
      setUserSearchTerm('');
      setSelectedResultIndex(-1);
      return;
    }

    // Focus the input
    if (inputRef.current) {
      inputRef.current.focus();
    }

    async function load() {
      const allEntries = await userIndexManager.listByApp(selectedApp.key);
      setEntries(allEntries);
    }

    load();
  }, [userSearchVisible, selectedApp.key]);

  // UserSearch the entries array whenever the userSearch term changes.
  useEffect(() => {
    if (userSearchTerm.length === 0) {
      setResults([]);
      return;
    }

    async function userSearch() {
      const r = userIndexManager.searchByApp(selectedApp.key, entries, userSearchTerm);
      setResults(r);
      setSelectedResultIndex(-1);
    }

    userSearch();
  }, [userSearchTerm, selectedApp.key, entries]);

  const handleResultClick = (entry) => {
    onSearch(entry);
    setUserSearchVisible(false);
    setUserSearchTerm('');
  };

  useEffect(() => {

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'Tab') {
        e.preventDefault(); // Prevent the default tab behavior
        setSelectedResultIndex((prevIndex) =>
          Math.min(prevIndex + 1, results.length - 1)
        );
      } else if (e.key === 'ArrowUp') {
        setSelectedResultIndex((prevIndex) =>
          Math.max(prevIndex - 1, 0)
        );
      } else if (e.key === 'Enter' && selectedResultIndex >= 0) {
        const entry = results[selectedResultIndex];
        onSearch(entry);
        setUserSearchVisible(false);
        setUserSearchTerm('');
      }
    };

    if (userSearchVisible) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [userSearchVisible, selectedResultIndex, results, onSearch, setUserSearchVisible, setUserSearchTerm]);

  useEffect(() => {
    if (selectedResultIndex >= 0 && resultsRef.current[selectedResultIndex]) {
      resultsRef.current[selectedResultIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedResultIndex]);

  return (
    <>
      <InlineStyles placeholderColor={theme.foregroundColorFaded} />

      <Modal title={`Search ${selectedApp && selectedApp.title}`} isOpen={userSearchVisible} onClose={() => setUserSearchVisible(false)} width="100%" height="100%">
        <div className="user-search-container">

          {/* SEARCH INPUT */}
          <input
            type="text"
            ref={inputRef}
            className="user-search-input"
            placeholder="Search users..."
            style={{
              backgroundColor: theme.backgroundColorFaded,
              color: theme.foregroundColor,
            }}
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
          />
        </div>

        {/* DIVIDER */}
        <div className="user-search-divider"
          style={{
            backgroundColor: theme.backgroundColorFaded,
          }}></div>

        {/* SEARCH RESULTS */}
        <div className="user-search-results-container">
          {results.map((result) => {
            const flattenedIndex = results.indexOf(result);
            return (
              <div
                key={result.id}
                ref={(el) => (resultsRef.current[flattenedIndex] = el)}
                className="user-search-result"
                style={{
                  color: flattenedIndex === selectedResultIndex ? theme.highlightForegroundColor : theme.foregroundColor,
                  backgroundColor: flattenedIndex === selectedResultIndex ? theme.highlightBackgroundColor : 'transparent',
                }}
                onClick={() => handleResultClick(result)}>
                {result.userName}
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default UserSearch;
