// Firebase
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';

class AdminManager {

    async fetchSetting(key) {
        try {
            const settingsCollection = collection(db, collections.admin);
            const q = query(settingsCollection, where('key', '==', key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                let docData = null;
                querySnapshot.forEach((doc) => {
                    docData = doc.data();
                });
                console.log(`Document data for ${key}:`, docData);
                return docData;
            } else {
                console.warn(`No document found for key: ${key}`);
                return null;
            }
        } catch (error) {
            console.error("Error fetching setting:", error);
            throw error;
        }
    }

    async updateSetting(key, value) {
        try {
            const settingsCollection = collection(db, '_admin');
            const q = query(settingsCollection, where('key', '==', key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach(async (doc) => {
                    const docRef = doc.ref;
                    await updateDoc(docRef, { value });
                    console.log(`Document ${key} updated with value: ${value}`);
                });
            } else {
                console.warn(`No document found for key: ${key}`);
            }
        } catch (error) {
            console.error("Error updating setting:", error);
            throw error;
        }
    }
}

export default AdminManager;
