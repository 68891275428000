import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Activity
import { activity } from '../../managers/ActivityManager';

// Styles
import './AppLogoEdit.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppLogoEdit = ({ title, backgroundColor, borderColor, mode }) => {
    const { theme } = useTheme();
    const {
        hideProgress,
        selectedApp,
        setSelectedApp,
        showProgress
    } = useContext(Global);

    const handleFileUpload = async (event) => {
        showProgress("Uploading...");

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop(); // Extract the file extension

        const image = document.createElement('img');
        image.onload = async () => {
            // Create canvas
            const maxDimension = 1000;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const ratio = Math.min(maxDimension / image.width, maxDimension / image.height);
            const width = image.width * ratio;
            const height = image.height * ratio;

            canvas.width = width;
            canvas.height = height;

            // Draw the image scaled down
            ctx.drawImage(image, 0, 0, width, height);

            // Convert canvas to blob
            canvas.toBlob(async (blob) => {
                // Reference to the folder where the app logos are stored
                const logoFolderRef = ref(storage, `logos/${mode.toLowerCase()}/`);

                try {
                    // List all files in the folder
                    const listResults = await listAll(logoFolderRef);

                    // Filter out files that belong to the current app in the specified mode
                    const existingFiles = listResults.items.filter((item) =>
                        item.name.startsWith(selectedApp.key)
                    );

                    // Delete each existing file
                    for (const fileRef of existingFiles) {
                        await deleteObject(fileRef);
                    }

                    // Upload the new file
                    const storageRef = ref(storage, `logos/${mode.toLowerCase()}/${selectedApp.key}.${fileExtension}`); // Use the mode in the file name
                    await uploadBytes(storageRef, blob); // Upload the blob
                    const fileUrl = await getDownloadURL(storageRef); // Get the URL

                    // Update the appropriate logo field based on the mode
                    const updatedApp = {
                        ...selectedApp
                    };
                    if (mode === 'DARK') {
                        updatedApp.logoDark = fileUrl;
                    } else {
                        updatedApp.logoLight = fileUrl;
                    }

                    setSelectedApp(updatedApp);

                    const data = {
                        ...updatedApp
                    };
                    await updateDoc(doc(db, collections.apps, selectedApp.key), data);

                    activity.log(selectedApp.key, 'writes', 1);

                    hideProgress();
                } catch (error) {
                    console.error("Error handling app logo upload:", error);
                    hideProgress();
                }
            }, `image/${fileExtension}`); // Specify the correct MIME type
        };

        image.src = URL.createObjectURL(file);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-logo-edit-container">

                {/* TITLE */}
                <div
                    className="app-logo-edit-title"
                    style={{ color: theme.foregroundColorFaded }}>
                    {title}
                </div>

                {/* LOGO WRAPPER */}
                <label htmlFor={`logo-upload-${selectedApp.key}-${mode}`}>
                    <div className="app-logo-edit-wrapper"
                        style={{
                            backgroundColor: backgroundColor,
                            borderColor: borderColor
                        }}>

                        {/* IMAGE */}
                        {selectedApp && (mode === 'DARK' ? selectedApp.logoDark : selectedApp.logoLight) &&
                            <img className="app-logo-edit-image"
                                alt="App Logo"
                                src={mode === 'DARK' ? selectedApp.logoDark : selectedApp.logoLight}
                            />
                        }

                    </div>
                </label>

                {/* BACKGROUND INPUT */}
                <input id={`logo-upload-${selectedApp.key}-${mode}`} type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
            </div>
        </>
    );
};

export default AppLogoEdit;