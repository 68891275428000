import React, { useContext, useEffect, useState } from 'react';

// Firebase
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';

// Charting
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Global
import { Global } from '../Global';

// Styles
import './Billing.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AppSelector from '../foundation/AppSelector';
import TimespanSelector from '../foundation/TimespanSelector';

const Billing = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        setUserApps,
        targetApp,
        timespan
    } = useContext(Global);

    // Costs
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({ reads: 0, writes: 0, deletes: 0 });
    const [totalCost, setTotalCost] = useState(0.0);

    useEffect(() => {
        const fetchApps = async () => {
            if (!currentUser || !currentUser.key) return;

            // Fetch all apps for the current user
            const appsQuery = query(
                collection(db, collections.apps),
                where("userKey", "==", currentUser.key)
            );
            const appsSnapshot = await getDocs(appsQuery);
            const apps = appsSnapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            setUserApps(apps);

            const now = new Date();
            const timeBounds = {
                'CURRENT': new Date(), // Adjust this to your current billing period start date
                'LAST60': new Date(now.setDate(now.getDate() - 60)),
                'LAST24': new Date(now.setDate(now.getDate() - 1)),
                'LAST7': new Date(now.setDate(now.getDate() - 7)),
                'LAST30': new Date(now.setDate(now.getDate() - 30))
            };

            const startDate = timeBounds[timespan];

            // Filter apps if a specific app is selected
            const filtered = targetApp === "ALL" ? apps : apps.filter(app => app.key === targetApp);

            // Fetch activity data for the filtered apps
            const activityPromises = filtered.map(async (app) => {
                const q = query(
                    collection(db, collections.activity),
                    where("appKey", "==", app.key),
                    where("timestamp", ">=", startDate),
                    orderBy("timestamp", "asc")
                );

                const querySnapshot = await getDocs(q);
                return querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    timestamp: doc.data().timestamp.toDate().toLocaleDateString()
                }));
            });

            const activityData = (await Promise.all(activityPromises)).flat();

            setData(activityData);

            const totals = activityData.reduce((acc, item) => ({
                reads: acc.reads + item.reads,
                writes: acc.writes + item.writes,
                deletes: acc.deletes + item.deletes,
            }), { reads: 0, writes: 0, deletes: 0 });
            setTotals(totals);

            const cost = totals.reads * 0.00000012 + totals.writes * 0.00000036 + totals.deletes * 0.00000004;
            setTotalCost(parseFloat(cost.toFixed(8)));
        };

        fetchApps();
    }, [timespan, currentUser, setUserApps, targetApp]);


    function CustomTooltip({ payload, active }) {
        if (active && payload && payload.length > 0) {
            return (
                <div className="billing-tooltip">
                    <p className="billing-label">{`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    return (
        <>
            <div className="billing-container"
                style={{ backgroundColor: theme.backgroundColor 
                    }}>
                <div className="billing-center">

                    {/* PROFITS */}
                    <div className="billing-page-header">
                        <div className="billing-selectors">
                            <AppSelector />
                            <TimespanSelector />
                        </div>
                    </div>

                    {/* COSTS */}
                    <div className="billing-totals">
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }} >{totals.reads}</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }} >Reads</div>
                        </div>
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }} >{totals.writes}</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }}>Writes</div>
                        </div>
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }} >{totals.deletes}</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }}>Deletes</div>
                        </div>
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }} >=</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }}></div>
                        </div>
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }} >{totalCost.toFixed(8)}</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }}>Total Costs</div>
                        </div>
                    </div>
                    {/**
                    <div className="billing-section-header">
                        <div className="billing-section-title"
                            style={{ color: theme.foregroundColor }} >
                            Costs
                        </div>
                        <div className="billing-chart-hint"
                            style={{ color: theme.foregroundColorFaded }} >
                            You will not be billed until your total cost exceeds $1.
                        </div>
                    </div>
                     */}
                    <div className="billing-chart">
                        <LineChart width={600} height={400} data={data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke={theme.backgroundColorFaded} />
                            <XAxis dataKey="timestamp" tick={{ fontSize: 12 }} />
                            <YAxis tick={{ fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line type="monotone" dataKey="reads" stroke={theme.highlightBackgroundColor} activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="writes" stroke="#777" />
                            <Line type="monotone" dataKey="deletes" stroke="#333" />
                        </LineChart>
                    </div>

                    {/* REVENUE */}
                    {/**
                    <div className="billing-section-header">
                        <div className="billing-section-title"
                                style={{ color: theme.foregroundColor }}>
                            Revenue
                        </div>
                    </div>
                    <div className="billing-chart">
                        <LineChart width={650} height={400} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke={theme.backgroundColorFaded} />
                            <XAxis dataKey="timestamp" tick={{ fontSize: 12 }} />
                            <YAxis tick={{ fontSize: 12 }} />
                            <Legend />
                            <Tooltip content={<CustomRevenueTooltip />} />
                            {filteredApps.map(app => (
                                <Line
                                    key={app.key}
                                    type="monotone"
                                    data={groupedTransactions[app.key] || []}
                                    dataKey="amount"
                                    name={getLineTitle(app)}
                                    stroke={getRandomGrayscale()}
                                    activeDot={{ r: 8 }}
                                />
                            ))}
                        </LineChart>
                    </div>
                    <div className="billing-totals">
                        <div className="billing-total-wrapper">
                            <div className="billing-total-value"
                                style={{ color: theme.foregroundColor }}>{formatCurrency(totalRevenue)}</div>
                            <div className="billing-total-label"
                                style={{ color: theme.foregroundColorFaded }}>App Revenue</div>
                        </div>
                    </div>
                     */}

                    {/* TRANSACTIONS */}
                    {/**
                    <div className="billing-section-header">
                        <div className="billing-section-title"
                                style={{ color: theme.foregroundColor }}>
                            Transactions
                        </div>
                    </div>
                    <table className="billing-table">
                        <thead>
                            <tr className="billing-table-header"
                                style={{ borderBottom: theme.backgroundColorFaded }}>
                                <td 
                                    className="billing-table-header-item" 
                                    style={{ flexGrow: '1', textAlign: 'left', color: theme.foregroundColorFaded }}>User</td>
                                <td 
                                    className="billing-table-header-item" 
                                    style={{ width: '200px', color: theme.foregroundColorFaded }}>App</td>
                                <td 
                                    className="billing-table-header-item" 
                                    style={{ width: '150px', color: theme.foregroundColorFaded }}>Amount</td>
                                <td 
                                    className="billing-table-header-item" 
                                    style={{ width: '200px', color: theme.foregroundColorFaded }}>Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction) => (
                                <tr className="billing-row" key={transaction.key}>
                                    <td className="billing-email" style={{ flexGrow: '1', textAlign: 'left', color: theme.foregroundColor }}>
                                        {transaction.payeeUsername}
                                    </td>
                                    <td className="billing-column" style={{ width: '200px', color: theme.foregroundColor }}>
                                        {transaction.appTitle} v{transaction.appVersion}
                                    </td>
                                    <td className="billing-column" style={{ width: '150px', color: theme.foregroundColor }}>
                                        {formatCurrency(transaction.amount)}
                                    </td>
                                    <td className="billing-column" style={{ width: '200px', color: theme.foregroundColor }}>
                                        {formatDate(transaction.dateCreated)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                     */}
                </div>
            </div>
        </>
    );
};

export default Billing;
