import React from 'react';

// Styles
import './CalendarEvent.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`

.calendar-event-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%; /* Height of the fade */
    background: linear-gradient(to bottom, transparent, ${(props) => props.gradientColor});
  }
    
`;

const CalendarEvent = ({ event }) => {
  const { theme } = useTheme();

  const getEventTimespan = (event) => {
    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const minutesStr = minutes === 0 ? '' : `:${minutes < 10 ? '0' + minutes : minutes}`;
      return `${hours}${minutesStr}${ampm}`;
    };

    const startTime = formatTime(event.startDate.toDate());
    const endTime = formatTime(event.endDate.toDate());

    return `${startTime} - ${endTime}`;
  };

  return (
    <>
      <InlineStyles
        gradientColor={theme.highlightBackgroundColor}
      />
      <div 
        className="calendar-event-title"
        style={{ color: theme.highlightForegroundColor }}>
        {event.title}
      </div>
      <div 
        className="calendar-event-time"  
        style={{ color: theme.highlightForegroundColor }}>
        {getEventTimespan(event)}
      </div>
      <div className="calendar-event-details" style={{ color: theme.highlightForegroundColor }}>
        {event.attachments.map((item, index) => (
          <div key={index} 
          className="calendar-event-detail">
              {item.objectTitle}
            </div>
        ))}
        {Object.entries(event.formData)
          .filter(([key, value]) => {
            // Find the field in event.fields to check its type
            const field = event.fields.find(f => f.key === key);
            return field && field.type !== 'checklist';
          })
          .map(([key, value], index) => (
            <div key={index} className="calendar-event-detail">
              {Array.isArray(value) 
                ? value.map(item => <div key={item.key}>{item.note || ''}</div>) 
                : value}
            </div>
          ))}
      </div>
      <div className="calendar-event-fade"></div>
    </>
  );
};

export default CalendarEvent;
