import React from 'react';

// Styles
import './JoinedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const JoinedHeadline = ({ headline, handleUserClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;joined.
            {timestamp}
        </>
    );
};

export default JoinedHeadline;
