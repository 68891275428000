import React from 'react';

// Styles
import './CheckedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const CheckedHeadline = ({ headline, handleUserClick, handleObjectClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
        <span
          className="run-home-token"
          style={{ color: theme.foregroundColor }}
          onClick={() => handleUserClick(headline.userKey)}>
          {headline.userFirstName} {headline.userLastName}
        </span>&nbsp;
        {headline.value} {headline.fieldTitle} in&nbsp;
        <span
          className="run-home-token"
          style={{ color: theme.foregroundColor }}
          onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}>
          {headline.objectTitle}
        </span>.&nbsp;
        {timestamp}
      </>
    );
};

export default CheckedHeadline;
