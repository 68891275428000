import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Run.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from './account/billing/AccountBilling';
import AccountMenu from './account/menu/AccountMenu';
import AppUserProfile from './/appusers/AppUserProfile';
import AppUsers from './appusers/AppUsers';
import Calendar from './calendar/Calendar';
import FieldSelector from '../run/form/fieldselector/FieldSelector';
import Form from './form/Form';
import Menu from './menu/Menu';
import Messages from './messages/Messages';
import Objects from './objects/Objects';
import Pay from '../pay/Pay';
import Rate from './rate/Rate';
import Reports from './reports/Reports';
import RunHome from './runhome/RunHome';
import RunHeader from './RunHeader';
import Settings from './settings/Settings';
import TitleBar from '../foundation/TitleBar';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`
    
    body {
      margin: 0;
      font-family: ${(props) => props.fontFamily} !important;
      display: flex;
      flex-direction: column;
    }

    ::selection {
        background: ${(props) => props.selectionBackground};
        color: ${(props) => props.selectionForeground};
    }

    input::selection, textarea::selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

    input::-moz-selection, textarea::-moz-selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

`;

const Run = () => {
  const { theme } = useTheme();
  const {
    accountAboutVisible,
    accountBillingVisible,
    accountVisible,
    formMode,
    appUsersVisible,
    calendarVisible,
    currentUser,
    fieldSelectorVisible,
    menuVisible,
    messagesVisible,
    objectsView,
    payVisible,
    rateVisible,
    reportsVisible,
    runHomeVisible,
    selectedApp,
    selectedAppUser,
    selectedModel,
    selectedObject,
    setMenuVisible,
    settingsVisible,
    fieldVisible
  } = useContext(Global);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const renderContent = () => {
    if (fieldSelectorVisible) return <FieldSelector size="LARGE" />;
    if ((selectedObject && !fieldVisible) || (
      selectedModel &&
      !objectsView === "TABLE" && // ADD BUTTON CLICKED
      selectedObject === null &&
      formMode === "ADD"
    )
    ) return <Form />;
    if (selectedAppUser) return <AppUserProfile />;
    if (calendarVisible) return <Calendar />;
    if (settingsVisible) return <Settings />;
    if (reportsVisible) return <Reports />;
    if (runHomeVisible) return <RunHome />;
    if (appUsersVisible) return <AppUsers />;
    if (messagesVisible) return <Messages />;
    if (accountVisible) {
      if (isMobile) {
        if (accountAboutVisible) {
          return <AccountAbout />;
        } else if (accountBillingVisible) {
          return <AccountBilling />;
        } else {
          return <AccountMenu />;
        }
      } else {
        return <AccountMenu />;
      }
    }
    return <Objects />;
  };

  /**
   * Function to load only the regular variant of a font.
   * 
   * @param {string} fontName - Name of the font.
   */
  const loadGoogleFont = (fontName) => {
    const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadGoogleFont(selectedApp.fontFamily || 'Red Hat Display');
  }, [selectedApp]);

  return (
    <>
      <InlineStyles
        fontFamily={selectedApp && selectedApp.fontFamily || 'Bokor'}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="run-container"
        style={{ backgroundColor: theme.backgroundColor }}>
        {selectedApp &&
          selectedApp.userKey === currentUser.key &&
          !isMobile &&
          !isTablet && (
            <div className="run-title-bar">
              <TitleBar />
            </div>
          )}
        {payVisible && <Pay />}
        {rateVisible && <Rate />}
        {menuVisible && (
          <div className="run-menu-mask" onClick={closeMenu}></div>
        )}
        <div className={isMobile ? "run-center" : "run-center-mobile"} style={{ backgroundColor: theme.backgroundColor }}>
          <div className={`run-menu ${menuVisible ? 'visible' : ''}`}>
            <Menu />
          </div>
          <div className="run-content">
            <RunHeader />
            <div className="run-summaries">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Run;
