import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './Relationships.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DataIcon from '../../../../svg/DataIcon';
import RemoveIcon from '../../../../svg/RemoveIcon';

// Components
import Search from '../../../search/Search';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

// Managers
import ObjectManager from '../../../../managers/ObjectManager';

const objectManager = new ObjectManager();

const Relationships = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true
}) => {
    const { theme } = useTheme();
    const {
        models,
        selectedApp,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // State Variables
    const [value, setValue] = useState([]); // Default ADD mode value
    const [groupedEntries, setGroupedEntries] = useState({});
    const [searchVisible, setSearchVisible] = useState(false);

    useEffect(() => {
        setValue(object?.[field.key] || []);
    }, [object, field.key]);

    useEffect(() => {
        if (!value) return;

        const entries = value.reduce((acc, entry) => {
            if (!acc[entry.modelKey]) {
                acc[entry.modelKey] = [];
            }
            acc[entry.modelKey].push(entry);
            return acc;
        }, {});

        setGroupedEntries(entries);

    }, [value]);

    const handleAdd = () => {
        setSearchVisible(true);
    };

    const onSearch = (entry) => {
        setValue((prevEntries) => {
            // Check if the entry is already in the selected entries
            if (prevEntries.some((e) => e.objectKey === entry.objectKey)) {
                // Call onUpdate with the current value if no new entry is added
                //onUpdate(field, prevEntries);
                return prevEntries;
            }
            const newEntries = [...prevEntries, entry];
            // Call onUpdate with the new entries
            onUpdate(field, newEntries);
            return newEntries;
        });
        setSearchVisible(false);
    };

    const handleRemove = (entryToRemove) => {
        setValue((prevEntries) =>
            prevEntries.filter((entry) => entry.id !== entryToRemove.id)
        );
    };

    const getModelTitles = () => {
        return models.slice(0, 2).map(model => model.title).join(', ') + ', etc...';
    };

    const handleForward = async (entry) => {
        // Find the model in the models array and set it to active
        const model = models.find(model => model.key === entry.modelKey);
        setSelectedModel(model);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setSelectedObject(object);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                />

                {value.length > 0 &&
                    <>
                        {/* LIST */}
                        <div className="relationships-list-wrapper">
                            {Object.keys(groupedEntries).map((modelKey) => {
                                const groupModel = models.find((model) => model.key === modelKey);
                                return (
                                    <div key={modelKey} className="relationships-group">
                                        <div className="relationships-group-title" style={{ color: theme.foregroundColorFaded }}>
                                            {groupModel ? groupModel.title : 'Unknown Model'}
                                        </div>
                                        {groupedEntries[modelKey].map((entry) => (
                                            <div key={entry.id} className="relationships-entry-wrapper" style={{ color: theme.foregroundColor }}>
                                                <div className="relationships-entry-title"
                                                    onClick={() => handleForward(entry)}>
                                                    {entry.objectTitle}
                                                </div>
                                                <div onClick={() => handleRemove(entry)} style={{ marginLeft: '10px' }}>
                                                    <RemoveIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="20"
                                                        height="20"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }

                {/* ADD BUTTON */}
                <div className="relationships-add-wrapper" onClick={handleAdd}>
                    <div
                        className="relationships-add-button">
                        <DataIcon color={theme.foregroundColorFaded} width="14" height="14" />
                    </div>
                    <div className="relationships-add-label" style={{ color: theme.foregroundColorFaded }}>
                        Add {getModelTitles()}
                    </div>
                </div>

                {/* SEARCH MODAL */}
                <Search
                    onSearch={onSearch}
                    isVisible={searchVisible}
                    setVisible={setSearchVisible}
                />

            </FieldContainer>
        </>
    );
};

export default Relationships;
