/**
 * Formats a phone number, e.g. "(406) 580-1546".
 */
export function formatPhoneNumber(value) {
  const numbers = value.replace(/\D/g, '');
  const phoneNumber = numbers.substring(0, 10); // Limit to 10 digits
  const areaCode = phoneNumber.substring(0, 3);
  const middle = phoneNumber.substring(3, 6);
  const last = phoneNumber.substring(6, 10);

  let formattedValue = '';
  if (phoneNumber.length > 6) {
    formattedValue = `(${areaCode}) ${middle}-${last}`;
  } else if (phoneNumber.length > 3) {
    formattedValue = `(${areaCode}) ${middle}`;
  } else if (phoneNumber.length > 0) {
    formattedValue = `(${areaCode}`;
  }
  return formattedValue;
}

/**
 * Function to format currency as a decimal to 2 places, applied on blur.
 */
export function formatCurrency(value) {
  if (!value) return '';
  const numValue = parseFloat(value).toFixed(2);
  return isNaN(numValue) ? '' : numValue;
}

/**
 * Function to format number, removing unnecessary zeros and trailing decimal points.
 */
export function formatNumber(value) {
  const trimmedValue = value.replace(/(\.\d*?[1-9])0+$|\.0*$/, '$1');
  return trimmedValue ? Number(trimmedValue).toString() : '';
}

/**
 * Function to format date, e.g. "Wed, Jul 10, 2024"
 */
export function formatTimestamp(date) {
  if (date && date.toDate) {
    date = date.toDate();
  }
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

/**
 * Function to format a JavaScript Date object, e.g. "Wed, Jul 10, 2024"
 */
export function formatDate(date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid date object');
  }
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

/**
 * Function to format day and date, e.g. "Wednesday the 31st"
 */
export function formatDayAndDate(date) {
  const formatDateSuffix = (date) => {
      if (date > 3 && date < 21) return 'th';
      switch (date % 10) {
          case 1: return "st";
          case 2: return "nd";
          case 3: return "rd";
          default: return "th";
      }
  }

  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  return `${weekdays[date.getDay()]} the ${date.getDate()}${formatDateSuffix(date.getDate())}`;
};