import React from 'react';

// Styles
import './ListedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const ListedHeadline = ({ headline, handleUserClick, handleObjectClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;

            {headline.action === "ADDED" ? (
                <>
                    added
                </>
            ) : (
                <>
                    removed
                </>
            )}&nbsp;
            {headline.value}&nbsp;
            {headline.action === "ADDED" ? (
                <>
                    to
                </>
            ) : (
                <>
                    from
                </>
            )}&nbsp;
            {headline.fieldTitle} in&nbsp;
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}>
                {headline.objectTitle}
            </span>.&nbsp;
            {timestamp}
        </>
    );
};

export default ListedHeadline;
