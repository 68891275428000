import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Formatter
import { formatPhoneNumber } from '../../../../utilities/Formatters';

// Styles
import './Phone.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import MessageIcon from '../../../../svg/MessageIcon';
import PhoneIcon from '../../../../svg/PhoneIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

/**
 * Phone Component
 * 
 * This component renders an input field for a phone number. Also includes
 * buttons to call or text the number if available.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {boolean} readOnly - Indicates whether the input should be read-only.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Phone = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    // Theme
    const { theme } = useTheme();
    const {
        selectedModel
    } = useContext(Global);

    // State Variables
    const [value, setValue] = useState(''); // Default ADD mode value
    const [showButtons, setShowButtons] = useState(false);

    // References
    const inputRef = useRef(null);

    /** 
     * Updates the showButtons state when the value changes.
     */
    useEffect(() => {
        setShowButtons(value.length !== 0);
    }, [value]);

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        if (object && object[field.key] !== undefined) {
            setValue(formatPhoneNumber(object[field.key] || ''));
        }
    }, [object, field.key]);

    /**
     * Method to focus the input when the container is clicked.
     * 
     * @param {string} e - Click event.
     */
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    /**
     * Method to handle the blur of the field, which sends the update.
     */
    const handleBlur = () => {
        if (readOnly) return;
        onUpdate(field, value);
    };

    /**
     * Method to handle the change in value.
     * 
     * @param {string} e - Change event.
     */
    const handleChange = (e) => {
        const input = e.target;
        const cursorPosition = input.selectionStart;
        const rawValue = e.target.value;

        // Format the phone number as the user types
        const formattedValue = formatPhoneNumber(rawValue);

        setValue(formattedValue);

        // Calculate new cursor position
        const diff = formattedValue.length - rawValue.length;
        const newCursorPosition = cursorPosition + diff;

        // Set the cursor position
        setTimeout(() => {
            input.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
    };

    /**
     * Method to handle the click on the phone icon.
     */
    const handlePhoneClick = (e) => {
        window.location.href = `tel:${value}`;
    };

    /**
     * Method to handle the click on the sms icon.
     */
    const handleSmsClick = (e) => {
        window.location.href = `sms:${value}`;
    };

    /**
     * Additional custom menu options for the phone field.
     */
    const menuOptions = [
        {
            text: 'Call',
            icon: PhoneIcon,
            onClick: () => {
                handlePhoneClick();
            }
        },
        {
            text: 'Send Text',
            icon: MessageIcon,
            onClick: () => {
                handleSmsClick();
            }
        },
    ];

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                    additionalOptions={menuOptions}
                />

                {/* WRAPPER */}
                <div className="phone-wrapper">

                    {/* INPUT */}
                    <input
                        type="text"
                        ref={inputRef}
                        className="phone-input"
                        style={{
                            color: theme.foregroundColor,
                            width: showButtons ? "200px" : "100%"
                        }}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                    />

                    {/* BUTTONS */}
                    {showButtons &&
                        <div className="phone-buttons">

                            {/* SMS */}
                            <div className="phone-sms-button" onClick={handleSmsClick} title="Text">
                                <MessageIcon
                                    color={theme.foregroundColor}
                                    width="22"
                                    height="22"
                                />
                            </div>

                            {/* CALL */}
                            <div className="phone-phone-button" onClick={handlePhoneClick} title="Call">
                                <PhoneIcon
                                    color={theme.foregroundColor}
                                    width="20"
                                    height="20"
                                />
                            </div>
                        </div>
                    }
                </div>
            </FieldContainer>
        </>
    );
};

export default Phone;
