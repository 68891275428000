import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './MonthPickerButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import ExpandLessIcon from '../../svg/ExpandLessIcon';
import ExpandMoreIcon from '../../svg/ExpandMoreIcon';

const MonthPickerButton = () => {
    const { theme } = useTheme();
    const {
        activeDate, 
        currentYear,
        currentMonth,
        monthPickerVisible,
        setActiveDate,
        setMonthPickerVisible,
    } = useContext(Global);

    const [title, setTitle] = useState("");

    useEffect(() => {
        const updateTitle = () => {
            const date = new Date();
            date.setFullYear(currentYear);
            date.setMonth(currentMonth);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", 
                "Aug", "Sept", "Oct", "Nov", "Dec"];
            setTitle(`${months[currentMonth]} ${currentYear}`);
        };
        
        updateTitle();
    }, [currentYear, currentMonth]);

    useEffect(() => {
        if (!activeDate) {
            // Initialize with today's date if not set
            setActiveDate(new Date()); 
        }
    }, [activeDate, setActiveDate]);

    const handleTitleClick = () => {
        setMonthPickerVisible(!monthPickerVisible);
    };

    return (
        <div className="month-picker-container">
            <div className="month-picker-title-container"
                onClick={handleTitleClick}>
                <div className="month-picker-title"
                    style={{color: theme.foregroundColor}}
                >
                    {title}
                </div>
                <div className="month-picker-expand-icon">
                    {monthPickerVisible ? (
                        <ExpandLessIcon
                            color={theme.foregroundColor}
                            width="16"
                            height="16"
                        />
                    ) : (
                        <ExpandMoreIcon
                            color={theme.foregroundColor}
                            width="16"
                            height="16"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MonthPickerButton;
