import React, { useEffect, useState } from 'react';

// Styles
import './LookupCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LookupCell Component
 * 
 * This component renders an input field that accepts text values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LookupCell = ({
  object,
  field,
  handleBlur
}) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');
  const [options, setOptions] = useState([]);

  const backgroundColor = "transparent";
  const foregroundColor = theme.foregroundColor;

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
    setOptions(field.options);
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    handleBlur(object.key, field.key, inputValue);
  };

  return (
    <div className="lookup-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}>
      <select
        className="lookup-cell-input"
        style={{ color: theme.foregroundColor }}
        value={value}
        onChange={handleChange}>
        <option value="" {...(value !== '' ? { disabled: true } : {})}></option>
        {options &&
          <>
            {options.map(option => (
              <option key={option.key} value={option.key}>{option.title}</option>
            ))}
          </>
        }
      </select>
    </div>
  );
};

export default LookupCell;
