import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Drag and Drop - Sorting
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './ObjectChecklist.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../../svg/PlusIcon';

// Components
import ObjectChecklistAddButton from './ObjectChecklistAddButton';
import ObjectChecklistItem from './ObjectChecklistItem';
import ObjectChecklistNoteForm from './ObjectChecklistNoteForm';
import FieldMenu from '../../fieldmenu/FieldMenu';
import Search from '../../../search/Search';

// Managers
import ObjectManager from '../../../../managers/ObjectManager';

const objectManager = new ObjectManager();

/**
 * Component for rendering a checklist.
 *
 * @param {string} object - Object to render the checklist for.
 * @param {string} field - Field to render the checklist for.
 * @param {function} onUpdate - Function to call when the checklist is updated.
 * @param {boolean} showFieldMenu - Whether to show the field menu.
 * 
 * @return {ObjectChecklist} - The checklist component.
 */
const ObjectChecklist = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme } = useTheme();
    const { 
        models,
        selectedApp,
        setSelectedModel, 
        setSelectedObject,
        userRole 
    } = useContext(Global);

    // State variables
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchVisible, setSearchVisible] = useState(false);
    const [noteFormOpen, setNoteFormOpen] = useState(false);

    /**
     * Initializes the items state variable.
     */
    useEffect(() => {
        setItems(object?.[field.key] || []);
    }, [object, field.key]);

    /**
     * Moves an item from one index to another.
     *
     * @param {integer} dragIndex - Index of the item being dragged.
     * @param {integer} hoverIndex - Index of the item being hovered over.
     */
    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = items[dragIndex];
        const updatedItems = [...items];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Toggles the checked state of an item.
     *
     * @param {string} itemKey - Key of the item to toggle.
     */
    const handleCheckboxChange = (itemKey) => {
        const updatedItems = items.map(item =>
            item.objectKey === itemKey ? { ...item, checked: !item.checked } : item
        );
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Handles a click on the add button.
     */
    const handleAddButtonClick = () => {
        setSearchVisible(true);
    };

    /**
     * Handles the search result selection.
     *
     * @param {string} entry - Search index entry.
     */
    const handleSearch = (entry) => {
        setItems((prevEntries) => {
            // Check if the entry is already in the selected entries
            if (prevEntries.some((e) => e.objectKey === entry.objectKey)) {
                return prevEntries;
            }
            const updatedItems = [...prevEntries, entry];
            onUpdate(field, updatedItems);
            return updatedItems;
        });

        setSearchVisible(false);
    };

    /**
     * Handles the saving of a note.
     *
     * @param {string} value - Search index entry.
     */
    const handleNoteSave = (entry) => {
        const updatedItems = items.map(item =>
            item.objectKey === entry.objectKey ? { ...item, note: entry.note } : item
        );
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Removes an item from the checklist.
     *
     * @param {string} item - Item to remove.
     */
    const handleRemoveClick = (item) => {
        const updatedItems = items.filter(i => i.objectKey !== item.objectKey);
        setItems(updatedItems);
        onUpdate(field, updatedItems);
    };

    /**
     * Handles a click on an object in the checklist.
     */
    const handleViewClick = async (item) => {
        const object = await objectManager.fetch(selectedApp.key, item.modelKey, item.objectKey);
        const model = models.find((m) => m.key === item.modelKey);
        setSelectedModel(model);
        setSelectedObject(object);
    };

    /**
     * Handles a click on the note option in the menu.
     */
    const handleNoteClick = async (item) => {
        setSelectedItem(item);
        setNoteFormOpen(true);
    };

    /**
     * Renders a single checklist item.
     *
     * @param {object} item - ObjectChecklist item to render.
     * @param {integer} index - Index of the item in the items array.
     */
    const renderItem = (item, index) => (
        <div key={item.key} className="object-checklist-item">
            <ObjectChecklistItem
                key={item.key}
                index={index}
                item={item}
                moveItem={moveItem}
                onChange={handleCheckboxChange}
                onRemoveClick={handleRemoveClick}
                onViewClick={handleViewClick}
                onNoteClick={handleNoteClick}
                readOnly={readOnly}
            />
        </div>
    );

    const menuOptions = [
        {
            text: 'Add List Item',
            icon: PlusIcon,
            onClick: () => {
                setSearchVisible(true);
            }
        }
    ];

    return (

        <>

            {/* CONTAINER */}
            <DndProvider backend={HTML5Backend}>

                <div className="object-checklist-container" 
                    style={{ borderColor: readOnly ? 'transparent' : theme.backgroundColorFaded }}>

                    {/* HEADER */}
                    <div className="object-checklist-header">
                        <div className="object-checklist-label" style={{ color: theme.foregroundColorFaded }}>
                            {field.title}
                        </div>
                        <div>
                            {userRole === "ADMIN" && showFieldMenu &&
                                <FieldMenu
                                    key={field.key}
                                    field={field}
                                    additionalOptions={menuOptions}
                                />
                            }
                        </div>
                    </div>

                    {/* ITEMS */}
                    <div className="object-checklist-items">
                        {items.map((item, index) => renderItem(item, index))}
                    </div>

                    {/* ADD BUTTON */}
                    {!readOnly &&
                        <div className='object-checklist-footer-wrapper'>
                            <ObjectChecklistAddButton
                                onClick={handleAddButtonClick}
                            />
                        </div>
                    }

                </div>

            </DndProvider>

            {/* SEARCH MODAL */}
            <Search
                onSearch={handleSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
                modelKey={field.checklistModelKey || ""}
            />

            {/* NOTE FORM */}
            <ObjectChecklistNoteForm
                modalOpen={noteFormOpen}
                setModalOpen={setNoteFormOpen}
                item={selectedItem}
                onSave={handleNoteSave}
            />

        </>

    );
};

export default ObjectChecklist;
