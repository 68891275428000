import React, { useEffect, useState } from 'react';

// Styles
import './TextCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * TextCell Component
 * 
 * This component renders an input field that accepts text values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TextCell = ({ object, field, handleBlur }) => {
  const { theme } = useTheme();

  const [value, setValue] = useState(object[field.key] || '');
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [foregroundColor, setForegroundColor] = useState(theme.foregroundColor || "transparent");

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  /**
   * Handle input change
   * 
   * This function sets the value to the input value.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  /**
   * Handle input blur
   * 
   * This function resets the background and foreground colors,
   * and calls the handleBlur function passed as a prop.
   * 
   * @param {Object} e - The event object.
   */
  const handleFieldBlur = (e) => {
    setBackgroundColor("transparent");
    setForegroundColor(theme.foregroundColor);
    handleBlur(object.key, field.key, value);
  }

  /**
   * Handle input focus
   * 
   * This function sets the background and foreground colors to highlight colors
   * when the input is focused.
   * 
   * @param {Object} e - The event object.
   */
  const handleFocus = (e) => {
    setBackgroundColor(theme.backgroundColorFaded);
    setForegroundColor(theme.backgroundColorFaded);
  }

  return (
    <div className="text-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}
    >
      <input
        type="text"
        className="text-cell-input"
        style={{
          color: theme.foregroundColor
        }}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleFieldBlur}
      />
    </div>
  );
};

export default TextCell;
