import React from 'react';

// Styles
import './CommentButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CommentIcon from '../../../../svg/CommentIcon';

const CommentButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="comment-button-container">
            <CommentIcon
                color={theme.foregroundColor}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default CommentButton;
