import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './MenuButton.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import MenuIcon from '../svg/MenuIcon';

const MenuButton = () => {
  const { theme } = useTheme();
  const {
    menuVisible,
    setMenuVisible
  } = useContext(Global);

  /**
   * TOGGLE MENU
   */
  const toggleMenu = async () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <div className="menu-button-container"
        onClick={toggleMenu}>
          <div className="profile-button-icon-wrapper">
            <MenuIcon
              color={theme.foregroundColor}
              width="25"
              height="25"
            />
          </div>
      </div>
    </>
  );
};

export default MenuButton;
