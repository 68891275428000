import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ChannelItem.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Styled Components
import { createGlobalStyle } from 'styled-components';

// Images
import ChannelIcon from '../../../svg/ChannelIcon';

// Components
import ChannelItemMenu from './ChannelItemMenu';

// Inline Styles
const InlineStyles = createGlobalStyle`
    .channel-item-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * ChannelItem Component
 * 
 * This component renders a channel item in the menu.
 * 
 * @param {object} channel - The channel to represent in the menu.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelItem = ({ channel, isActive, onClick }) => {
    const { theme } = useTheme();
    const {
        userRole,
    } = useContext(Global);

    return (
        <>
            {/* STYLES */}
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />

            {/* CONTAINER */}
            <div className="channel-item-container"
                style={{
                    backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent'
                }}>
                <div className="channel-item-left"
                    onClick={onClick}>

                    {/* ICON */}
                    <ChannelIcon
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColor}
                        width="18"
                        height="14"
                    />

                    {/* TEXT */}
                    <div className="channel-item-text"
                        style={{
                            color: isActive ? theme.highlightForegroundColor : theme.foregroundColor
                        }}>
                        {channel && channel.title}
                    </div>
                </div>

                {/* MENU */}
                {userRole === "ADMIN" &&
                    <div className="channel-item-right">
                        <ChannelItemMenu channel={channel} />
                    </div>
                }
            </div>
        </>
    );
};

export default ChannelItem;
