import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Apps.css';

// Components
import AppItem from '../AppItem';

// Managers
import AppManager from '../../managers/AppManager';

const appManager = new AppManager();

const Apps = () => {
    const { 
        apps,
        currentUser,
        setApps
    } = useContext(Global);


    useEffect(() => {

        if (!currentUser) return;

        async function fetchUserApps() {
            try {

                const results = await appManager.fetchUserApps(currentUser.key);
                setApps(results);

            } catch (error) {
                console.error('Failed to fetch user apps:', error);
            }
        }
        fetchUserApps();

    }, [currentUser, setApps]);

    return (
        <div className="build-apps-background"
            style={{
                gridTemplateColumns: apps.length > 0 ? 'repeat(3, 1fr)' : 'none',
            }}>
            {apps.map(app => (
                <AppItem
                    key={app.key}
                    app={app}
                />
            ))}
        </div>
    );
};

export default Apps;
