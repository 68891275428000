import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Theme
import { useTheme } from '../../ThemeContext';

// Styles
import './Reports.css';

// Managers
import ReportManager from '../../managers/ReportManager';

const Reports = () => {
  const { theme } = useTheme();
  const {
    models,
    selectedApp,
    setReportsVisible,
    setSelectedModel
  } = useContext(Global);

  const [reports, setReports] = useState([]);
  const [aggregateValues, setAggregateValues] = useState([]);

  // Fetch reports
  useEffect(() => {
    const reportManager = new ReportManager();

    // Define the callback function
    const handleUpdate = (items) => {
      setReports(items);
    };

    const unsubscribe = reportManager.fetchReportsAndSubscribe(selectedApp.key, handleUpdate);

    // Cleanup function to unsubscribe from the updates when the component unmounts
    return () => unsubscribe();
  }, [selectedApp.key]);

  useEffect(() => {
    reports.forEach((report, index) => {
      generateReport(report.definition, index)
        .then(total => {
          setAggregateValues(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = total;
            return newValues;
          });
        })
        .catch(error => {
          console.error(`Error generating report ${report.title}: `, error);
        });
    });
  }, [reports]);

  const generateReport = async (report, index, params = {}) => {
    if (!report) {
      throw new Error("Report not found");
    }

    // Construct the query based on the report definition
    let baseQuery = collection(db, report.modelKey);

    // Apply filters to the query
    let constraints = [];
    if (report.filters && report.filters.length > 0) {
      report.filters.forEach(filter => {
        let filterValue = filter.value;
        // Replace placeholders with actual parameter values
        for (const [key, value] of Object.entries(params)) {
          filterValue = filterValue.replace(`{${key}}`, value);
        }
        if (filter.operation === "equals") {
          constraints.push(where(filter.field, '==', filterValue));
        }
        // You can add more filter operations here if needed
      });
    }

    // Combine base query with constraints
    const q = query(baseQuery, ...constraints);

    // Execute the query
    const snapshot = await getDocs(q);

    // Initialize the result container
    let results;

    if (report.groupBy) {
      // If grouping is specified
      results = {};

      // Aggregate results based on the defined aggregation and grouping
      snapshot.forEach(doc => {
        const data = doc.data();
        const groupByKey = data[report.groupBy];
        let amount = data[report.aggregation.field] ?? 0; // Default to 0 if null or undefined

        if (report.aggregation.type === "currency") {
          amount = parseFloat(amount); // Parse as float for currency
        }

        if (!results[groupByKey]) {
          results[groupByKey] = 0;
        }
        results[groupByKey] += amount;
      });
    } else {
      // If no grouping is specified, just sum the amounts
      results = 0;

      snapshot.forEach(doc => {
        let amount = doc.data()[report.aggregation.field] ?? 0; // Default to 0 if null or undefined
        if (report.aggregation.type === "currency") {
          amount = parseFloat(amount); // Parse as float for currency
        }
        results += amount;
      });
    }

    // Format results as currency if necessary
    if (report.aggregation.type === "currency") {
      results = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(results);
    }

    return results;
  };

  const handleClick = (modelKey) => {
    const model = models.find(model => model.key === modelKey);
    setReportsVisible(false);
    setSelectedModel(model);
  };

  return (
    <>
      <div className={isMobile || isTablet ? "run-reports-container-mobile" : "run-reports-container"}>
        <div className="run-reports-container-column">
          {reports.slice(0, Math.ceil(reports.length / 2)).map((report, index) => (
            report && report.pin && report.pin === true && (
              <div key={report.id} className="report-wrapper"
                onClick={() => handleClick(report.definition.modelKey)}
                style={{
                  backgroundColor: theme.backgroundColor,
                  borderColor: theme.backgroundColorFaded
                }}
              >
                <div className="report-title"
                  style={{ color: theme.foregroundColorFaded }}
                >
                  {report.title}
                </div>
                <div className="report-value"
                  style={{ color: theme.foregroundColor }}
                >
                  {aggregateValues[index]}
                </div>
              </div>
            )
          ))}
        </div>
        <div className="run-reports-container-column">
          {reports.slice(Math.ceil(reports.length / 2)).map((report, index) => (
            <div key={report.id} className="report-wrapper"
              onClick={() => handleClick(report.definition.modelKey)}
              style={{
                backgroundColor: theme.backgroundColor,
                borderColor: theme.backgroundColorFaded
              }}
            >
              <div className="report-title"
                style={{ color: theme.foregroundColorFaded }}
              >
                {report.title}
              </div>
              <div className="report-value"
                style={{ color: theme.foregroundColor }}
              >
                {aggregateValues[index + Math.ceil(reports.length / 2)]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Reports;
