import React, { useEffect, useRef, useState } from 'react';

// Formatter
import { formatNumber } from '../../../../utilities/Formatters';

// Styles
import './Number.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Number = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly={readOnly}
}) => {
    const { theme } = useTheme();

    const [value, setValue] = useState(''); // Default ADD mode value

    const inputRef = useRef(null);

    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    // Focus the input when the container is clicked
    const handleContainerClick = (e) => {
        if (readOnly) return;
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        // Apply more specific formatting on blur
        const formattedValue = formatNumber(value);
        setValue(formattedValue);
        onUpdate(field, formattedValue);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        // Light formatting to remove non-numeric characters, allowing for decimal points
        const formattedValue = value.replace(/[^0-9.]+|^(0+(?!\.))/g, '');
        setValue(formattedValue);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* INPUT */}
                <input
                    type="text"
                    ref={inputRef}
                    className="number-input"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ color: theme.foregroundColor }}
                    readOnly={readOnly}
                />

            </FieldContainer>
        </>
    );
};

export default Number;
