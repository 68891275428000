import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../firebaseConfig';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './FieldRename.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../../foundation/Modal';

const FieldRename = ({ 
    field,
    fieldTitle, 
    setFieldTitle, 
    renameOpen, 
    setRenameOpen 
}) => {
        const { theme } = useTheme();
        const {
            formFields,
            selectedApp,
            setFormFields
        } = useContext(Global);

    const rename = async () => {
        const data = {
            title: fieldTitle
        };

        try {
            await updateDoc(doc(db, collections.fields, field.key), data);

            // Update the title in the formFields state
            const updatedFormFields = formFields.map(item =>
                item.key === field.key ? { ...item, title: fieldTitle } : item
            );
            
            setFormFields(updatedFormFields);

            activity.log(selectedApp.key, 'writes', 1);

            setRenameOpen(false);
        } catch (error) {
            console.error('Error renaming field:', error);
        }
    };

    return (

        <Modal title="Rename" isOpen={renameOpen} onClose={() => setRenameOpen(false)}>
            <input type="text"
                className="modal-input"
                value={fieldTitle}
                onChange={(e) => setFieldTitle(e.target.value)}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}
            />
            <div className="modal-buttons">
                <button onClick={rename} className="modal-button"
                    style={{ color: theme.foregroundColor }}>
                    Save
                </button>
            </div>
        </Modal>

    );
};

export default FieldRename;
