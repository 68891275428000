import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../firebaseConfig';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './FieldMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DeleteIcon from '../../../svg/DeleteIcon';
import DotsIcon from '../../../svg/DotsIcon';
import DownIcon from '../../../svg/DownIcon';
import RenameIcon from '../../../svg/RenameIcon';
import UpIcon from '../../../svg/UpIcon';

// Components
import ContextMenuButton from '../../../foundation/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../foundation/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../foundation/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../foundation/contextmenu/ContextMenuPopup';
import FieldRemove from './FieldRemove';
import FieldRename from './FieldRename';

const FieldMenu = ({ field, additionalOptions = [] }) => {
    const { theme } = useTheme();
    const { formFields, hideProgress, selectedApp, selectedModel, showProgress } = useContext(Global);

    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [fieldTitle, setFieldTitle] = useState('');

    const toggleMenu = (event) => {
        event.stopPropagation();
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (!field) return;
        setFieldTitle(field.title);
    }, [field]);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setConfirmOpen(true);
    };

    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('up');
    };

    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('down');
    };

    const handleRenameClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setRenameOpen(true);
    };

    const sort = async (direction) => {
        showProgress("Moving...");
        const currentIndex = formFields.findIndex(item => item.key === field.key);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < formFields.length) {
            const targetItem = formFields[newIndex];
            const currentSort = field.sort;
            field.sort = targetItem.sort;
            targetItem.sort = currentSort;

            try {
                await updateDoc(doc(db, collections.fields, field.key), { sort: field.sort });
                await updateDoc(doc(db, collections.fields, targetItem.key), { sort: targetItem.sort });

                activity.log(selectedApp.key, 'writes', 2);
            } catch (error) {
                console.error('Error updating sort order:', error);
            }
        }

        hideProgress();
    };

    return (
        <>
            {/* BUTTON */}
            <div className="field-menu-button">
                <ContextMenuButton 
                    onToggle={toggleMenu} 
                    dotsColor={theme.foregroundColor} 
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* ADDITIONAL OPTIONS */}
                {additionalOptions.length > 0 &&
                    <ContextMenuGroup title="Options">
                        {additionalOptions.map((option, index) => (
                            <ContextMenuOption
                                key={index}
                                icon={option.icon || DotsIcon}
                                text={option.text}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    option.onClick(event);
                                    setMenuVisible(false);
                                }}
                            />
                        ))}
                    </ContextMenuGroup>
                }

                {/* BASE OPTIONS */}
                <ContextMenuGroup title="Field">
                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Rename"
                        onClick={handleRenameClick}
                    />

                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    {field && selectedModel && field.key !== selectedModel.titleFieldKey &&
                        <ContextMenuOption
                            icon={DeleteIcon}
                            text="Remove"
                            iconSize={11}
                            onClick={handleDeleteClick}
                        />
                    }
                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* RENAME MODAL */}
            <FieldRename
                field={field}
                fieldTitle={fieldTitle}
                setFieldTitle={setFieldTitle}
                renameOpen={renameOpen}
                setRenameOpen={setRenameOpen}
            />

            {/* REMOVE MODAL */}
            <FieldRemove
                field={field}
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
            />

        </>
    );
};

export default FieldMenu;
