import React, { useContext, useState } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { auth } from '../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Styles
import './Prompt.css';

// Images
import WandIcon from '../svg/WandIcon';

// Managers
import AppManager from '../managers/AppManager';
import AppUserManager from '../managers/AppUserManager';
import ModelManager from '../managers/ModelManager';
import PromptManager from '../managers/PromptManager';
import RoleManager from '../managers/RoleManager';
import UserIndexManager from '../managers/UserIndexManager';

const appManager = new AppManager();
const appUserManager = new AppUserManager();
const promptManager = new PromptManager();
const roleManager = new RoleManager();
const userIndexManager = new UserIndexManager();

let reportFields = [];

const Prompt = () => {
  const {
    currentUser,
    hideProgress,
    setPage,
    setFormMode,
    setSelectedApp,
    setSelectedModel,
    showProgress,
    studio
  } = useContext(Global);

  const [prompt, setPrompt] = useState('');

  function adjustTextareaHeight(textarea) {
    if (textarea.value.trim().length === 0) {
      return;
    }

    const maxHeight = 400; // Maximum height in pixels
    let newHeight = textarea.scrollHeight - 8;

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      textarea.style.overflowY = 'scroll'; // Add scrollbar if content exceeds maxHeight
    } else {
      textarea.style.overflowY = 'hidden'; // Hide scrollbar if content is within maxHeight
    }

    textarea.style.height = `${newHeight}px`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (prompt.trim().length === 0) return;

    showProgress("Creating app...");

    await submitAppPrompt(prompt);

    hideProgress();
  };

  const submitAppPrompt = async (prompt) => {
    try {
      const combinedPrompt = await promptManager.prepareAppPrompt(prompt);

      console.log(combinedPrompt);

      const response = await promptManager.send(combinedPrompt);

      await processAppResponse(response);

      setPrompt('');
    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

  const processAppResponse = async (r) => {
    console.log(r);

    reportFields = []; // Clear the report aggregate fields array

    try {

      // Parse the JSON response
      const jsonString = promptManager.cleanJSON(r);
      const jsonData = JSON.parse(jsonString);

      const appKey = generateKey();
      const user = auth.currentUser;
      const userKey = user.uid;

      // Current timestamp
      const now = Timestamp.now();

      const appData = {
        key: appKey,
        themes: {
          dark: {
            backgroundColor: jsonData.backgroundColorDark,
            backgroundColorFaded: jsonData.backgroundColorDarkFaded,
            foregroundColor: jsonData.foregroundColorDark,
            foregroundColorFaded: jsonData.foregroundColorDarkFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorDark,
            highlightForegroundColor: jsonData.highlightForegroundColorDark,
          },
          light: {
            backgroundColor: jsonData.backgroundColorLight,
            backgroundColorFaded: jsonData.backgroundColorLightFaded,
            foregroundColor: jsonData.foregroundColorLight,
            foregroundColorFaded: jsonData.foregroundColorLightFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorLight,
            highlightForegroundColor: jsonData.highlightForegroundColorLight,
          }
        },
        businessModel: "FREE",
        description: jsonData.appDescription,
        originalKey: appKey,
        prompt: prompt,
        published: false,
        title: jsonData.appTitle,
        version: 1,
        userKey: userKey,
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        dateCreated: now,
        dateModified: now
      };

      const app = await appManager.addAppData(appKey, appData);

      // Add the app creator as the first app user
      const appUserKey = generateKey();

      const appUserData = {
          key: appUserKey,
          appKey: appKey,
          userKey: userKey,
          userFirstName: currentUser.firstName,
          userLastName: currentUser.lastName,
          userEmail: currentUser.email,
          roleKey: "ADMIN",
          dateJoined: now
      };

      await appUserManager.add(appKey, appUserKey, appUserData);

      // Add the app creator to the user index
      await userIndexManager.add(appKey, userKey, currentUser.firstName + currentUser.lastName);

      setSelectedApp(app);

      if (isMobile) {
          setPage('RUN');
      } else {
          setPage('STUDIO');
      }

      showProgress("Generating menu...");

      const modelManager = new ModelManager();

      // Initialize a map to store model titles and their keys
      const modelTitleToKey = {};

      // Initialize an array to store modelData objects
      const modelsArray = [];

      let sortIndex = 0;

      // Check if models exist and are in array format
      if (jsonData.models && Array.isArray(jsonData.models)) {
        for (const model of jsonData.models) {
          const title = model.title;
          const description = model.description;
          const modelKey = generateKey();

          // Store the model key with its title for later lookup
          modelTitleToKey[title] = modelKey;

          // Current timestamp
          const now = Timestamp.now();

          const modelData = {
            key: modelKey,
            appKey: appKey,
            userKey: userKey,
            title: title,
            description: description,
            summaryHeight: '36px',
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: sortIndex,
            dateCreated: now,
            dateModified: now,
          };

          // Add the model to the app
          await modelManager.add(appKey, modelKey, modelData);

          // Push the modelData object to the modelsArray
          modelsArray.push(modelData);

          sortIndex++;
        }
      } else {
        console.log("No models found or invalid model format.");
      }

      // Models
      for (var i = 0; i < modelsArray.length; i++) {
        await submitModelPrompt(app, modelsArray[i], modelsArray);
      }

      // Roles
      await submitRolesPrompt(app);

    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const submitModelPrompt = async (app, model, models) => {
    try {

      const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models);

      console.log(prompt);

      const response = await promptManager.send(prompt);

      await promptManager.processModelResponse(
        response, 
        app, 
        model, 
        showProgress,
        setSelectedModel,
        setFormMode,
        null, // no need to reset visibility
        reportFields
      );

    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

  const submitRolesPrompt = async (app) => {
    try {

      const prompt = await promptManager.prepareRolesPrompt(app.title, app.description);

      console.log(prompt);

      const response = await promptManager.send(prompt);

      await processRolesResponse(response, app);

    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

  const processRolesResponse = async (r, app) => {
    try {

      showProgress("Generating roles...");

      console.log(r);

      const jsonString = promptManager.cleanJSON(r);
      const jsonData = JSON.parse(jsonString);

      console.log(jsonData.roles);

      if (jsonData.roles && Array.isArray(jsonData.roles)) {
        jsonData.roles.forEach((role) => {
          const roleKey = generateKey();
          const userKey = currentUser.key;
          const userFirstName = currentUser.firstName;
          const userLastName = currentUser.lastName;
          roleManager.addRole(app.key, roleKey, userKey, userFirstName, userLastName, role.title);
        });
      } else {
        console.log("No roles found or invalid format.");
      }

    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  return (
    <div className="prompt-container"
      style={{
        backgroundColor: studio.backgroundColorFaded,
        borderColor: studio.backgroundColorFaded
      }}
    >
      <textarea
        className="prompt"
        style={{
          backgroundColor: studio.backgroundColorFaded,
          borderColor: studio.backgroundColorFaded,
          color: studio.foregroundColor
        }}
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
          adjustTextareaHeight(e.target);
        }}
        placeholder="What kind of app do you need?"
        rows="1"
      />
      <div onClick={handleSubmit} className="prompt-image">
        <WandIcon
          color={studio.foregroundColor}
          width="30"
          height="30"
        />
      </div>
    </div>
  );
};

export default Prompt;

