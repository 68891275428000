import React from 'react';

// Styles
import './MenuHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppLogo from '../../run/logo/AppLogo';

const MenuHeader = () => {
    const { theme } = useTheme();

    return (

        <div className="menu-header-container"
            style={{ backgroundColor: theme.backgroundColor }}>
            <AppLogo 
                size={100}
                showTitle={false}
            />
        </div>
    );
};

export default MenuHeader;
