import React from 'react';

// Styles
import './Divider.css';

// Theme
import { useTheme } from '../ThemeContext';

const Divider = () => {
  const { theme } = useTheme();

  return (

    <div className="divider-container"
      style={{
        borderBottomColor: theme.backgroundColorFaded
      }}
    ></div>
  );
};

export default Divider;

