import React, { useState } from 'react';

// Styles
import './ContextMenuOption.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`

  .context-menu-option-container:hover {
    background-color: ${(props) => props.hoverColor};
  }
    
`;

const ContextMenuOption = ({ icon: IconComponent, text, onClick, iconSize = 13 }) => {
  const { theme } = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <InlineStyles
        hoverColor={theme.highlightBackgroundColor}
        hoverTextColor={theme.highlightForegroundColor}
      />
      <div
        onClick={onClick}
        className="context-menu-option-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          color: isHovered ? theme.highlightForegroundColor : theme.foregroundColor,
        }}>
        <div className="context-menu-option-icon">
          <IconComponent
            color={isHovered ? theme.highlightForegroundColor : theme.foregroundColor}
            width={iconSize}
            height={iconSize}
          />
        </div>
        {text}
      </div>
    </>
  );
};

export default ContextMenuOption;
