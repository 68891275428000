import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../firebaseConfig';

// Styles
import './ChannelItemEdit.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import { activity } from '../../../managers/ActivityManager';

// Components
import Modal from '../../../foundation/Modal';

/**
 * ChannelItemEdit Component
 * 
 * This component allows editing a channel.
 * 
 * @param {object} channel - The channel object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelItemEdit = ({ channel, isOpen, setOpen }) => {
    const { theme } = useTheme();
    const {
        selectedApp
    } = useContext(Global);

    // State Variables
    const [channelTitle, setChannelTitle] = useState('');

    /** 
     * Initializes the channel title when the component mounts.
     */
    useEffect(() => {
        if (!channel) return;
        setChannelTitle(channel.title);
    }, [channel]);

    /** 
     * Renames the channel.
     */
    const renameChannel = async () => {

        // Data to change
        const data = {
            title: channelTitle
        };

        try {
            // Update the channel in the database
            await updateDoc(doc(db, collections.channels, channel.key), data);

            // Log the activity
            activity.log(selectedApp.key, 'writes', 1);

            // Close the modal
            setOpen(false);
        } catch (error) {
            console.error('Error renaming channel:', error);
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Edit Channel" 
                isOpen={isOpen} 
                onClose={() => setOpen(false)}
                width="250px">

                {/* LABEL */}
                <div className="channel-item-edit-label"
                    style={{ color: theme.foregroundColor }}>
                    Title
                </div>

                {/* INPUT */}
                <input type="text"
                    className="channel-item-edit-input"
                    value={channelTitle}
                    onChange={(e) => setChannelTitle(e.target.value)}
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily
                    }}
                />

                {/* BUTTONS */}
                <div className="channel-item-edit-buttons">
                    <div onClick={renameChannel} className="channel-item-edit-button"
                        style={{ color: theme.foregroundColor }}>
                        Save
                    </div>
                </div>
                
            </Modal>
        </>
    );
};

export default ChannelItemEdit;
