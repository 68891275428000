import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Account.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AccountAbout from '../../account/about/AccountAbout';
import AccountBilling from './billing/AccountBilling';
import AccountMenu from './menu/AccountMenu';
import Modal from '../../foundation/Modal';

const Account = () => {
    const { theme } = useTheme();
    const {
        accountAboutVisible,
        setUserAccountOpen,
        userAccountOpen
    } = useContext(Global);


    return (
        <Modal
            title=""
            height="90%"
            isOpen={userAccountOpen}
            onClose={() => setUserAccountOpen(false)}
            width="900px">
            <div className="account-container">
                <AccountMenu />
                {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
            </div>
        </Modal>
    );
};

export default Account;
