import React from 'react';

// Styles
import './AccountMenuSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`
    .account-menu-summary-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const AccountMenuSummary = ({ title, description }) => {
    const { theme } = useTheme();

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />

            <div className="account-menu-summary-container">
                <div className="account-menu-summary-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {title}
                </div>
                <div className="account-menu-summary-description"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    {description}
                </div>
            </div>
            <div className="account-menu-summary-divider"
                style={{
                    borderBottomColor: theme.backgroundColorFaded
                }}
            ></div>
        </>
    );
};

export default AccountMenuSummary;
