import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './AppSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppSettingsBusinessModel from './AppSettingsBusinessModel';
import AppSettingsDescription from './AppSettingsDescription';
import AppSettingsFont from './AppSettingsFont';
import AppSettingsIcon from './AppSettingsIcon';
import AppSettingsTheme from './AppSettingsTheme';
import AppSettingsTitle from './AppSettingsTitle';
import UserFields from './userfields/UserFields';
import Modal from '../Modal';

// Managers
import AppManager from '../../managers/AppManager';

// Themes
const defaultThemes = {
    dark: {
        backgroundColor: '#000000',
        backgroundColorFaded: '#222222',
        foregroundColor: '#ffffff',
        foregroundColorFaded: '#aaaaaa',
        highlightColor: '#ff4500'
    },
    light: {
        backgroundColor: '#ffffff',
        backgroundColorFaded: '#f0f0f0',
        foregroundColor: '#000000',
        foregroundColorFaded: '#555555',
        highlightColor: '#ff0000'
    }
};

const AppSettings = ({ appKey, isAppSettingsOpen, setAppSettingsOpen }) => {
    const { theme } = useTheme();
    const {
        hideProgress,
        selectedApp,
        setPage,
        setSelectedApp,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    // Menu
    const [selectedOption, setSelectedOption] = useState('GENERAL');

    // Title
    const [title, setTitle] = useState('');

    // Description
    const [description, setDescription] = useState('');

    // Business Model
    const [businessModel, setBusinessModel] = useState('FREE');
    const [subscriptionPrice, setSubscriptionPrice] = useState(0.00);
    const [seatPrice, setSeatPrice] = useState(0.00);

    const [themes, setThemes] = useState(defaultThemes);
    const [fontFamily, setFontFamily] = useState('');

    // Define state for required fields and displayKey
    const [requiredFields, setRequiredFields] = useState({
        fullname: false,
        displayname: false,
        handle: false,
        birthdate: false,
    });
    const [displayKey, setDisplayKey] = useState('username'); // Default to 'username'

    useEffect(() => {
        if (selectedApp) {
            setTitle(selectedApp.title || '');
            setDescription(selectedApp.description || '');
            setBusinessModel(selectedApp.businessModel || 'FREE');
            setSubscriptionPrice(selectedApp.subscriptionPrice || 0.00);
            setSeatPrice(selectedApp.seatPrice || 0.00);
            setThemes(selectedApp.themes || defaultThemes);
            setFontFamily(selectedApp.fontFamily || 'Red Hat Display');
            setRequiredFields(selectedApp.requiredFields || {
                fullname: false,
                displayname: false,
                handle: false,
                birthdate: false,
            });
            setDisplayKey(selectedApp.displayKey || 'username');
        }
    }, [selectedApp]);

    const handleUpdateThemes = (updatedThemes) => {
        setThemes(updatedThemes); // Update the local state
    };

    const handleFontChange = (updatedFontFamily) => {
        setFontFamily(updatedFontFamily); // Update the local state
    };

    const handleSave = async () => {
        // Close the modal
        setAppSettingsOpen(false);

        if (!title.trim()) {
            alert('Please enter an app title.');
            return;
        }

        // Convert prices to numbers
        const formattedSubscriptionPrice = parseFloat(subscriptionPrice);
        const formattedSeatPrice = parseFloat(seatPrice);

        // Check if the conversion was successful
        if (isNaN(formattedSubscriptionPrice) || isNaN(formattedSeatPrice)) {
            alert('Please enter valid numeric values for prices.');
            return;
        }

        // Current timestamp
        const now = Timestamp.now();

        const data = {
            title: title,
            description: description,
            businessModel: businessModel || 'FREE',
            subscriptionPrice: formattedSubscriptionPrice || 0.00,
            seatPrice: formattedSeatPrice || 0.00,
            themes: themes,
            fontFamily: fontFamily,
            requiredFields: requiredFields,
            displayKey: displayKey,
            dateModified: now,
        };

        // Call updateApp to update the database
        const appManager = new AppManager();
        const result = await appManager.updateApp(appKey, data);

        if (result.success) {
            // Reset the model and close the modal
            setTitle('');
            setDescription('');
            setSubscriptionPrice(0.00);
            setSeatPrice(0.00);
            setStatusMessage(result.message);

            // Update state
            setSelectedApp((prevApp) => ({
                ...prevApp,
                title: title,
                description: description,
                businessModel: businessModel,
                subscriptionPrice: formattedSubscriptionPrice,
                seatPrice: formattedSeatPrice,
                themes: themes,
                fontFamily: fontFamily,
                requiredFields: requiredFields,
                displayKey: displayKey,
            }));
        } else {
            // Display the error message
            setStatusMessage(result.message);
        }
    };

    const handleDelete = async () => {
        showProgress('Deleting app...');

        if (!selectedApp) {
            alert('No app selected!');
            hideProgress();
            return;
        }

        const message = selectedApp.title + " and all of its data will be permanently deleted. Are you sure you would like to continue?";

        if (!window.confirm(message)) {
            hideProgress();
            return;
        }

        try {
            setAppSettingsOpen(false);

            const appKey = selectedApp.key;
            const appManager = new AppManager();
            await appManager.deleteApp(appKey);

            setSelectedApp(null);
            setPage('HOME');
            setStatusMessage('App deleted successfully.');
        } catch (error) {
            console.error('Error deleting object and related data: ', error);
            setStatusMessage('Error deleting object and related data');
        }

        hideProgress();
    };

    const menu = [
        { key: 'GENERAL', title: 'General' },
        { key: 'USERFIELDS', title: 'User Fields' },
        { key: 'ICON', title: 'Icon/Logo' },
        { key: 'THEME', title: 'Colors' },
        { key: 'FONT', title: 'Font Family' },
    ];

    const handleOptionClick = (key) => {
        setSelectedOption(key);
    };

    return (
        <Modal
            title="App Settings"
            isOpen={isAppSettingsOpen}
            onClose={() => setAppSettingsOpen(false)}
            width="900px"
        >
            <div className="app-settings-container">
                <div className="app-settings-wrapper">
                    <div className="app-settings-menu">
                        {menu.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option.key)}
                                className="app-settings-menu-option"
                                style={{
                                    backgroundColor: option.key === selectedOption
                                        ? theme.highlightBackgroundColor
                                        : theme.backgroundColor
                                }}
                            >
                                <div
                                    className="app-settings-menu-option-title"
                                    style={{
                                        color: selectedOption && selectedOption === option.key
                                            ? theme.highlightForegroundColor
                                            : theme.foregroundColor
                                    }}
                                >
                                    {option.title}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="app-settings-divider"
                        style={{
                            backgroundColor: theme.backgroundColorFaded
                        }}></div>
                    <div className="app-settings-content">
                        {selectedOption === 'GENERAL' && (
                            <>
                                <AppSettingsTitle
                                    title={title}
                                    setTitle={setTitle}
                                />
                                <AppSettingsDescription
                                    description={description}
                                    setDescription={setDescription}
                                />
                            </>
                        )}
                        {selectedOption === 'BUSINESS' && (
                            <AppSettingsBusinessModel
                                selectedApp={selectedApp}
                                businessModel={businessModel}
                                setBusinessModel={setBusinessModel}
                                subscriptionPrice={subscriptionPrice}
                                setSubscriptionPrice={setSubscriptionPrice}
                                seatPrice={seatPrice}
                                setSeatPrice={setSeatPrice}
                            />
                        )}
                        {selectedOption === 'USERFIELDS' && (
                            <UserFields 
                                requiredFields={requiredFields} 
                                setRequiredFields={setRequiredFields} 
                                displayKey={displayKey} 
                                setDisplayKey={setDisplayKey} 
                            />
                        )}
                        {selectedOption === 'ICON' && (
                            <AppSettingsIcon themes={themes} />
                        )}
                        {selectedOption === 'THEME' && (
                            <AppSettingsTheme themes={themes} onUpdate={handleUpdateThemes} />
                        )}
                        {selectedOption === 'FONT' && (
                            <AppSettingsFont fontFamily={fontFamily} onChange={handleFontChange} />
                        )}
                    </div>
                </div>
                <div className="app-settings-modal-buttons">
                    <button
                        type="button"
                        onClick={handleSave}
                        className="app-settings-modal-button"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily || 'Open Sans'
                        }}>
                        Save Changes
                    </button>
                </div>
                <div className="app-settings-modal-buttons">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="app-settings-modal-button-delete"
                        style={{
                            color: theme.foregroundColorFaded,
                            fontFamily: selectedApp.fontFamily || 'Open Sans'
                        }}>
                        Delete App
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AppSettings;
