import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './UserList.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import AppUserSummary from '../../../appusers/AppUserSummary';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

// Images
import CheckOffIcon from '../../../../svg/CheckOffIcon';
import CheckOnIcon from '../../../../svg/CheckOnIcon';
import ForwardIcon from '../../../../svg/ForwardIcon';

// Managers
import AppUserManager from '../../../../managers/AppUserManager';

const appUserManager = new AppUserManager();

/**
 * UserList Component
 * 
 * This component renders list of users related to the record.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {boolean} readOnly - Indicates whether the input should be read-only.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserList = ({
    object,
    field,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme } = useTheme();
    const {
        formMode,
        selectedApp,
        selectedModel,
        setAppUsersVisible,
        setSelectedAppUser,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // State Variables
    const [appUsers, setAppUsers] = useState([]);
    const [relatedAppUsers, setRelatedAppUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Clear the array if we are moving to ADD mode
    useEffect(() => {
        if (formMode === "ADD") {
            setRelatedAppUsers([]);
        }
    }, [formMode]);

    // Fetch app users and relationships on component mount or when object changes
    useEffect(() => {

        // Fetch app users
        async function fetchAppUsers() {
            try {
                const appUsers = await appUserManager.fetchAppUsers(selectedApp.key);
                setAppUsers(appUsers);
            } catch (error) {
                console.error('Failed to fetch related app users:', error);
            }
        }

        // Fetch user relationships
        async function fetchRelationships() {
            try {
                const relatedAppUsers = await appUserManager.listRelatedAppUsers(selectedApp.key, object.key, field.key);
                setRelatedAppUsers(relatedAppUsers);
            } catch (error) {
                console.error('Failed to fetch related objects:', error);
            }
        }

        fetchAppUsers();

        if (object) {
            fetchRelationships();
        }

    }, [object, selectedApp.key, field.key]);

    // Handle item selection check/uncheck
    const handleCheck = async (appUser) => {
        const isRelated = relatedAppUsers.some(obj => obj.key === appUser.key);

        if (isRelated) {
            // If the checkbox is being unchecked, delete the relationship
            await appUserManager.deleteRelationship(
                selectedApp.key,
                object.key,
                appUser.key,
                field.key
            );
            setRelatedAppUsers(relatedAppUsers.filter(obj => obj.key !== appUser.key));
        } else {
            // If the checkbox is being checked, create the relationship
            await appUserManager.createRelationship(
                selectedApp.key,
                object.key,
                appUser.key,
                field.key
            );
            setRelatedAppUsers([...relatedAppUsers, appUser]);
        }
    };

    // Handle item forward
    const handleForward = async (appUser) => {
        setAppUsersVisible(true);
        setSelectedAppUser(appUser);
        setSelectedObject(null);
        setSelectedModel(null);
    };

    const handleSelectClick = () => {
        setIsModalOpen(true);
    };

    const handleOverlayClick = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                readOnly={readOnly}
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />
                <div className="user-list-items">
                    {relatedAppUsers.map((appUser, index) => {
                        const isChecked = relatedAppUsers.some(obj => obj.key === appUser.key);
                        return isChecked ? (
                            <div key={appUser.key || index} className="user-list-item">
                                <div className="user-list-item-summary"
                                    onClick={() => handleForward(appUser)}>
                                    <AppUserSummary
                                        appUser={appUser}
                                    />
                                </div>
                                {selectedModel &&
                                    <div className="user-list-item-forward"
                                        onClick={() => handleForward(appUser)}>
                                        <ForwardIcon
                                            color={theme.foregroundColor}
                                            width="16"
                                            height="16"
                                        />
                                    </div>
                                }
                            </div>
                        ) : null;
                    })}
                </div>

                {!readOnly &&
                    <>
                        <div
                            className="user-list-summary-button"
                            style={{ color: theme.foregroundColorFaded }}
                            onClick={handleSelectClick}>
                            Select
                        </div>

                        {isModalOpen && (
                            <div className="user-list-modal-overlay" onClick={handleOverlayClick}>
                                <div className="user-list-modal-panel"
                                    style={{ backgroundColor: theme.backgroundColorFaded }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {appUsers.length > 0 && (
                                        <>
                                            {appUsers.map((appUser, index) => {
                                                const isChecked = relatedAppUsers.some(obj => obj.key === appUser.key);
                                                return (

                                                    <div key={appUser.key || index} className="user-list-modal-summary-row">
                                                        <div className="user-list-modal-summary-checkbox" onClick={() => handleCheck(appUser)}>
                                                            {isChecked ? (
                                                                <CheckOnIcon
                                                                    color={theme.highlightBackgroundColor}
                                                                    checkColor={theme.foregroundColor}
                                                                    width="30"
                                                                    height="30"
                                                                />
                                                            ) : (
                                                                <CheckOffIcon
                                                                    color={theme.highlightBackgroundColor}
                                                                    width="30"
                                                                    height="30"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="user-list-modal-summary-wrapper"
                                                            onClick={() => handleCheck(appUser)}>
                                                            <AppUserSummary
                                                                appUser={appUser}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                }

            </FieldContainer>
        </>
    );
};

export default UserList;
