// Firebase
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class ReportManager {

    /**
     * Method to add a new report.
     * 
     * @param {string} appKey - App key.
     * @param {string} key - The new key of the report.
     * @param {string} title - Title for the report.
     * @returns {report} - New report.
    */
    async addReport(appKey, key, title, definition) {

        // Current timestamp
        const now = Timestamp.now();

        const report = {
            key: key,
            appKey: appKey,
            title: title,
            definition: definition,
            pin: true,
            dateCreated: now,
            dateModified: now,
        };

        await setDoc(doc(db, collections.reports, key), report);

        activity.log(appKey, 'writes', 1);

        return report;
    }

    /**
      * Fetches reports for an app.
      * 
      * @param {string} appKey - App key to get reports for.
      */
    fetchReports(appKey) {
        try {
            const reportsCollection = collection(db, collections.reports);
            const q = query(reportsCollection, where("appKey", "==", appKey), orderBy("title"));

            return getDocs(q).then(snapshot => {

                activity.log(appKey, 'reads', snapshot.docs.length);

                return snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            }).catch(error => {
                console.error("Error fetching reports:", error);
                throw error;
            });
        } catch (error) {
            console.error("Error setting up report fetch:", error);
            throw error;
        }
    }

    /**
      * Fetches reports and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchReportsAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the reports collection
            const reportsCollection = collection(db, collections.reports);

            // Create a query to find reports by appKey and sort them by title
            const q = query(reportsCollection, where("appKey", "==", appKey), orderBy("title"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const reportList = snapshot.docs.map(doc => ({
                    id: doc.id, // Include the document ID if needed
                    ...doc.data() // Spread operator to include all fields from the document
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(reportList);
                    activity.log(appKey, 'reads', reportList.length);
                }
            }, error => {
                console.error("Error fetching reports:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
      * Fetches a report by its report key.
      * 
      * @param {string} appKey - App key.
      * @param {string} reportKey - Key of the report to retrieve.
      */
    async fetchReport(appKey, reportKey) {
        try {
            // Create a reference to a specific report document using reportKey
            const reportRef = doc(db, collections.reports, reportKey);

            // Get the document
            const docSnap = await getDoc(reportRef);

            activity.log(appKey, 'reads', 1);

            if (docSnap.exists()) {
                // If the document exists, construct and return the report object
                return {
                    id: docSnap.id, // Include the document ID
                    ...docSnap.data() // Include all fields from the document
                };
            } else {
                // If no document exists for the provided key, handle accordingly
                console.log("No report found with the given reportKey.");
                return null; // Or you could throw an error, depending on your use case
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            throw error; // Rethrow the error to handle it appropriately
        }
    }

    /**
      * Updates an report's data in the Firestore database.
      * 
      * @param {string} appKey - App key.
      * @param {string} reportKey - The key (document ID) of the report to update.
      * @param {string} title - Title of the report.
      * @param {string} pin - Indicates whether the report should be pinned.
      * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async updateReport(appKey, reportKey, title, pin) {
        try {

            // Current timestamp
            const now = Timestamp.now();

            const data = {
                title: title,
                pin: pin,
                dateModified: now
            };

            // Update the document in Firestore
            await updateDoc(doc(db, collections.reports, reportKey), data);

            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Report updated successfully." };
        } catch (error) {
            console.error('Error updating report:', error);
            // Return an error message
            return { success: false, message: "Error updating report." };
        }
    }

    /**
     * Updates a report's data in the Firestore database.
     *
     * @param {string} appKey - App key.
     * @param {string} reportKey - The key (document ID) of the report to update.
     * @param {object} data - An object containing the report properties and their new values.
     */
    async updateReportData(appKey, reportKey, data) {
        try {
            const now = Timestamp.now();

            // Ensure dateModified is always updated to the current timestamp
            data.dateModified = now;

            // Update the document in Firestore
            await updateDoc(doc(db, collections.reports, reportKey), data);

            // Log the activity - assuming an activity logging function exists
            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Report updated successfully." };
        } catch (error) {
            console.error('Error updating report:', error);
            // Return an error message
            return { success: false, message: "Error updating report." };
        }
    }

    /**
     * Deletes a report from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} reportKey - The key of the report to delete.
     * @returns {Promise<{success: checkbox, message?: string}>} - Result of the deletion operation.
     */
    async deleteReport(appKey, reportKey) {
        try {
            // Reference to the report document
            const reportRef = doc(db, collections.reports, reportKey);

            // Fetch the report document snapshot
            const reportSnapshot = await getDoc(reportRef);
            activity.log(appKey, 'reads', 1);

            // Check if the report exists
            if (!reportSnapshot.exists()) {
                const message = "Report does not exist";
                console.error(message);
                return { success: false, message };
            }

            // Delete the report document
            await deleteDoc(reportRef);

            activity.log(appKey, 'deletes', 1);

            // Return success response
            return { success: true };
        } catch (error) {
            console.error('Error deleting report:', error);
            // Return an error message
            return { success: false, message: "Error deleting report." };
        }
    }

}

export default ReportManager;
