import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from './account/billing/AccountBilling';
import AccountMenu from './account/menu/AccountMenu';
import AppUsers from '../run/appusers/AppUsers';
import AppUserProfile from '../run/appusers/AppUserProfile';
import Billing from '../billing/Billing';
import Calendar from './calendar/Calendar';
import FieldSelector from '../run/form/fieldselector/FieldSelector';
import Form from './form/Form';
import Menu from './menu/Menu';
import Messages from './messages/Messages';
import Objects from './objects/Objects';
import Reports from './reports/Reports';
import RunHome from './runhome/RunHome';
import TitleBar from '../foundation/TitleBar';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`
    
    body {
      margin: 0;
      font-family: ${(props) => props.fontFamily};
      display: flex;
      flex-direction: column;
    }

    ::selection {
        background: ${(props) => props.selectionBackground};
        color: ${(props) => props.selectionForeground};
    }

    input::selection, textarea::selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

    input::-moz-selection, textarea::-moz-selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

`;

const Desktop = () => {
  const { theme } = useTheme();
  const {
    accountVisible,
    accountAboutVisible,
    appUsersVisible,
    billingVisible,
    calendarVisible,
    fieldSelectorVisible,
    messagesVisible,
    objectsView,
    reportsVisible,
    runHomeVisible,
    selectedApp,
    setFormMode
  } = useContext(Global);

  // In desktop mode, show the add form on the right side of the screen
  useEffect(() => {
    if (!isMobile) {
      setFormMode("ADD");
    }
  }, [setFormMode]);

  const renderContent = () => {
    if (billingVisible) return <Billing />;
    if (runHomeVisible) return <RunHome />;
    if (reportsVisible) return <Reports />;
    if (calendarVisible) return <Calendar />;
    if (messagesVisible) return <Messages />;
    if (appUsersVisible) return (
      <>
        <AppUsers />
        <AppUserProfile />
      </>
    );
    if (accountVisible) return (
      <>
        <AccountMenu />
        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
      </>
    );
    return (
      <>
        <Objects />
        {objectsView !== "TABLE" &&
          <>
            {fieldSelectorVisible ? (
              <div className="desktop-field-selector">
                <div className="desktop-field-selector-header"
                  style={{ color: theme.foregroundColorFaded }}>
                  Add Field
                </div>
                <FieldSelector
                  size="LARGE"
                />
              </div>
            ) : (
              <Form />
            )}
          </>
        }
      </>
    );
  };

  /**
   * Function to load only the regular variant of a font.
   * 
   * @param {string} fontName - Name of the font.
   */
  const loadGoogleFont = (fontName) => {
    const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadGoogleFont(selectedApp.fontFamily || 'Red Hat Display');
  }, [selectedApp]);

  return (
    <>
      <InlineStyles
        fontFamily={selectedApp && selectedApp.fontFamily || 'Red Hat Display'}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{ backgroundColor: theme.backgroundColor }}>
        {selectedApp && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}
        <div className="desktop-center" style={{ backgroundColor: theme.backgroundColor }}>
          <Menu />
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default Desktop;
