// Utilities
import { generateKey } from '../../utilities/Keys';

class DragHelper {

    /**
     * Method to handle drag start as the user begins dragging an element.
     * 
     * @param {event} e - Drag event.
     * @param {object} element - Element that is being dragged
     * @param {string} action - Action: "new" or "moving"
     */
    handleDragStart(e, elem, action) {
        // Can be either "new" or "moving"
        e.dataTransfer.setData("action", action);

        // Serialize the element to a string
        e.dataTransfer.setData("element", JSON.stringify(elem));
    };

    /**
      * Method to handle a drop action on the canvas. This will either add a new
      * element to the canvas or move an existing one.
      * 
      * @param {event} e - Drop event.
     * @param {array} elements - Array of existing elements on the canvas.
      * @param {function} setElements - Function to set the elements on the canvas.
      * @param {function} setSelectedElement - Function to set the selectedElement.
      * @param {object} fieldDefaults - Set of default field styles if adding a field.
      */
    handleDrop(e, elements, setElements, setSelectedElement, fieldDefaults = {}) {
        e.preventDefault();

        // Intent of the drop - "new" or "moving"
        const action = e.dataTransfer.getData("action");

        // Element being dropped on the canvas
        const element = JSON.parse(e.dataTransfer.getData("element"));

        if (action === "new") {
            this.handleNewDrop(e,  element, elements,setElements, setSelectedElement, fieldDefaults);
        } else {
            this.handleMoveDrop(e, element, setElements);
        }
    };

    /**
     * Method to handle a drop of an existing element on the canvas after moving.
     * 
     * @param {event} e - Drop event.
     * @param {object} element - Element that is being dropped.
     * @param {function} setElements - Function to update the element on the canvas.
     */
    handleMoveDrop(event, element, setElements) {
        const [startX, startY] = event.dataTransfer.getData("text/plain").split(',');

        const dx = event.clientX - parseInt(startX, 10);
        const dy = event.clientY - parseInt(startY, 10);

        // Adjust the top/left of the element
        const currentLeft = parseInt(element.styles.left, 10);
        const currentTop = parseInt(element.styles.top, 10);

        const newLeft = `${currentLeft + dx}px`;
        const newTop = `${currentTop + dy}px`;

        const updatedStyles = {
            ...element.styles,
            left: newLeft,
            top: newTop
        };

        const updatedElement = { ...element, styles: updatedStyles };

        // Update the elements array by replacing the updated element
        setElements(prevElements =>
            prevElements.map(el => el.key === element.key ? updatedElement : el)
        );
    }

    /**
     * Method to handle a drop of a new element on the canvas.
     * 
     * @param {event} e - Drop event.
     * @param {object} element - Element that is being dropped.
     * @param {array} elements - Array of existing elements on the canvas.
     * @param {function} setElements - Function to update the elements on the canvas.
     * @param {function} setSelectedElement - Function to set the selected element.
     * @param {object} fieldDefaults - Contains default information for field types.
     */
    handleNewDrop(e, element, elements, setElements, setSelectedElement, fieldDefaults = {}) {

        // Make sure the element is valid
        if (element) {

            // If the element is a button, ensure only one button with the same buttonType exists
            if (element.type === 'button' && element.buttonType) {
                const existingButton = elements.find(el => el.type === 'button' && el.buttonType === element.buttonType);

                // If an existing button with the same buttonType is found, do not add a new one
                if (existingButton) {
                    console.warn(`A button of type '${element.buttonType}' already exists.`);
                    return; // Prevent adding the new element
                }
            }

            // Get the drop location by calculating the offset from the top/left of the canvas
            const containerRect = e.target.getBoundingClientRect();
            const left = `${parseInt(e.clientX - containerRect.left)}px`;
            const top = `${parseInt(e.clientY - containerRect.top)}px`;

            // Set the default width and height
            let width = '40px';
            let height = '40px';

            // Initialize an empty defaults object for additional properties
            let additionalDefaults = {};

            // If the element is a field, override the default width and height using 
            // fieldDefaults from the theme context.
            if (element.type === 'field') {
                const defaults = this.getFieldDefaults(element.field, fieldDefaults);
                if (defaults) {
                    ({ width, height, ...additionalDefaults } = defaults);
                }
            }

            // If the element is static, override the default width and height.
            if (element.type === 'static') {
                // Created
                if (element.elementType === 'created') {
                    width = "120px";
                    height = "20px";
                }
                // User Name
                if (element.elementType === 'username') {
                    width = "120px";
                    height = "20px";
                }
            }

            // If the element is a button, override the default width and height.
            if (element.type === 'button') {
                width = "20px";
                height = "20px";
            }

            // Set up the element styles, merging all defaults (left, top, width, height) 
            // with any additional field-specific properties from defaults
            const styles = {
                ...element.styles || {}, // existing styles
                left: left,
                top: top,
                width: width,
                height: height,
                ...additionalDefaults // any additional default properties from fieldDefaults
            };

            // Generate a unique key for the element
            const key = generateKey();

            // Add the styles and a new key to the element
            const updatedElement = {
                ...element,
                key: key,
                styles: styles
            };

            // Once a key is added, select the element
            setSelectedElement(updatedElement);

            // Add the element to the canvas
            setElements(prev => [...prev, updatedElement]);
        }
    }

    /**
     * Method to gather default width, height and styles for a field element.
     * 
     * @param {object} field - Field to get the defaults for.
     * 
     * @returns {set} - fieldDefaults if found, otherwise null.
     */
    getFieldDefaults(field, fieldDefaults) {
        for (const key in fieldDefaults) {
            if (field.type.startsWith(key)) {
                return fieldDefaults[key];
            }
        }
        return null;
    }

}

export default DragHelper;
