import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './Modal.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import CloseIcon from '../svg/CloseIcon';

const Modal = ({ title, isOpen, onClose, children, width, height }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  // State Variables
  const [isAnimating, setIsAnimating] = useState(false);

  // Fade
  useEffect(() => {
      if (isOpen) {
          // Trigger the animation
          setIsAnimating(true);
      } else {
          setIsAnimating(false);
      }
  }, [isOpen]);

  // Create a style object that includes the width and height if provided
  const modalStyle = {
    ...selectedApp ? ({ backgroundColor: theme.backgroundColor }) : ({ backgroundColor: studio.backgroundColorFaded }),
    ...width && { width: width },
    ...height && { height: height },
    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <div className="modal-overlay" onMouseDown={onClose}>
      <div
        className="modal-content"
        style={modalStyle}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          <div className="modal-title"
            style={{
              color: selectedApp ? theme.foregroundColor : studio.foregroundColor
            }}
          >
            {title}
          </div>
          <div className="modal-close-button" onClick={onClose}>
            <CloseIcon
              color={selectedApp ?
                theme.foregroundColor :
                studio.foregroundColor
              }
              width="16"
              height="16"
            />
          </div>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
