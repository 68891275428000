import React from 'react';

// Styles
import './ButtonSummaryPreview.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import TextIcon from '../../../svg/TextIcon';
import BookmarkIcon from '../../../svg/BookmarkIcon';
import CartIcon from '../../../svg/CartIcon';
import FavoriteIcon from '../../../svg/FavoriteIcon';
import FollowIcon from '../../../svg/FollowIcon';
import CommentIcon from '../../../svg/CommentIcon';
import DislikeIcon from '../../../svg/DislikeIcon';
import DotIcon from '../../../svg/DotIcon';
import DownloadIcon from '../../../svg/DownloadIcon';
import DownvoteIcon from '../../../svg/DownvoteIcon';
import EmojiIcon from '../../../svg/EmojiIcon';
import LikeIcon from '../../../svg/LikeIcon';
import PrintIcon from '../../../svg/PrintIcon';
import PriorityIcon from '../../../svg/PriorityIcon';
import ShareIcon from '../../../svg/ShareIcon';
import UpvoteIcon from '../../../svg/UpvoteIcon';

const ButtonSummaryPreview = ({ element }) => {
    const { theme } = useTheme();

    const iconMap = {
      bookmark: BookmarkIcon,
      cart: CartIcon,
      comment: CommentIcon,
      dislike: DislikeIcon,
      dot: DotIcon,
      download: DownloadIcon,
      downvote: DownvoteIcon,
      emoji: EmojiIcon,
      favorite: FavoriteIcon,
      follow: FollowIcon,
      like: LikeIcon,
      print: PrintIcon,
      priority: PriorityIcon,
      share: ShareIcon,
      text: TextIcon,
      upvote: UpvoteIcon,
    };

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { width, height } = styles;

    // Dynamically choose the correct icon based on element.buttonType
    const IconComponent = iconMap[element.buttonType];

    // Remove "px" from width and height, default to 14 if undefined
    const iconWidth = width ? parseInt(width, 10) : 14;
    const iconHeight = height ? parseInt(height, 10) : 14;

    return (
        <div className="button-summary-preview-container">
          <IconComponent
            color={theme.foregroundColor}
            width={iconWidth}
            height={iconHeight}
          />
        </div>
    );
};

export default ButtonSummaryPreview;
