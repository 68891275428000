import { db, collections } from '../firebaseConfig';
import { writeBatch, doc, serverTimestamp, collection } from 'firebase/firestore';

class ActivityManager {
    constructor() {
        this.activities = {};
        //this.syncInterval = 600000; // Sync every 10 minutes
        this.syncInterval = 10000; // Sync every 10 seconds
        this.setupAutoSync();
    }

    log(appKey, type, count = 1) {
        if (!this.activities[appKey]) {
            this.activities[appKey] = { reads: 0, writes: 0, deletes: 0, uploads: 0, timestamp: serverTimestamp() };
        }
        this.activities[appKey][type] = (this.activities[appKey][type] || 0) + count;
    }

    async syncActivities() {
        const batch = writeBatch(db);
        for (const key in this.activities) {
            const appActivities = this.activities[key];
            if (appActivities.reads > 0 || appActivities.writes > 0 || appActivities.deletes > 0 || appActivities.uploads > 0) {
                const docRef = doc(collection(db, collections.activity)); // Automatically generate document ID
                const newValues = {
                    appKey: key, // include appKey in the document data
                    reads: appActivities.reads,
                    writes: appActivities.writes,
                    deletes: appActivities.deletes,
                    uploads: appActivities.uploads,
                    timestamp: appActivities.timestamp
                };
                batch.set(docRef, newValues);
            }
        }
        await batch.commit();
        this.activities = {}; // Reset activities after successful sync
    }

    setupAutoSync() {
        this.interval = setInterval(() => this.syncActivities(), this.syncInterval);
        window.addEventListener('beforeunload', this.syncActivities);
    }

    cleanup() {
        clearInterval(this.interval);
        window.removeEventListener('beforeunload', this.syncActivities);
    }
}

// Exporting a singleton
export const activity = new ActivityManager();
