import React from 'react';

// Styles
import './DocumentAddedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const DocumentAddedHeadline = ({ headline, handleUserClick, handleFileClick, handleObjectClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;
            attached&nbsp;
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleFileClick(headline.fileUrl)}>
                {headline.fileName}
            </span>&nbsp;to&nbsp;
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}>
                {headline.objectTitle}
            </span>.&nbsp;
            {timestamp}
        </>
    );
};

export default DocumentAddedHeadline;
