import React, { useContext, useEffect, useState } from 'react';

// Firebase
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, collections, db } from '../firebaseConfig';

// Global
import { Global } from '../Global';

// Styles
import './Signup.css';

// Components
import AccountForm from './AccountForm';

import SignupButton from './signup/SignupButton';
import SignupLink from './signup/SignupLink';

// Managers
import InviteManager from '../managers/InviteManager';
import UserManager from '../managers/UserManager';

const inviteManager = new InviteManager();
const userManager = new UserManager();

/**
 * Signup Component
 * 
 * This component renders a signup form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function Signup() {
  const {
    invite,
    setCurrentUser,
    setInvite,
    setLoginMode,
    setLoginOpen,
    setPage,
    setSelectedApp
  } = useContext(Global);

  // State Variables
  const [defaultEmail, setDefaultEmail] = useState(''); // The email from the invite, if any
  const [email, setEmail] = useState(''); // The editable email field
  const [username, setUsername] = useState(''); // Username (required)
  const [fullName, setFullName] = useState(''); // Full Name (optionally required)
  const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
  const [handle, setHandle] = useState(''); // Handle (optionally required)
  const [password, setPassword] = useState(''); // Password (required)

  // Validation States
  const [formValid, setFormValid] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  /**
   * Handles invites by setting the selected app and default email. 
   * If an invite email exists, we set it as the default and non-editable.
   */
  useEffect(() => {
    if (invite && invite.email) {
      setDefaultEmail(invite.email); // Set the invite email as the default
      setEmail(invite.email); // Set email as the invite email for initial display
      //setIsEmailValid(true); // Assume valid initially
    }
  }, [invite]);

  /**
   * Enable the submit button only if all required fields are valid.
   */
  useEffect(() => {
    setSubmitEnabled(formValid);
  }, [formValid]);

  /**
   * Method to handle submit of the signup form.
   * 
   * @param {string} e - Click event.
   */
  const handleSubmit = async (e) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, collections.users, user.uid), {
        key: user.uid,
        username,
        email,
        fullname: fullName,
        displayname: displayName,
        handle,
        dateCreated: new Date(),
      });

      setLoginOpen(false); // Close the signup modal upon successful account creation

      const combinedUser = await userManager.fetchCurrentUser(auth.currentUser);
      setCurrentUser(combinedUser);

      // If an invite exists, create an app user
      if (invite) {
        inviteManager.processInvite(
          user.uid,
          email,
          invite,
          setInvite,
          setSelectedApp,
          setPage
        );
      }
    } catch (error) {
      console.error('Failed to create an account:', error.message);
    }
  };

  /**
   * Handles click on the login link.
   */
  const handleLoginClick = async () => {
    setLoginMode("LOGIN");
  };

  /**
   * Method to handle submit of the signup form.
   * 
   * @param {string} e - Click event.
   */
  const handleFormChange = async (
    username, 
    email,
    fullName,
    displayName,
    handle,
    password,
    isValid
  ) => {
    setUsername(username);
    setEmail(email);
    setFullName(fullName);
    setDisplayName(displayName);
    setHandle(handle);
    setPassword(password);
    setFormValid(isValid);
  };

  return (
    <div className="signup-container">

      {/* FORM */}
      <AccountForm
        onChange={handleFormChange}
      />

      {/* SUBMIT */}
      <SignupButton onClick={handleSubmit} enabled={submitEnabled} />

      {/* SIGN-IN LINK */}
      <SignupLink onClick={handleLoginClick} />

    </div>
  );
}

export default Signup;
