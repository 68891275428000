import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

// Styles
import './UserSettings.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import UserPhotoEdit from '../profile/UserPhotoEdit';

// Managers
import UserIndexManager from '../managers/UserIndexManager';
import UserManager from '../managers/UserManager';

const userIndexManager = new UserIndexManager();
const userManager = new UserManager();

function UserSettings() {
    const { theme } = useTheme();
    const { 
        currentUser,
        page,
        selectedApp,
        setLoginMode,
        setLoginOpen, 
        setPage,
        setSelectedApp,
        setSelectedModel,
        setSelectedObject,
        setSelectedField,
        setStatusMessage, 
        studio
    } = useContext(Global);

    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');

    useEffect(() => {
        setNewFirstName(currentUser.firstName);
        setNewLastName(currentUser.lastName);
    }, [currentUser.firstName, currentUser.lastName]);

    const handleSave = async () => {
        if (!currentUser.firstName.trim()) {
            alert('Please supply a first name.');
            return;
        }
        if (!currentUser.lastName.trim()) {
            alert('Please supply a last name.');
            return;
        }

        // Ensure the user is logged in before attempting to update the firstName
        if (currentUser) {
            // Update the user's information in the database
            await userManager.update(selectedApp.key, currentUser.key, {
                firstName: newFirstName,
                lastName: newLastName
            });

            // Update the user's information in the search index
            await userIndexManager.update(selectedApp.key, currentUser.key, newFirstName + " " + newLastName);
        
            setStatusMessage('User information updated successfully.');
            setLoginOpen(false);
        } else {
            alert('No user is logged in.');
        }
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await signOut(auth);

            setLoginOpen(false);

            setSelectedApp(null);
            setSelectedModel(null);
            setSelectedObject(null);
            setSelectedModel(null);
            setSelectedField(null);
            
            setLoginMode("LOGIN");
            
            setPage('WELCOME');

            console.log('You are now logged out.');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="logout-container"
            style={selectedApp && page === "RUN" ? { 
                backgroundColor: theme.backgroundColor } : {}
            }
        >
            <UserPhotoEdit />
            <form onSubmit={e => e.preventDefault()}> {/* Prevent model submission */}
                <div className="modal-label"
                    style={selectedApp && page === "RUN" && page === "RUN" ? { 
                        color: theme.foregroundColorFaded 
                    } : {
                        color: studio.foregroundColorFaded
                    }}
                >
                    First Name
                </div>
                <input
                    type="text"
                    name="firstName"
                    className="modal-input"
                    style={selectedApp && page === "RUN" ? { 
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor
                     } : {
                        backgroundColor: studio.backgroundColorFaded,
                        borderColor: studio.backgroundColorFaded,
                        color: studio.foregroundColor
                     }
                    }
                    value={newFirstName}
                    onChange={e => setNewFirstName(e.target.value)}
                />
                <div className="modal-label"
                    style={selectedApp && page === "RUN" && page === "RUN" ? { 
                        color: theme.foregroundColorFaded 
                    } : {
                        color: studio.foregroundColorFaded
                    }}
                >
                    Last Name
                </div>
                <input
                    type="text"
                    name="lastName"
                    className="modal-input"
                    style={selectedApp && page === "RUN" ? { 
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor
                     } : {
                        backgroundColor: studio.backgroundColorFaded,
                        borderColor: studio.backgroundColorFaded,
                        color: studio.foregroundColor
                     }
                    }
                    value={newLastName}
                    onChange={e => setNewLastName(e.target.value)}
                />
                <button
                    type="button" 
                    className="modal-button"
                    style={selectedApp && page === "RUN" ? { 
                        color: theme.foregroundColor } : {color: studio.foregroundColor}
                    }
                    onClick={handleSave}>
                    Save Changes
                </button>
                <br />
                <button
                    type="button"
                    className="logout-button"
                    style={selectedApp && page === "RUN" ? { 
                        color: theme.foregroundColorFaded } : {color: studio.foregroundColorFaded}
                    }
                    onClick={handleLogout}>
                    Sign Out
                </button>
            </form>
        </div>
    );
}

export default UserSettings;
