import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Circle Loader
import { CircleLoader } from 'react-spinners';

// Styles
import './ProgressPanel.css';

// Theme
import { useTheme } from '../ThemeContext';

const ProgressPanel = () => {
  const { theme } = useTheme();
  const {
    progressVisible,
    statusMessage
  } = useContext(Global);

  if (!progressVisible) return null;

  return (
    <div className="progress-panel">
      <div className="progress-content">
        <div className="status-message"
          style={{
            color: theme.foregroundColor
          }}
        >{statusMessage}</div>
        <div className="progress-circle">
          <CircleLoader
            size={50}
            color={theme.highlightBackgroundColor}
            loading={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressPanel;
