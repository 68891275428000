import React from 'react';

// Styles
import './Gallery.css';

const Gallery = ({ headline }) => {

    return (
        <>
            {headline.photoUrls.length === 1 &&
                <img src={headline.photoUrls[0]} alt="Gallery" className="gallery-photo" />
            }

            {headline.photoUrls.length === 2 &&
                <>
                    <div className="gallery-left">
                        <img src={headline.photoUrls[0]} alt="Gallery" className="gallery-photo" />
                    </div>
                    <div className="gallery-right">
                        <img src={headline.photoUrls[1]} alt="Gallery" className="gallery-photo" />
                    </div>
                </>
            }
            {headline.photoUrls.length >= 3 &&
                <>
                    <div className="gallery-left">
                        <img src={headline.photoUrls[0]} alt="Gallery" className="gallery-photo" />
                    </div>
                    <div className="gallery-right">
                        <div className="gallery-right-top">
                            <img src={headline.photoUrls[1]} alt="Gallery" className="gallery-photo" />
                        </div>
                        <div className="gallery-right-bottom">
                            <img src={headline.photoUrls[2]} alt="Gallery" className="gallery-photo" />
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Gallery;
