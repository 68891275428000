import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../ThemeContext'; // Adjust the import path based on your project structure

// Styled components for the switch
const SwitchContainer = styled.div`
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: ${({ checked, theme }) => (checked ? theme.highlightBackgroundColor : theme.backgroundColorFaded)};
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SwitchKnob = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
  transform: ${({ checked }) => (checked ? 'translateX(25px)' : 'translateX(0)')};
`;

const Switch = ({ initialChecked = false, onChange }) => {
  const { theme } = useTheme();

  const [checked, setChecked] = useState(initialChecked);

  const handleToggle = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <SwitchContainer theme={theme} checked={checked} onClick={handleToggle}>
      <SwitchKnob checked={checked} />
    </SwitchContainer>
  );
};

export default Switch;
