import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './RunHeader.css';

// Components
import BackButton from './BackButton';
import MenuButton from './MenuButton';
import RunLogo from './RunLogo';
import UserButton from '../foundation/UserButton';

const RunHeader = () => {
  const {
    selectedApp,
    selectedObject,
    setAppTitle,
  } = useContext(Global);

  useEffect(() => {
    if (selectedApp) {
      setAppTitle(selectedApp.title);
    }
  }, [selectedApp, setAppTitle]);

  return (
    <>
      <div className="run-header-container">
        <div className="run-header-left">
          {selectedObject ? (
            <BackButton />
          ) : (
            <MenuButton />
          )}
        </div>
        <div className="run-header-center">
          <RunLogo />
        </div>
        <div className="run-header-right">
          <UserButton />
        </div>
      </div>
    </>
  );
};

export default RunHeader;
