import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './MenuSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../foundation/Modal';

// Managers
import ModelManager from '../../managers/ModelManager';

const MenuSettings = ({
    isMenuSettingsOpen,
    setMenuSettingsOpen
}) => {
    const { theme } = useTheme();
    const {
        models,
        hideProgress,
        selectedApp,
        selectedModel,
        setModels,
        setSelectedModel,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    const [title, setModelTitle] = useState('');

    // Initialize permission states
    const [permissions, setPermissions] = useState({
        noview: [],
        noadd: [],
        noedit: [],
        nodelete: []
    });

    // Populate initial permissions when selectedModel changes
    useEffect(() => {
        if (selectedModel) {
            setPermissions({
                noview: selectedModel.noview || [],
                noadd: selectedModel.noadd || [],
                noedit: selectedModel.noedit || [],
                nodelete: selectedModel.nodelete || []
            });
        }
    }, [selectedModel]);

    useEffect(() => {

        if (!selectedModel) {
            return;
        }

        if (isMenuSettingsOpen) {
            setModelTitle(selectedModel.title || '');
        }

    }, [selectedModel, isMenuSettingsOpen]);

    const handleSave = async () => {
        setMenuSettingsOpen(false);

        // Update the title
        const newTitle = selectedModel.title;

        if (!newTitle.trim()) {
            alert('Please enter a model title.');
            return;
        }

        // Update the database
        const modelManager = new ModelManager();
        const result = await modelManager.update(
            selectedApp.key,
            selectedModel.key,
            title,
            false,
            permissions.noview,
            permissions.noadd,
            permissions.noedit,
            permissions.nodelete,
        );

        if (result.success) {
            // Reset the model and close the modal
            setModelTitle('');
            setStatusMessage("Model saved successfully.");
        } else {
            // Display the error message
            setStatusMessage(result.message);
        }
    };

    const handleDelete = async () => {

        showProgress("Deleting menu item...");

        // Close the modal
        setMenuSettingsOpen(false);

        if (!selectedModel) {
            alert('No object selected!');
            return;
        }

        try {

            const modelKey = selectedModel.key;

            const modelManager = new ModelManager();
            await modelManager.delete(selectedApp.key, modelKey);

            const updatedModels = models.filter(model => model.key !== modelKey);
            setModels(updatedModels);

            // Select the first object in the updated list, if any
            if (models.length > 0) {
                setSelectedModel(models[0]);
            } else {
                // If no models are left, clear the selected model
                setSelectedModel(null);
            }

            setStatusMessage('Object and related data deleted successfully');
        } catch (error) {
            console.error("Error deleting object and related data: ", error);
            setStatusMessage('Error deleting object and related data');
        }

        hideProgress();
    };

    return (

        <Modal title="Menu Item Settings"
            isOpen={isMenuSettingsOpen}
            onClose={() => setMenuSettingsOpen(false)}
            width="300px">

            <div className="menu-settings-container">
                <div className="menu-settings-wrapper">
                    <div className="menu-settings-left">
                        <div className="menu-settings-label"
                            style={{color: theme.foregroundColorFaded}}
                            >
                            Title
                        </div>
                        <input
                            type="text"
                            name="title"
                            className="modal-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded
                            }}
                            value={title}
                            maxLength="25"
                            onChange={e => setModelTitle(e.target.value)}
                        />
                    </div>
                </div>

                <div className="modal-buttons">
                    <button
                        type="button"
                        onClick={handleSave}
                        className="modal-button"
                        style={{color: theme.foregroundColor}}>
                            Save Changes
                    </button>
                </div>

                <div className="modal-buttons">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="modal-button-delete"
                        style={{color: theme.foregroundColorFaded}}>
                            Delete
                    </button>
                </div>
            </div>
        </Modal>

    );
};

export default MenuSettings;
