import React, { useEffect, useState } from 'react';

// Styles
import './Day.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import Select from '../../../../foundation/select/Select';

/**
 * Day Component
 * 
 * This component renders a day of the week selector.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Day = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    // Theme
    const { theme } = useTheme();

    // State Variables
    const [value, setValue] = useState(''); // Default ADD mode value

    /**
     * Initialize the field value.
     */
    useEffect(() => {
        const initialValue = object?.[field.key] || '';
        setValue(initialValue); // Ensure value is synced with value
    }, [object, field.key]);

    /**
     * Method to handle the change in value.
     * 
     * @param {string} e - Change event.
     */
    const handleChange = (val) => {
        setValue(val);
        onUpdate(field, val);
    };

    const options = [
        { value: '0', label: 'Sunday' },
        { value: '1', label: 'Monday' },
        { value: '2', label: 'Tuesday' },
        { value: '3', label: 'Wednesday' },
        { value: '4', label: 'Thursday' },
        { value: '5', label: 'Friday' },
        { value: '6', label: 'Saturday' }
    ];

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                readOnly={readOnly}
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* READ-ONLY TEXT */}
                {readOnly ? (
                    <div className='month-value'
                        style={{ color: theme.foregroundColor }}>
                        {options.find(option => option.value === value)?.label}
                    </div>
                ) : (
                    <>
                        {/* INPUT */}
                        <Select
                            options={options}
                            value={value}
                            onChange={(value) => handleChange(value)}
                        />
                    </>
                )}

            </FieldContainer>
        </>
    );
};

export default Day;
