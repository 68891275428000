import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './AppSelector.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import SelectIcon from '../svg/SelectIcon';

const AppSelector = () => {
    const { theme } = useTheme();
    const {
        setTargetApp,
        targetApp,
        userApps
    } = useContext(Global);

    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    const toggleDropdown = (event) => {
        setIsOpen(!isOpen);
    };

    // Handle clicking outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current &&
                !containerRef.current.contains(event.target) &&
                event.target.tagName !== 'LI') {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Function to return the label based on the targetApp value
    const getLabel = () => {
        if (targetApp === "ALL") return "All Apps";
        const app = userApps.find(app => app.key === targetApp);
        return app.title;
    };

    const handleAppChange = (event, app) => {
        event.stopPropagation();
        setTargetApp(app);
        setIsOpen(false);
    };

    return (
        <div className="app-selector-outer-container">
            <div ref={containerRef} className="app-selector-container" onClick={toggleDropdown}>
                <div className="app-selector-triangle">
                    <SelectIcon
                        color={theme.foregroundColor}
                        width="16"
                        height="16"
                    />
                </div>
                <span
                    style={{ color: theme.foregroundColor }}>{getLabel()}</span>
            </div>
            {isOpen && (
                <ul className="app-selector-dropdown-menu"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded
                    }} 
                    onClick={(e) => e.stopPropagation()}>
                    <li onClick={(e) => {
                        e.stopPropagation();
                        handleAppChange(e, "ALL");
                    }} className="app-selector-dropdown-menu-item"
                        style={{ color: theme.foregroundColor }}
                    >
                        All Apps
                    </li>
                    {userApps.map((app, index) => (
                        <li key={index} onClick={(e) => {
                            e.stopPropagation();
                            handleAppChange(e, app.key);
                        }} className="app-selector-dropdown-menu-item"
                            style={{ color: theme.foregroundColor }}
                        >
                            {app.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AppSelector;
