import React from 'react';

// Styles
import './ContextMenuButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DotsIcon from '../../svg/DotsIcon';

// The main component
const ContextMenuButton = ({ title, icon: IconComponent = DotsIcon, iconSize = 16, onToggle, dotsColor }) => {
  const { theme } = useTheme();

  if(dotsColor === undefined) {
    dotsColor = theme.foregroundColor;
  }

  return (
    <div className="context-menu-button-container"
      onClick={onToggle}>
      <div
        className="context-menu-button-icon"
        title={title}>
        <IconComponent
          color={dotsColor}
          width={iconSize}
          height={iconSize}
        />
      </div>
    </div>
  );
};

export default ContextMenuButton;
