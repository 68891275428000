import React from 'react';

// Styles
import './ObjectChecklistPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../../svg/CheckOffIcon';
import CheckOnIcon from '../../../../svg/CheckOnIcon';
import RemoveIcon from '../../../../svg/RemoveIcon';

// Updated ChecklistPreview component to support multi-line input
const ObjectChecklistPreview = ({
    placeholder,
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="object-checklist-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div className="object-checklist-preview-label"
                style={{ color: theme.foregroundColorFaded }}>
                {placeholder}
            </div>
            <div className="object-checklist-preview-wrapper">
                <div className="object-checklist-preview-content">
                    <div className="object-checklist-preview-item">
                        <div className="object-checklist-preview-item-check-wrapper"
                        >
                            <div className="object-checklist-preview-item-checkbox">
                                <CheckOnIcon
                                    color={theme.highlightBackgroundColor}
                                    checkColor={theme.foregroundColor}
                                    width="30"
                                    height="30"
                                />
                            </div>
                            <div
                                className="object-checklist-preview-item-text"
                                style={{
                                    color: theme.foregroundColor
                                }}
                            >
                                Item 1
                            </div>
                        </div>
                        <div className="object-checklist-preview-item-remove-container">
                            <RemoveIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />
                        </div>
                    </div>
                    <div className="object-checklist-preview-item">
                        <div className="object-checklist-preview-item-check-wrapper"
                        >
                            <div className="object-checklist-preview-item-checkbox">
                                <CheckOffIcon
                                    color={theme.highlightBackgroundColor}
                                    checkColor={theme.foregroundColor}
                                    width="30"
                                    height="30"
                                />
                            </div>
                            <div
                                className="object-checklist-preview-item-text"
                                style={{
                                    color: theme.foregroundColor
                                }}
                            >
                                Item 2
                            </div>
                        </div>
                        <div className="object-checklist-preview-item-remove-container">
                            <RemoveIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />
                        </div>
                    </div>
                    <div className="object-checklist-preview-item">
                        <div className="object-checklist-preview-item-check-wrapper"
                        >
                            <div className="object-checklist-preview-item-checkbox">
                                <CheckOffIcon
                                    color={theme.highlightBackgroundColor}
                                    checkColor={theme.foregroundColor}
                                    width="30"
                                    height="30"
                                />
                            </div>
                            <div
                                className="object-checklist-preview-item-text"
                                style={{
                                    color: theme.foregroundColor
                                }}
                            >
                                Item 3
                            </div>
                        </div>
                        <div className="object-checklist-preview-item-remove-container">
                            <RemoveIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectChecklistPreview;
