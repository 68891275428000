import React, { useContext } from 'react';

// Styles
import './UserDot.css';

// Global
import { Global } from '../Global';

// Theme
import { useTheme } from '../ThemeContext';

const UserDot = ({ firstInitial = '', size }) => {
    const { theme } = useTheme();
    const {
        selectedApp,
        studio
    } = useContext(Global);

    const style = {
        backgroundColor: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
        color: selectedApp ? theme.highlightForegroundColor : studio.highlightForegroundColor,
        '--dot-size': size + 'px',
        '--dot-font-size': size * 0.5 + 'px',  // 50% of size
        height: size + 'px',
        width: size + 'px',
    };

    return (
        <div
            className="user-dot-container"
            style={style}>
                {firstInitial}
        </div>
    );
};

export default UserDot;
