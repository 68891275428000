import React, { useContext, useEffect, useState, useCallback } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// React Window
import { FixedSizeList as List } from 'react-window';

// Debounce
import { debounce } from 'lodash';  // Implement debounce manually

// Managers
import ObjectManager from '../../managers/ObjectManager';

// Styles
import './Objects.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AddButton from './AddButton';
import Divider from '../../foundation/Divider';
import Object from './Object';
import ObjectsToolBar from './ObjectsToolBar';
import Table from '../table/Table';

const Objects = () => {
    const { theme } = useTheme();
    const {
        formMode,
        groups,
        models,
        objects,
        objectsView,
        selectedApp,
        selectedLookup,
        selectedModel,
        setSummaryHeight,
        setObjects,
        summaryHeight,
        sortField,
        sortDirection
    } = useContext(Global);

    // State Variables
    const [width, setWidth] = useState("440px");
    const [viewedItems, setViewedItems] = useState([]);  // Track items that have been viewed
    const [listHeight, setListHeight] = useState(0);

    // Constants
    const numberToSubtractMobile = 100; // Fixed height to subtract for mobile
    const numberToSubtractDesktop = 110; // Fixed height to subtract for desktop

    // Debounce function to batch view count updates
    const batchUpdateViews = useCallback(
        debounce((items) => {
            if (items.length > 0) {
                const objectManager = new ObjectManager();
                objectManager.updateViewCount(selectedApp.key, selectedModel.key, items);
            }
        }, 1000),
        []
    );

    // Adjust width of items according to whether we're in table or form view
    useEffect(() => {
        if (objectsView === "TABLE") {
            setWidth(isMobile || isTablet ? "100%" : "calc(440px + 40%)");
        } else { // LIST or GROUPS
            setWidth(isMobile || isTablet ? "100%" : "440px");
        }

        // Calculate list height based on mobile or desktop
        const availableHeight = window.innerHeight;
        const calculatedHeight = isMobile || isTablet
            ? availableHeight - numberToSubtractMobile
            : availableHeight - numberToSubtractDesktop;

        setListHeight(calculatedHeight);
    }, [objectsView]);

    useEffect(() => {
        if (models === undefined || !models || models.length === 0) return;

        if (!selectedModel) {
            setObjects([]);
            return;
        }

        const model = models.find(model => model.key === selectedModel.key);
        if (model) {
            setSummaryHeight(model.summaryHeight || "50px");
        }

        const objectManager = new ObjectManager();
        const handleUpdate = (items) => {
            items.sort((a, b) => {
                let valueA, valueB;
                switch (sortField) {
                    case 'CREATED':
                        valueA = a.dateCreated;
                        valueB = b.dateCreated;
                        break;
                    case 'MODIFIED':
                        valueA = a.dateModified;
                        valueB = b.dateModified;
                        break;
                    default:
                        if (a[sortField] && b[sortField]) {
                            const lineA = a[sortField]?.toLowerCase();
                            const lineB = b[sortField]?.toLowerCase();
                            valueA = lineA;
                            valueB = lineB;
                        }
                }

                if (sortDirection === 'ASC') {
                    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                } else if (sortDirection === 'DESC') {
                    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
                }

                return 0;
            });

            setObjects(items);
        };

        const unsubscribe = objectManager.listAndSubscribe(selectedApp.key, selectedModel.key, handleUpdate);
        return () => unsubscribe();
    }, [selectedModel, sortField, sortDirection, models, selectedApp.key, setObjects, setSummaryHeight]);

    // Callback when items are rendered in the virtualized list
    const handleItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
        // Extract the object keys from the visible range of objects
        const newlyViewedKeys = objects
            .slice(visibleStartIndex, visibleStopIndex + 1)
            .map((obj) => obj.key)  // Extract only the 'key' from each object
            .filter((key) => !viewedItems.includes(key));  // Filter out keys that have already been viewed

        if (newlyViewedKeys.length > 0) {
            setViewedItems([...viewedItems, ...newlyViewedKeys]);
            batchUpdateViews(newlyViewedKeys);
        }
    };

    // Render each object in the virtualized list
    const renderObject = ({ index, style }) => {
        const object = objects[index];
        return (
            <div key={object.key} style={style}>
                <Object object={object} height={summaryHeight} />
                <Divider />
            </div>
        );
    };

    return (
        <>
            <div className={(isMobile || isTablet) ? "objects-container-mobile" : "objects-container"}
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    width: width
                }}>
                <ObjectsToolBar />
                {objectsView === "TABLE" && (
                    <div className="objects-table">
                        <Table />
                    </div>
                )}

                {objectsView === "LIST" && (
                    <List
                        height={listHeight}
                        itemCount={objects.length}
                        itemSize={parseInt(summaryHeight, 10)}
                        onItemsRendered={handleItemsRendered}
                        width={"100%"}>
                        {renderObject}
                    </List>
                )}

                {objectsView === "GROUPS" && (
                    <div className="objects-groups-wrapper">
                        {groups
                            .filter(group => objects.some(obj => obj[selectedLookup.key] === group.key)) // Filter out empty groups
                            .map((group) => (
                                <div
                                    key={group.key}
                                    style={{
                                        color: theme.foregroundColorFaded
                                    }}>
                                    <div className="objects-group-title">
                                        {group.title}
                                    </div>
                                    <div className="objects-group-row"
                                        style={{
                                            minHeight: (parseInt(summaryHeight, 10)) + "px"
                                        }}>

                                        {objects
                                            .filter(obj => obj[selectedLookup.key] === group.key)
                                            .map((object) => (
                                                <div
                                                    key={object.id}
                                                    className="objects-group-row"
                                                    style={{
                                                        minWidth: "90%",
                                                        marginRight: "5%"
                                                    }}>
                                                    <Object object={object} height={summaryHeight} />
                                                </div>
                                            ))}

                                    </div>

                                    <Divider />
                                </div>
                            ))}
                    </div>
                )}

                {selectedModel && formMode !== "ADD" &&
                    <div className="objects-add-button">
                        <AddButton />
                    </div>
                }
            </div>
        </>
    );
};

export default Objects;
