import React from 'react';

// Styles
import './EmojiButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import EmojiIcon from '../../../../svg/EmojiIcon';

const EmojiButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="emoji-button-container">
            <EmojiIcon
                color={theme.foregroundColor}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default EmojiButton;
