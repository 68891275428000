import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './EventForm.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Styled Components
import { createGlobalStyle } from 'styled-components';

// Components
import EventDates from './EventDates';
import EventFieldSelector from './EventFieldSelector';
import EventRemoveButton from './EventRemoveButton';
import EventSaveButton from './EventSaveButton';
import EventTitle from './EventTitle';
import EventDivider from './EventDivider';
import EventFieldChecklist from './EventFieldChecklist';
import EventObjectForm from './EventObjectForm';
import EventObjectSelector from './EventObjectSelector';
import FormField from '../../form/FormField';
import Modal from '../../../foundation/Modal';

// Managers
import EventManager from '../../../managers/EventManager';
import HeadlineManager from '../../../managers/HeadlineManager';
import ObjectManager from '../../../managers/ObjectManager';

const eventManager = new EventManager();
const headlineManager = new HeadlineManager();
const objectManager = new ObjectManager();

// Inline Styles
const InlineStyles = createGlobalStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: .2; 
  }
`;

const EventForm = ({ date }) => {
  const { theme } = useTheme();
  const {
    appFields,
    currentUser,
    eventVisible,
    models,
    selectedApp,
    selectedEvent,
    setEventVisible
  } = useContext(Global);

  // State Variables
  const [title, setTitle] = useState('');
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [modalTitle, setModalTitle] = useState('Add Event');
  const [fields, setFields] = useState([]); // Stores event fields
  const [formData, setFormData] = useState({}); // Stores [field.key]: values

  // Object
  const [objectFields, setObjectFields] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [checklistOpen, setChecklistOpen] = useState(false);
  const [eventObjects, setEventObjects] = useState([]);

  const [newObject, setNewObject] = useState(null);

  // Fields
  const handleFieldAdd = (field) => {
    if (field.type === 'checklist') {
      // Copy the field.value to the formData object
      setFormData(prev => ({ ...prev, [field.key]: field.value }));
    }

    setFields(prevFields => [...prevFields, field]);
  };

  const handleFieldUpdate = (field, value) => {
    // Add the field value pair to the fieldObject
    setFormData(prev => ({ ...prev, [field.key]: value }));
  };

  // Initialize formData with a key
  useEffect(() => {
    if (Object.keys(formData).length === 0) {
      setFormData({ key: generateKey() });
    }
  }, [formData]);

  // Initialize state based on whether we are adding or editing
  useEffect(() => {
    if (!eventVisible) return;

    if (selectedEvent) {
      // Editing an existing event
      setModalTitle('Edit Event');
      setTitle(selectedEvent.title);
      const start = selectedEvent.startDate.toDate();
      const end = selectedEvent.endDate.toDate();
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries(selectedEvent.attachments || []);
      setFields(selectedEvent.fields || []);
      setEventObjects(selectedEvent.eventObjects || []);
      setFormData(selectedEvent.formData || {});
    } else {
      // Adding a new event
      setModalTitle('Add Event');
      setTitle('');
      const start = new Date(date);
      const end = new Date(start);
      end.setHours(end.getHours() + 1);
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries([]);
      setFields([]);
      setEventObjects([]);
      setFormData({});
    }
  }, [eventVisible, selectedEvent, date]);

  const handleStartDateChange = (d) => {
    setStartDate(d);
  };

  const handleStartTimeChange = (t) => {
    setStartTime(t);
  };

  const handleEndDateChange = (d) => {
    setEndDate(d);
  };

  const handleEndTimeChange = (t) => {
    setEndTime(t);
  };

  /**
   * Method to handle saving of the event information.
   * 
  */
  const save = async () => {
    if (title.trim() === '') {
      setTitle('(No title)');
    }

    const start = new Date(startDate);
    start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    const end = new Date(endDate);
    end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    const key = selectedEvent ? selectedEvent.key : generateKey();

    let startTimestamp = Timestamp.fromDate(start);
    let endTimestamp = Timestamp.fromDate(end);

    const data = {
      appKey: selectedApp.key,
      key: key,
      title: title,
      startDate: startTimestamp,
      endDate: endTimestamp,
      attachments: selectedEntries,
      fields: fields,
      eventObjects: eventObjects,
      formData: formData
    };

    if (selectedEvent) {
      await eventManager.update(selectedApp.key, key, data);
    } else {
      await eventManager.add(selectedApp.key, key, data);

      // Create a headline
      headlineManager.addEventAddedHeadline(
        selectedApp.key,
        currentUser.key,
        currentUser.firstName,
        currentUser.lastName,
        key,
        title,
        startTimestamp,
        endTimestamp
      );
    }

    setEventVisible(false);
  };

  /**
   * Method to handle the removal of an event.
   * 
  */
  const handleRemoveEvent = async () => {
    await eventManager.delete(selectedApp.key, selectedEvent.key);
    setEventVisible(false);
  };

  /**
   * Method to handle the selection of an object.
   * 
   * @param {string} entry - The selected search index entry.
  */
  const handleObjectSelect = async (entry) => {
    const modelKey = entry.modelKey;
    const model = models.find(m => m.key === modelKey);
    const fields = appFields.filter(field => field.modelKey === modelKey);

    // Fetch the object
    const object = await objectManager.fetch(selectedApp.key, modelKey, entry.objectKey);

    const newObject = {
      object: object
    };

    // Set the selected object key
    setNewObject(newObject);

    // Set the fields for the selected model
    setObjectFields(fields);

    // Set the selected model
    setSelectedModel(model);

    // Open a modal to select the fields to include
    setChecklistOpen(true);
  };

  /**
   * Method to handle the selection of the fields to include.
   * 
   * @param {Array} fields - Array of selected fields.
   */
  const handleFieldsSelected = async (fields) => {

    // Create the new object to add to the eventObjects array
    const updatedObject = {
      ...newObject,
      fields: fields
    };

    // Update the eventObjects state by adding the new object to the existing array
    setEventObjects(prevObjects => [...prevObjects, updatedObject]);
  };

  return (
    <>
      <InlineStyles
        placeholderColor={theme.foregroundColorFaded}
      />

      <Modal title={modalTitle} isOpen={eventVisible} onClose={() => setEventVisible(false)} width="100%" height="100%">

        <div className="event-form-container">

          <div className="event-form-sections">
            {/* EVENT TITLE */}
            <EventTitle
              title={title}
              setTitle={setTitle}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* DATES AND TIMES */}
            <EventDates
              defaultStart={defaultStart}
              defaultEnd={defaultEnd}
              onStartDateChange={handleStartDateChange}
              onStartTimeChange={handleStartTimeChange}
              onEndDateChange={handleEndDateChange}
              onEndTimeChange={handleEndTimeChange}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* OBJECT FORMS */}
            {eventObjects.map((object) => (
              <div
                style={{
                  color: theme.foregroundColor
                }}>
                <EventObjectForm
                  eventObject={object}
                />

                <EventDivider />

              </div>
            ))}

            {/* FORM FIELDS*/}
            {formData && fields.map((field) => (
              <FormField
                object={formData}
                key={field.key}
                field={field}
                onUpdate={handleFieldUpdate}
                showFieldMenu={false}
              />
            ))}

            {fields.length > 0 &&
              <>
                {/* DIVIDER */}
                < EventDivider />
              </>
            }

            {/* EVENT OBJECT BUTTON */}
            <EventObjectSelector
              onObjectSelect={handleObjectSelect}
            />

            {/* FIELD SELECTOR */}
            <EventFieldSelector
              fields={fields}
              onFieldAdd={handleFieldAdd}
            />

          </div>

          {/* MASK */}
          <div className="event-form-buttons-mask"
            style={{
              backgroundColor: theme.backgroundColor
            }}>
          </div>

          {/* BUTTONS */}
          <div className="event-form-buttons">

            {/* SAVE BUTTON */}
            <EventSaveButton
              onSave={save}
            />

            {selectedEvent &&
              <>
                {/* DELETE BUTTON */}
                <EventRemoveButton
                  onRemove={handleRemoveEvent}
                />
              </>
            }

          </div>

          {/* FIELD CHECKLIST */}
          <EventFieldChecklist
            modalOpen={checklistOpen}
            setModalOpen={setChecklistOpen}
            model={selectedModel}
            fields={objectFields}
            onSave={handleFieldsSelected}
          />

        </div>

      </Modal >
    </>
  );
};

export default EventForm;
