import React, { useContext, useState, useEffect, useRef } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../../utilities/Keys';

// Activity
import { activity } from '../../managers/ActivityManager';

// Styles
import './RunHomePost.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import UserDot from '../../foundation/UserDot';

// Images
import DocumentIcon from '../../svg/DocumentIcon';
import ImageIcon from '../../svg/ImageIcon';
import VideoIcon from '../../svg/VideoIcon';

// Managers
import HeadlineManager from '../../managers/HeadlineManager';

const headlineManager = new HeadlineManager();

const RunHomePost = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        hideProgress,
        selectedApp,
        showProgress
    } = useContext(Global);

    const [isFocused, setIsFocused] = useState(false);
    const [postContent, setPostContent] = useState("");
    const [photoUrls, setPhotoUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [localPhotos, setLocalPhotos] = useState([]);
    const [localVideos, setLocalVideos] = useState([]);
    const [documentFiles, setDocumentFiles] = useState([]);

    const textareaRef = useRef(null);
    const photoInputRef = useRef(null);
    const videoInputRef = useRef(null);
    const documentInputRef = useRef(null);

    const handleFocus = () => setIsFocused(true);
    const handleChange = (e) => setPostContent(e.target.value);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

            if (textareaRef.current.scrollHeight > 200) {
                textareaRef.current.style.height = '200px';
            }
        }
    }, [postContent]);

    const handlePost = async () => {
        showProgress('Uploading files...');
        const uploadedPhotoUrls = [];
        const uploadedVideoUrls = [];
        const uploadedDocumentUrls = [];
        let totalBytes = 0; // Initialize totalBytes to accumulate file sizes
    
        // Upload photos
        for (const file of localPhotos) {
            const key = generateKey();
            const storageRef = ref(storage, `images/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedPhotoUrls.push(url);
    
            // Accumulate file size
            totalBytes += file.size;
        }

        // Upload videos
        for (const file of localVideos) {
            const key = generateKey();
            const storageRef = ref(storage, `videos/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedVideoUrls.push(url);
    
            // Accumulate file size
            totalBytes += file.size;
        }
    
        // Upload documents
        for (const { file } of documentFiles) {
            const key = generateKey();
            const storageRef = ref(storage, `files/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedDocumentUrls.push({ url, name: file.name });
    
            // Accumulate file size
            totalBytes += file.size;
        }
    
        // Log the total bytes of uploaded files
        activity.log(selectedApp.key, 'uploads', totalBytes);
    
        hideProgress();
    
        headlineManager.addPostHeadline(
            selectedApp.key,
            currentUser.key,
            currentUser.firstName,
            currentUser.lastName,
            postContent,
            uploadedPhotoUrls,
            uploadedVideoUrls,
            uploadedDocumentUrls
        );
    
        setPostContent("");
        setPhotoUrls([]);
        setLocalPhotos([]);
        setVideoUrls([]);
        setLocalVideos([]);
        setDocumentFiles([]);
        setIsFocused(false);
    };    

    const handlePhotoUpload = (event) => {
        const files = Array.from(event.target.files);
        const newLocalImages = files.map(file => URL.createObjectURL(file));
        setLocalPhotos([...localPhotos, ...files]);
        setPhotoUrls([...photoUrls, ...newLocalImages]);
        photoInputRef.current.value = "";
    }; 

    const handleVideoUpload = (event) => {
        const files = Array.from(event.target.files);
        const newLocalVideos = files.map(file => URL.createObjectURL(file));
        setLocalVideos([...localVideos, ...files]);
        setVideoUrls([...videoUrls, ...newLocalVideos]);
        videoInputRef.current.value = "";
    };

    const handleDocumentUpload = (event) => {
        const files = Array.from(event.target.files);
        const newDocumentFiles = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setDocumentFiles([...documentFiles, ...newDocumentFiles]);
        documentInputRef.current.value = "";
    };

    const handleDocumentClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    // Utility function to truncate the file name
    const truncateFileName = (fileName, maxLength) => {
        if (fileName.length <= maxLength) return fileName;

        const extension = fileName.split('.').pop();
        const nameWithoutExtension = fileName.slice(0, fileName.length - extension.length - 1);

        const truncatedName = nameWithoutExtension.slice(0, maxLength - extension.length - 4); // 3 for '...' and 1 for the dot before extension

        return `${truncatedName}...${extension}`;
    };

    return (
        <div className="run-home-post-container"
            style={{
                borderBottomColor: theme.backgroundColorFaded,
                borderRightColor: theme.backgroundColorFaded
            }}>
            <div className="run-home-post-wrapper">
                <div className="run-home-post-left">
                    <UserDot
                        firstInitial={currentUser.username.toUpperCase()[0]}
                        size="36"
                    />
                </div>
                <div className="run-home-post-center">
                    <textarea
                        name="post"
                        className="run-home-post-input"
                        placeholder="What's new today?"
                        style={{
                            backgroundColor: theme.backgroundColor,
                            borderColor: theme.backgroundColor,
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily,
                            maxHeight: '200px' // Set max height
                        }}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        value={postContent}
                        rows={1}
                        ref={textareaRef}
                    />
                </div>
                {isFocused && (
                    <div className="run-home-post-right">
                        <div className="run-home-post-button"
                            style={{
                                backgroundColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor
                            }}
                            onClick={handlePost}>
                            Post
                        </div>
                    </div>
                )}
            </div>
            <div className={`run-home-post-attachments ${isFocused ? 'visible' : 'hidden'}`}>
                <div className="run-home-post-attachment-buttons">
                    <div className="run-home-post-attachment-button"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}
                        onClick={() => photoInputRef.current.click()}>
                        <ImageIcon
                            color={theme.foregroundColor}
                            width="18"
                            height="18"
                        />
                        <input
                            ref={photoInputRef}
                            id="photo-upload"
                            type="file"
                            accept="image/*"
                            onChange={handlePhotoUpload}
                            style={{ display: 'none' }}
                            multiple
                        />
                    </div>
                    <div className="run-home-post-attachment-button"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}
                        onClick={() => videoInputRef.current.click()}>
                        <VideoIcon
                            color={theme.foregroundColor}
                            width="18"
                            height="18"
                        />
                        <input
                            ref={videoInputRef}
                            id="video-upload"
                            type="file"
                            accept="video/*"
                            onChange={handleVideoUpload}
                            style={{ display: 'none' }}
                            multiple
                        />
                    </div>
                    <div className="run-home-post-attachment-button"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}
                        onClick={() => documentInputRef.current.click()}>
                        <DocumentIcon
                            color={theme.foregroundColor}
                            width="16"
                            height="16"
                        />
                        <input
                            ref={documentInputRef}
                            id="document-upload"
                            type="file"
                            accept=".pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx,.csv,.rtf,.html,.zip"
                            onChange={handleDocumentUpload}
                            style={{ display: 'none' }}
                            multiple
                        />
                    </div>
                </div>
            </div>
            {photoUrls && photoUrls.length > 0 &&
                <div className="run-home-post-thumbnails">
                    {photoUrls.map((url, index) => (
                        <img key={index} src={url} alt="attachment" className="run-home-post-thumbnail" />
                    ))}
                </div>
            }
            {documentFiles && documentFiles.length > 0 &&
                <div className="run-home-post-documents">
                    {documentFiles.map(({ file, url }, index) => (
                        <div key={index} className="run-home-post-document"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}>
                            <DocumentIcon
                                color={theme.highlightBackgroundColor}
                                width="14"
                                height="14"
                            />
                            <div
                                className="run-home-post-document-filename"
                                style={{
                                    color: theme.foregroundColor
                                }}
                                onClick={() => handleDocumentClick(url)}>
                                {truncateFileName(file.name, 100)}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default RunHomePost;
