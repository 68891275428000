import React from 'react';

// Styles
import './CartButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CartIcon from '../../../../svg/CartIcon';

const CartButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="cart-button-container">
            <CartIcon
                color={theme.foregroundColor}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default CartButton;
