import React from 'react';

// Styles
import './PostHeadline.css';

// Components
import Gallery from './Gallery';
import VideoGallery from './VideoGallery';
import RunHomeComments from '../RunHomeComments';
import UserDot from '../../../foundation/UserDot';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DocumentIcon from '../../../svg/DocumentIcon';

const PostHeadline = ({ headline, handleUserClick, timestamp }) => {
    const { theme } = useTheme();

    const handleDocumentClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    // Utility function to truncate the file name
    const truncateFileName = (fileName, maxLength) => {
        if (fileName.length <= maxLength) return fileName;

        const extension = fileName.split('.').pop();
        const nameWithoutExtension = fileName.slice(0, fileName.length - extension.length - 1);

        const truncatedName = nameWithoutExtension.slice(0, maxLength - extension.length - 4); // 3 for '...' and 1 for the dot before extension

        return `${truncatedName}...${extension}`;
    };

    return (
        <>
            <div
                key={headline.key}
                className="post-headline-wrapper">
                <div className="post-headline-left"
                    onClick={() => handleUserClick(headline.userKey)}>
                    <UserDot
                        firstInitial={headline.username && headline.username.toUpperCase()[0]}
                        size="36"
                    />
                </div>
                <div className="post-headline-right">
                    <div className="post-headline-header">
                        <div className="post-headline-user"
                            onClick={() => handleUserClick(headline.userKey)}
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {headline.userFirstName} {headline.userLastName}
                        </div>
                        <div className="post-headline-timestamp"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            • {timestamp}
                        </div>
                    </div>
                    <div className="post-headline-content"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {headline.text}
                    </div>
                    {headline.documents && headline.documents.length > 0 &&
                        <div className="post-headline-documents-wrapper">
                            {headline.documents.map((document, index) => (
                                <div key={index} className="post-headline-document"
                                    style={{
                                        backgroundColor: theme.backgroundColorFaded
                                    }}
                                >
                                    <DocumentIcon
                                        color={theme.highlightBackgroundColor}
                                        width="14"
                                        height="14"
                                    />
                                    <div
                                        className="post-headline-document-filename"
                                        style={{
                                            color: theme.foregroundColor
                                        }}
                                        onClick={() => handleDocumentClick(document.url)}
                                    >
                                        {truncateFileName(document.name, 100)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    }

                </div>
            </div>
            {headline.photoUrls && headline.photoUrls.length > 0 &&
                <div className="post-headline-gallery-wrapper">
                    <Gallery headline={headline} />
                </div>
            }
            {headline.videoUrls && headline.videoUrls.length > 0 &&
                <div className="post-headline-gallery-wrapper">
                    <VideoGallery headline={headline} />
                </div>
            }
            <div className="post-headline-comment-section">
                <RunHomeComments
                    headline={headline}
                />
            </div>
        </>
    );
};

export default PostHeadline;
