import { doc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';

class TransactionManager {
    /**
     * Method to add a new payment method.
     * 
     * @param {string} key - The new key of the payment method.
     * @param {string} data - Payment method data.
     * @returns {paymethod} - New paymethod record.
    */
    async addPaymentMethod(key, data) {
        await setDoc(doc(db, collections.paymethods, key), data);
        return data;
    }

    /**
     * Method to add a new transaction.
     * 
     * @param {string} key - The new key of the transaction.
     * @param {string} data - Transaction data.
     * @returns {transaction} - New transaction record.
    */
    async addTransaction(key, data) {
        await setDoc(doc(db, collections.transactions, key), data);
        return data;
    }

    /**
     * Method to add a new subscription.
     * 
     * @param {string} key - The new key of the subscription.
     * @param {string} data - Subscription data.
     * @returns {subscription} - New subscription record.
    */
    async addSubscription(key, data) {
        await setDoc(doc(db, collections.subscriptions, key), data);
        return data;
    }

    /**
     * Method to fetch a payment method by card number.
     * 
     * @param {string} cardNumber - The card number to search for.
     * @returns {paymethod|null} - The paymethod record or null if not found.
    */
    async fetchPaymentMethodByCardNumber(cardNumber) {
        const paymethodsRef = collection(db, collections.paymethods);
        const q = query(paymethodsRef, where('cardNumber', '==', cardNumber));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            return { key: doc.id, ...doc.data() };
        }

        return null;
    }

    /**
     * Method to fetch payment methods by user key.
     * 
     * @param {string} userKey - The user key to search for.
     * @returns {Array} - The list of payment methods for the user.
    */
    async fetchPaymentMethodByUserKey(userKey) {
        const paymethodsRef = collection(db, collections.paymethods);
        const q = query(paymethodsRef, where('userKey', '==', userKey));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));
        }

        return [];
    }

    /**
     * Fetches transactions for a specified creator within a certain time bounds and optionally for a specific app.
     * 
     * @param {string} userKey - Key of the user being paid.
     * @param {Date} startDate - The start date for filtering transactions.
     * @param {string} targetApp - The app key to filter transactions (optional).
     * @returns {Promise<Array>} A promise that resolves to an array of transactions.
     */
    async fetchIncomingTransactions(userKey, startDate, targetApp) {
        try {
            // Define the collection reference
            const transactionsCollection = collection(db, collections.transactions);

            // Create the base query
            let q = query(
                transactionsCollection,
                where("payeeKey", "==", userKey),
                where("dateCreated", ">=", startDate)
            );

            // Add app filter if a specific app is selected
            if (targetApp !== "ALL") {
                q = query(q, where("appKey", "==", targetApp));
            }

            // Execute the query and get a snapshot of the results
            const transactionsSnapshot = await getDocs(q);

            // Map the documents to an array of data
            const transactions = transactionsSnapshot.docs.map(doc => doc.data());

            // Return the array of transactions
            return transactions;
        } catch (error) {
            // Log and rethrow any errors encountered
            console.error("Error fetching transactions:", error);
            throw error;
        }
    }

}

export default TransactionManager;
