import React from 'react';

// Styles
import './EditedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const EditedHeadline = ({ headline, handleUserClick, handleObjectClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;set the {headline.fieldTitle} of&nbsp;<span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}>
                {headline.objectTitle}
            </span>&nbsp;to "{headline.value}".&nbsp;
            {timestamp}
        </>
    );
};

export default EditedHeadline;
