import React, { useEffect, useState } from 'react';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './Time.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import Select from '../../../../foundation/select/Select';

const Time = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    // Theme
    const { theme } = useTheme();

    // State Variables
    const [value, setValue] = useState(''); // Default ADD mode value
    const [options, setOptions] = useState([]);

    /**
     * Generates times in 5-minute increments.
     * 
     * @param {date} date - Formats the time.
     */
    const generateTimes = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const valueHour = hour.toString().padStart(2, '0');
                const valueMinute = minute.toString().padStart(2, '0');
                const timeValue = `${valueHour}:${valueMinute}`; // Value in 24-hour format

                const displayHour = hour % 12 === 0 ? 12 : hour % 12;
                const amPm = hour < 12 ? 'AM' : 'PM';
                const timeDisplay = `${displayHour}:${valueMinute} ${amPm}`; // Display in 12-hour format

                times.push({ value: timeValue, label: timeDisplay });
            }
        }
        return times;
    };

    /**
     * Initialize the field value and sets the options list.
     */
    useEffect(() => {
        if (!object) {
            setValue('');
            return;
        };
        const fieldTimestamp = object[field.key];
        const formattedTime = fieldTimestamp ? formatTime(fieldTimestamp.toDate()) : '';
        setValue(formattedTime);

        // Generate the options
        setOptions(generateTimes());
    }, [object, field.key]);

    /**
     * Method to format a date object into a HH:MM time string
     * 
     * @param {date} date - Formats the time.
     */
    const formatTime = (date) => {
        if (!date) return '';
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    /**
     * Method to handle the change in value.
     * 
     * @param {string} val - New value.
     */
    const handleChange = (val) => {
        setValue(val);

        const [hours, minutes] = val.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0); // Set hours, minutes, and reset seconds and milliseconds
        const timestamp = Timestamp.fromDate(date);
        onUpdate(field, timestamp);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                readOnly={readOnly}
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* READ-ONLY TEXT */}
                {readOnly ? (
                    <div className='time-value'
                        style={{ color: theme.foregroundColor }}>
                        {options.find(option => option.value === value)?.label}
                    </div>
                ) : (
                    <>
                        {/* INPUT */}
                        <Select
                            options={options}
                            value={value}
                            onChange={(value) => handleChange(value)}
                        />
                    </>
                )}

            </FieldContainer>
        </>
    );
};

export default Time;
