import React from 'react';

// Styles
import './ThemeToggle.css';

// Themes
import { useTheme } from '../ThemeContext';

const ThemeToggle = () => {
    const { currentTheme, setCurrentTheme, theme } = useTheme();

    const toggleTheme = () => {
        setCurrentTheme(prevTheme => (prevTheme === 'DARK' ? 'LIGHT' : 'DARK'));
    };

    return (
        <div className="theme-toggle-container" onClick={toggleTheme}>

            <div
                className="theme-toggle-left"
                style={{ borderColor: currentTheme === "LIGHT" ? theme.highlightBackgroundColor : "white" }}
            >

            </div>
            <div
                className="theme-toggle-right"
                style={{ borderColor: currentTheme === "DARK" ? theme.highlightBackgroundColor : "black" }}
            >
            </div>
        </div>
    );
};

export default ThemeToggle;
