import React from 'react';

// Styles
import './FollowButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import FollowIcon from '../../../../svg/FollowIcon';

const FollowButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="follow-button-container">
            <FollowIcon
                color={theme.foregroundColor}
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default FollowButton;
