import React, { useRef, useState, useEffect } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ObjectChecklistNoteForm.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Modal from '../../../../foundation/Modal';

const ObjectChecklistNoteForm = ({
    modalOpen,
    setModalOpen,
    item,
    onSave
}) => {
    const { theme } = useTheme();

    // State Variables
    const [itemNote, setItemNote] = useState('');

    // References
    const inputRef = useRef(null);

    // Effect to load existing note into the state when the modal opens
    useEffect(() => {
        if (modalOpen && item && item.note) {
            setItemNote(item.note);
        }
    }, [modalOpen, item]);

    /**
     * Method to add a note to a checklist item and save it.
     */
    const handleSave = () => {
        if (inputRef.current && item) {
            // Save the note in item.note
            const updatedItem = { ...item, note: itemNote };

            // Call onSave with the updated item
            onSave(updatedItem);

            // Close the modal after saving
            setModalOpen(false);
        }
    }

    return (
        <Modal title={modalOpen && item.objectTitle} width={isMobile ? "90%" : "350px"} isOpen={modalOpen} onClose={() => setModalOpen(false)}>

            {/* NOTE FIELD */}
            <div className="object-checklist-note-form-label"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                Note
            </div>

            {/* INPUT */}
            <textarea
                ref={inputRef}
                className="object-checklist-note-form-instructions-input"
                value={itemNote}
                onChange={(e) => setItemNote(e.target.value)}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}
                maxLength="250"
            ></textarea>

            {/* SAVE BUTTON */}
            <div className="modal-buttons">
                <button onClick={handleSave} className="modal-button"
                    style={{ color: theme.foregroundColor }}>
                    Save
                </button>
            </div>
        </Modal>
    );
};

export default ObjectChecklistNoteForm;
