import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './UserButton.css';

// Device Detection
import { isMobile } from 'react-device-detect';

// Components
import Account from '../run/account/Account';
import UserDot from '../foundation/UserDot';

const UserButton = () => {
  const {
    accountAboutVisible,
    currentUser,
    resetVisibility,
    setAccountVisible,
    setUserAccountOpen,
    userAccountOpen
  } = useContext(Global);

  const handleClick = async () => {
    if (!isMobile) {
      setUserAccountOpen(true);
    } else {
      resetVisibility();
      setAccountVisible(true);
    }
  };

  return (
    <>
      <div
        onClick={handleClick}>
        {currentUser &&
          <UserDot
            //firstInitial={currentUser.username.toUpperCase()[0]}
            firstInitial="K"
            size={24}
          />
        }
      </div>

      <Account />
    </>
  );
};

export default UserButton;

