import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Activity
import { activity } from '../../managers/ActivityManager';

// Styles
import './AccountAboutPhoto.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AccountAboutPhoto = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        hideProgress,
        selectedApp,
        setCurrentUser,
        showProgress
    } = useContext(Global);

    const handleFileUpload = async (event) => {
        showProgress("Uploading...");

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop(); // Extract the file extension

        // Log the size of the original file in bytes
        console.log(`Original file size: ${file.size} bytes`);

        const image = document.createElement('img');
        image.onload = async () => {
            // Create canvas
            const maxDimension = 1000;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const ratio = Math.min(maxDimension / image.width, maxDimension / image.height);
            const width = image.width * ratio;
            const height = image.height * ratio;

            canvas.width = width;
            canvas.height = height;

            // Draw the image scaled down
            ctx.drawImage(image, 0, 0, width, height);

            // Convert canvas to blob
            canvas.toBlob(async (blob) => {
                // Log the size of the blob in bytes
                console.log(`Uploading: ${blob.size} bytes`);

                activity.log(selectedApp.key, 'uploads', blob.size);

                // Reference to the folder where the user profile photos are stored
                const userPhotoFolderRef = ref(storage, `users/profile/`);

                try {

                    // Upload the new file
                    const storageRef = ref(storage, `users/profile/${currentUser.key}.${fileExtension}`); // Use the original extension
                    await uploadBytes(storageRef, blob); // Upload the blob
                    const fileUrl = await getDownloadURL(storageRef); // Get the URL
                    setCurrentUser({ ...currentUser, photo: fileUrl });

                    const data = { photo: fileUrl };
                    await updateDoc(doc(db, collections.users, currentUser.key), data);

                    hideProgress();
                    
                } catch (error) {
                    console.error("Error handling user photo upload:", error);
                    hideProgress();
                }
            }, `image/${fileExtension}`); // Specify the correct MIME type
        };

        image.src = URL.createObjectURL(file);
    };

    return (
        <div className="account-about-photo-container"
            style={{
                borderColor: theme.backgroundColor
            }}
            >
            <label htmlFor="account-about-photo-upload" className="account-about-photo-wrapper">
                {currentUser && currentUser.photo &&
                    <img
                        src={currentUser.photo}
                        className="account-about-photo"
                        alt="User"
                    />
                }
            </label>
            <input id="account-about-photo-upload"
                type="file"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default AccountAboutPhoto;

