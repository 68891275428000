import React, { useState, useEffect, useRef } from 'react';

// Styles
import './TextSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Styled Components
const InlineStyles = createGlobalStyle`

    .gradient-overlay::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        width: 100%;
        background: linear-gradient(to top, ${(props) => props.gradientColor}, transparent);
        pointer-events: none; /* Allow clicks to pass through */
    }
    
`;

const TextSummary = ({ element, object }) => {
    const { theme, getProcessedStyles } = useTheme();
    const [showGradient, setShowGradient] = useState(false);
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    useEffect(() => {
        const container = containerRef.current;

        // Check if container height is greater than 50px and set gradient accordingly
        if (container && container.offsetHeight > 50) {
            setShowGradient(true);
        } else {
            setShowGradient(false);
        }
    }, [object, element]);

    return (
        <>
            <InlineStyles
                gradientColor={theme.backgroundColor}
            />
            <div
                className={`text-summary-container ${showGradient ? 'gradient-overlay' : ''}`}
                ref={containerRef}
                style={{ ...processed, height }}>
                {object[element.field.key]}
            </div>
        </>
    );
};

export default TextSummary;
