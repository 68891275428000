import React from 'react';

// Styles
import './GalleryHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Gallery from './Gallery';
import RunHomeComments from '../RunHomeComments';

const GalleryHeadline = ({ headline, handleObjectClick, handleUserClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <div>
            <div>
                <span
                    className="run-home-token"
                    style={{ color: theme.foregroundColor }}
                    onClick={() => handleUserClick(headline.userKey)}>
                    {headline.userFirstName} {headline.userLastName}
                </span>&nbsp;
                added {headline.photoUrls.length}
                {headline.photoUrls.length === 1 ? (
                    <> photo </>
                ) : (
                    <> photos </>
                )}
                to <i>{headline.fieldTitle}</i> in&nbsp;
                <span
                    className="run-home-token"
                    style={{ color: theme.foregroundColor }}
                    onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}>
                    {headline.objectTitle}
                </span>.&nbsp;
            </div>
            <div className="gallery-headline-wrapper"
                onClick={() => handleObjectClick(headline.modelKey, headline.objectKey)}
                style={{
                    backgroundColor: theme.backgroundColor
                }}>
                <Gallery headline={headline} />
            </div>
            {timestamp}
            <RunHomeComments 
                headline={headline}
            />
        </div>
    );
};

export default GalleryHeadline;
