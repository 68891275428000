import React from 'react';

const EditIcon = ({ color = "white", width = "256", height = "256" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none" />
    <circle cx="128" cy="128" r="96" fill="none" stroke={color} strokeWidth="16" />
    <circle cx="128" cy="128" r="12" fill={color} />
    <circle cx="172" cy="128" r="12" fill={color} />
    <circle cx="84" cy="128" r="12" fill={color} />
  </svg>
);

export default EditIcon;
