// Firebase
import { collection, deleteDoc, doc, orderBy, onSnapshot, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../firebaseConfig';

// Activity
import { activity } from '../managers/ActivityManager';

class FieldManager {

    /**
     * Method to add a new field.
     * 
     * @param {string} appKey - App key.
     * @param {string} key - key for the new field.
     * @param {string} modelKey - Model to add an field for.
     * @param {string} title - Title of the field.
     * @param {string} type - Type of field.
     * @param {string} sort - Sort position in lists.
     * @param {string} targetModelKey - Defines a relationship with another collection.
     * @returns {object} - New field.
    */
    async add(appKey, key, modelKey, data) {

        // Current timestamp
        const now = Timestamp.now();

        const field = {
            key: key,
            appKey: appKey,
            modelKey: modelKey,
            dateCreated: now,
            dateModified: now,
            ...data
        };

        await setDoc(doc(db, collections.fields, key), field);

        activity.log(appKey, 'writes', 1);

        return field;
    }

    /**
     * Deletes a field from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} fieldKey - The key of the field to delete.
     */
    async delete(appKey, fieldKey) {
        try {
            // Delete the selected field from Firestore
            await deleteDoc(doc(db, collections.fields, fieldKey));

            // Log the delete activity
            activity.log(appKey, 'deletes', 1);

            // Return success message
            return { success: true, message: "Field deleted and summaries updated successfully." };

        } catch (error) {
            console.error('Error deleting field:', error);
            // Return an error message
            return { success: false, message: "Error deleting field." };
        }
    }

    /**
      * Fetches fields and subscribes to real-time updates.
      * 
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(appKey, modelKey, onUpdate) {
        try {

            // Create a reference to the fields collection
            const fieldsCollection = collection(db, collections.fields);

            // Create a query to fetch fields for the selected model
            const q = query(fieldsCollection, where("modelKey", "==", modelKey), orderBy("sort"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q,
                snapshot => {
                    let fieldList = snapshot.docs.map(doc => ({
                        id: doc.id, // Include the document ID if needed
                        ...doc.data() // Spread operator to include all fields from the document
                    }));

                    // Call the onUpdate callback with the updated list
                    if (onUpdate) {
                        activity.log(appKey, 'reads', fieldList.length);
                        onUpdate(fieldList);
                    }
                },
                error => {
                    console.error("Error fetching fields:", error);
                }
            );

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
      * Fetches fields by app key and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAppFieldsAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the models collection
            const fieldsCollection = collection(db, collections.fields);

            // Create a query to find models by appKey and sort them by title
            const q = query(fieldsCollection, where("appKey", "==", appKey), orderBy("title"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const fieldList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(fieldList);
                    activity.log(appKey, 'reads', fieldList.length);
                }
            }, error => {
                console.error("Error fetching app fields:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
     * Updates a field's data in the Firestore database.
     *
     * @param {string} appKey - App key.
     * @param {string} fieldKey - The key (document ID) of the field to update.
     * @param {object} data - An object containing the field properties and their new values.
     */
    async update(appKey, fieldKey, data) {
        try {
            const now = Timestamp.now();

            // Ensure dateModified is always updated to the current timestamp
            data.dateModified = now;

            // Update the document in Firestore
            await updateDoc(doc(db, collections.fields, fieldKey), data);

            // Log the activity - assuming an activity logging function exists
            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Field updated successfully." };
        } catch (error) {
            console.error('Error updating field:', error);
            // Return an error message
            return { success: false, message: "" };
        }
    }

}

export default FieldManager;
