import React from 'react';

// Styles
import './ImagePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import nature from '../../../../images/nature.jpg';

const ImagePreview = ({ 
    placeholder = '', 
    selected = false,
    size 
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="image-preview-container">
            <div className="image-preview-wrapper"
                style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
                <img
                    src={nature}
                    alt="Nature"
                    className="image-preview-image"
                />
            </div>
        </div>
    );
};

export default ImagePreview;
