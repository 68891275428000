import React from 'react';
// Styles
import './EventAddedHeadline.css';
// Theme
import { useTheme } from '../../../ThemeContext';

const EventAddedHeadline = ({ headline, handleUserClick, handleEventClick, timestamp }) => {
    const { theme } = useTheme();

    // Function to format date and time
    const formatDateTime = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore timestamp to JS Date
        const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
        const dateOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
        
        const timeString = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
        const dateString = date.toLocaleDateString('en-US', dateOptions);
        
        return { timeString, dateString };
    };

    // Extracting and formatting event start and end times
    const { eventStartDate, eventEndDate } = headline;
    const { timeString: startTime, dateString: startDate } = formatDateTime(eventStartDate);
    const { timeString: endTime, dateString: endDate } = formatDateTime(eventEndDate);

    const isSameDay = startDate === endDate;

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;added&nbsp;<span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleEventClick(headline.eventKey)}>
                {headline.eventTitle}
            </span>&nbsp;to the calendar&nbsp;
            {isSameDay ? (
                <>from {startTime} to {endTime} on {startDate}</>
            ) : (
                <>from {startTime} on {startDate} to {endTime} on {endDate}</>
            )}.&nbsp;
            {timestamp}
        </>
    );
};

export default EventAddedHeadline;
