import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './AccountUsername.css';

// Theme
import { useTheme } from '../ThemeContext';

// Managers
import UserManager from '../managers/UserManager';
const userManager = new UserManager();

/**
 * AccountUsername Component
 * 
 * This component renders an input for a user's username.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountUsername = ({ onChange, defaultValue = '' }) => {
  const { theme } = useTheme();
  const {
    currentUser,
    selectedApp,
    studio
  } = useContext(Global);

  // State Variables
  const [usernameError, setUsernameError] = useState('');
  const [value, setValue] = useState('');

  /**
   * Initializes the field value.
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * Method to handle username input change.
   * 
   * @param {Event} e - Change event
   */
  const handleUsernameChange = async (e) => {
    const username = e.target.value.trim(); // Trim any unnecessary spaces

    onChange(username, false); // Assume invalid initially

    // Validate username presence
    if (!username) {
      setUsernameError('A valid username is required.');
      return;
    }

    // Validate username length (3 to 20 characters)
    if (username.length < 3 || username.length > 20) {
      setUsernameError('Username must be between 3 and 20 characters.');
      return;
    }

    // Validate username character set (alphanumeric, underscore, hyphen, period)
    const usernameRegex = /^[a-zA-Z0-9_.-]{3,20}$/;
    if (!usernameRegex.test(username)) {
      setUsernameError('Username can only contain letters, numbers, underscores, hyphens, and periods.');
      return;
    }

    // Check if username already exists, and if the currentUser
    // exists, exclude the current user from the check
    const exists = await userManager.usernameExists(username);
    if (exists && (!currentUser || currentUser.username !== username)) {
      setUsernameError('An account with this username already exists.');
      return;
    }

    // If we have a valid username and it doesn't exist, clear the error
    setUsernameError('');

    // Let the parent know the username is valid
    onChange(username, true);
  };

  return (
    <>

      {/* CONTAINER */}
      <div className="account-username-container">

        {/* USERNAME INPUT */}
        <input
          type="text"
          className="account-username-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          value={value}
          onChange={handleUsernameChange}
          placeholder="Username"
          required
        />

        {/* ERROR MESSAGE */}
        {usernameError && (
          <div
            className="account-username-error"
            style={{
              color: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
            }}>
            {usernameError}
          </div>
        )}
      </div>

    </>
  );
};

export default AccountUsername;
