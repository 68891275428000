import React, { useContext, useEffect, useState } from 'react';

// Styles
import './CalendarMonth.css';

// Managers
import EventManager from '../../managers/EventManager';
import ObjectManager from '../../managers/ObjectManager';

// Global context and styles
import { Global } from '../../Global';

const CalendarMonth = () => {
    const {
        activeDate,
        events,
        selectedApp,
        setBackToCalendar,
        setCalendarVisible,
        setSelectedObject
    } = useContext(Global);

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const getMonthDays = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1).getDay();
    };

    const daysInMonth = getMonthDays(currentYear, currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);
    const daysInPreviousMonth = getMonthDays(currentYear, currentMonth - 1);

    const [monthEvents, setMonthEvents] = useState({});

    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const days = [];
    // Previous month's days
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        days.push({
            day: daysInPreviousMonth - i,
            month: 'prev',
        });
    }
    // Current month's days
    for (let i = 1; i <= daysInMonth; i++) {
        days.push({
            day: i,
            month: 'current',
        });
    }
    // Next month's days to fill the week
    let nextMonthDays = 1;
    while (days.length % 7 !== 0) {
        days.push({
            day: nextMonthDays++,
            month: 'next',
        });
    }

    // Gather events
    useEffect(() => {
        if (activeDate) {
            const firstDayOfMonth = new Date(activeDate.getFullYear(), activeDate.getMonth(), 1);
            const lastDayOfMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0);

            const eventManager = new EventManager();
            const eventsBetweenDates = eventManager.getEventsBetweenDates(events, firstDayOfMonth, lastDayOfMonth);

            // Transmodel the fetched events into a map keyed by day of the month
            const eventsByDay = eventsBetweenDates.reduce((acc, event) => {
                const day = event.startDate.toDate().getDate();
                if (!acc[day]) {
                    acc[day] = [];
                }
                acc[day].push(event);
                return acc;
            }, {});

            setMonthEvents(eventsByDay);
        }
    }, [selectedApp, activeDate, events]);

    const handleEventClick = (event, modelKey, objectKey) => {
        event.stopPropagation();
        const objectManager = new ObjectManager();
        objectManager.fetch(selectedApp.key, modelKey, objectKey).then(fetchedObject => {
            setCalendarVisible(false);
            setBackToCalendar(true);
            setSelectedObject(fetchedObject);
        }).catch(error => {
            console.error("Failed to fetch object:", error);
            setSelectedObject(null);
        });
    }

    const handleDayClick = (day, month) => {
        const monthOffset = month === 'prev' ? -1 : month === 'next' ? 1 : 0;
        const date = new Date(currentYear, currentMonth + monthOffset, day);
        alert(date.toDateString());
    };

    return (
        <div className="calendar-month-container">
            <div className="calendar-month-header">
                {daysOfWeek.map((day, index) => (
                    <div key={index} className="calendar-month-day">{day}</div>
                ))}
            </div>
            <div className="calendar-month-days">
                {days.map((day, index) => (
                    <div key={index} className={`calendar-month-cell ${day.month}`} onClick={() => handleDayClick(day.day, day.month)}>
                        <div className="calendar-month-day-header">
                            {day.day}
                        </div>
                        <div className="calendar-month-day-events">
                            {monthEvents[day.day] && monthEvents[day.day].map((monthEvent, idx) => (
                                <div key={idx} className="event">
                                    <div
                                        key={index}
                                        className="calendar-month-event"
                                        onClick={(event) => handleEventClick(event, monthEvent.modelKey, monthEvent.objectKey)}
                                    >
                                        <div className="calendar-month-event-object">
                                            {monthEvent.objectTitle}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default CalendarMonth;
