import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './BackButton.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import BackIcon from '../svg/BackIcon';

const BackButton = () => {
  const { theme } = useTheme();
  const { 
    setCalendarVisible,
    setSelectedObject, 
    backToCalendar
  } = useContext(Global);

  /**
   * BACK BUTTON CLICK
   */
  const handleBack = () => {
    if (backToCalendar) {
      setCalendarVisible(true);
      setSelectedObject(null);
    } 

    setSelectedObject(null);
  }

  return (
    <>
      <div className="back-button-container" onClick={handleBack}>
        <BackIcon 
          color={theme.foregroundColor} 
          width="16" 
          height="16" 
        />
      </div>
    </>
  );
};

export default BackButton;
