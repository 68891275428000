import React, { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppUsers.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppUsersToolBar from './AppUsersToolBar';
import AppUserInviteButton from './AppUserInviteButton';
import AppUserSummary from './AppUserSummary';

// Managers
import AppUserManager from '../../managers/AppUserManager';

const appUserManager = new AppUserManager();

const AppUsers = () => {
    const { theme } = useTheme();
    const {
        appUsers,
        selectedApp,
        setAppUsers,
        setSelectedAppUser,
    } = useContext(Global);

    useEffect(() => {
        async function fetchAppUsers() {
            try {
                const appUsers = await appUserManager.fetchAppUsers(selectedApp.key);
                setAppUsers(appUsers);
            } catch (error) {
                console.error('Failed to fetch app appUsers:', error);
            }
        }
        if (selectedApp && selectedApp.key) {
            fetchAppUsers();
        }
    }, [selectedApp, setAppUsers]);

    const handleUserClick = (appUser) => {
        setSelectedAppUser(appUser);
    };

    return (
        <>
            <div className="app-users-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                }}
            >
                <AppUsersToolBar />
                <div className="app-users-list">
                    {appUsers.map((appUser, index) => (
                        <div className="app-users-user-wrapper"
                            key={appUser.key}
                            onClick={() => handleUserClick(appUser)}>
                            <AppUserSummary
                                appUser={appUser}
                            />
                        </div>
                    ))}
                </div>
                <div className="app-users-add-button">
                    <AppUserInviteButton />
                </div>
            </div>
            {/**
                {currentUser.key == selectedApp.userKey && currentUser.key !== appUser.userKey &&
                    <AppUserMenu
                        appUserKey={appUser.key}
                        handleDelete={() => handleDelete(appUser.key)}
                    />
                }
            */}
        </>
    );
};

export default AppUsers;
