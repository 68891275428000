import React from 'react';

// Styles
import './AppSettingsIcon.css';

// Components
import AppLogoEdit from '../../run/logo/AppLogoEdit';

const AppSettingsIcon = ({ themes }) => {

    return (
        <div className="app-settings-icon-container">
            <div className="app-settings-icon-column">
                <AppLogoEdit 
                    title="Dark Mode"
                    backgroundColor={themes.dark.backgroundColor}
                    borderColor={themes.dark.backgroundColorFaded}
                    mode="DARK"
                />
            </div>
            <div className="app-settings-icon-column">
                <AppLogoEdit 
                    title="Light Mode"
                    backgroundColor={themes.light.backgroundColor}
                    borderColor={themes.light.backgroundColorFaded}
                    mode="LIGHT"
                />
            </div>
        </div>
    );
};

export default AppSettingsIcon;
