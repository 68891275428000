import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Activity
import { activity } from '../../managers/ActivityManager';

// Styles
import './AccountAbout.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AccountAboutPhoto from './AccountAboutPhoto';
import AccountAboutSaveButton from './AccountAboutSaveButton';
import AccountForm from '../AccountForm';

// Managers
import AppUserManager from '../../managers/AppUserManager';
import UserIndexManager from '../../managers/UserIndexManager';
import UserManager from '../../managers/UserManager';

const appUserManager = new AppUserManager();
const userIndexManager = new UserIndexManager();
const userManager = new UserManager();

const AccountAbout = ({ setOpen }) => {
    const { theme } = useTheme();
    const {
        currentUser,
        selectedApp,
        setAppUsers,
        setCurrentUser
    } = useContext(Global);

    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');


    // State Variables
    const [defaultEmail, setDefaultEmail] = useState(''); // The email from the invite, if any
    const [email, setEmail] = useState(''); // The editable email field
    const [username, setUsername] = useState(''); // Username (required)
    const [fullName, setFullName] = useState(''); // Full Name (optionally required)
    const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
    const [handle, setHandle] = useState(''); // Handle (optionally required)
    const [password, setPassword] = useState(''); // Password (required)

    // Validation States
    const [formValid, setFormValid] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleSubmit = async () => {

        // Update the user's information in the database
        const data = {
            username: username,
            fullName: fullName,
            displayName: displayName,
            handle: handle
        }

        // Update the user's information in the database
        await userManager.update(selectedApp.key, currentUser.key, data);

        // Update the user's information in the appusers collection
        //await appUserManager.updateName(selectedApp.key, currentUser.key, newFirstName, newLastName);

        // Update the user's information in the search index
        //await userIndexManager.update(selectedApp.key, currentUser.key, newFirstName + " " + newLastName);

        // Update the current user state
        setCurrentUser(prev => ({ ...prev, ...data }));

        // Update the global appUsers state array
        setAppUsers(prevAppUsers => prevAppUsers.map(appUser =>
            appUser.userKey === currentUser.key
                ? { ...appUser, userFirstName: newFirstName, userLastName: newLastName }
                : appUser
        ));

        // Log the activity
        activity.log(selectedApp.key, 'writes', 1);
    };

    /**
     * Method to handle submit of the signup form.
     * 
     * @param {string} e - Click event.
     */
    const handleFormChange = async (
        username,
        email,
        fullName,
        displayName,
        handle,
        password,
        isValid
    ) => {
        setUsername(username);
        setEmail(email);
        setFullName(fullName);
        setDisplayName(displayName);
        setHandle(handle);
        setPassword(password);
        setFormValid(isValid);
    };

    /**
     * Enable the submit button only if all required fields are valid.
     */
    useEffect(() => {
      setSubmitEnabled(formValid);
    }, [formValid]);

    return (

        <div className="account-about-container">

            <div className="account-about-top-section">
                <AccountAboutPhoto />
            </div>
            <div className="account-about-form">

                {/* FORM */}
                <AccountForm
                    onChange={handleFormChange}
                    user={currentUser}
                    requirePassword={false}
                    showLabels={true}
                />

                {/* SUBMIT */}
                <AccountAboutSaveButton onClick={handleSubmit} enabled={submitEnabled} />

            </div>

        </div>
    );
};

export default AccountAbout;
