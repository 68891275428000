import React from 'react';

// Components
import ColorSelector from '../ColorSelector';

// Styles
import './AppSettingsThemeEditor.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppSettingsThemeEditor = ({
    title,
    adjustTheme,
    onThemeChange
}) => {
    const { theme } = useTheme();

    const handleColorChange = (key, color) => {
        onThemeChange({ ...adjustTheme, [key]: color });
    };

    const themeOptions = [
        { key: 'backgroundColor', label: 'Background Color' },
        { key: 'backgroundColorFaded', label: 'Background Color (Faded)' },
        { key: 'foregroundColor', label: 'Foreground Color' },
        { key: 'foregroundColorFaded', label: 'Foreground Color (Faded)' },
        { key: 'highlightBackgroundColor', label: 'Highlight Background Color' },
        { key: 'highlightForegroundColor', label: 'Highlight Foreground Color' }
    ];

    return (
        <div className="app-settings-theme-editor-container">
            <div 
                className="app-settings-theme-editor-title" 
                style={{color: theme.foregroundColorFaded}}
                >
                {title}
            </div>
            <br />
            {themeOptions.map(option => (
                <div key={option.key} className="app-settings-color">
                    <ColorSelector
                        color={adjustTheme && adjustTheme[option.key]}
                        onUpdate={color => handleColorChange(option.key, color)}
                    />
                    <div 
                        className="app-settings-theme-editor-label" 
                        style={{color: theme.foregroundColor}}
                    >
                        {option.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AppSettingsThemeEditor;
