import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './ChannelItemAdd.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../../foundation/Modal';

// Managers
import ChannelManager from '../../../managers/ChannelManager';

const channelManager = new ChannelManager();

/**
 * ChannelItemAdd Component
 * 
 * This component allows the user to add a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelItemAdd = () => {
    const { theme } = useTheme();
    const {
        channelItemAddOpen,
        channels,
        currentUser,
        hideProgress,
        selectedApp,
        setChannelItemAddOpen,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');

    // References
    const titleInputRef = useRef(null);

    // Focus the title field on channel load
    useEffect(() => {
        if (channelItemAddOpen) {
            titleInputRef.current.focus();
        }
    }, [channelItemAddOpen]);

    /**
     * Handles the add of a channel.
     * 
     * @param {string} e - Click event.
     */
    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a channel title.');
            return;
        }

        showProgress("Adding channel...");

        setChannelItemAddOpen(false);

        const userKey = currentUser.key;
        const userFirstName = currentUser.firstName;
        const userLastName = currentUser.lastName;
        const appKey = selectedApp.key;

        const newChannelKey = generateKey();  // Generate key for the new channel

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each channel to find the highest sort number
        channels.forEach(channel => {
            const sortNumber = channel.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const channelData = {
            key: newChannelKey,
            appKey: appKey,
            description: "",
            userKey: userKey,
            userFirstName: userFirstName,
            userLastName: userLastName,
            title: title,
            sort: highestSortNumber + 1,
            dateCreated: now,
            dateModified: now,
        };

        await channelManager.add(appKey, newChannelKey, channelData);

        setTitle('');

        setStatusMessage("Channel created successfully.");

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Channel"
                isOpen={channelItemAddOpen}
                onClose={() => setChannelItemAddOpen(false)}
                width="250px">

                {/* LABEL */}
                <div className="channel-item-add-label"
                    style={{ color: theme.foregroundColor }}>
                    Title
                </div>

                {/* INPUT */}
                <input
                    type="text"
                    name="title"
                    className="channel-item-add-input"
                    style={{
                        color: theme.foregroundColor,
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        fontFamily: selectedApp.fontFamily
                    }}
                    value={title}
                    maxLength="18"
                    onChange={e => setTitle(e.target.value)}
                    ref={titleInputRef}
                />

                {/* BUTTONS */}
                <div className="channel-item-add-buttons">
                    <button
                        type="button"
                        onClick={handleAdd}
                        className="channel-item-add-button"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        Add
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ChannelItemAdd;
