import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { generateKey } from '../utilities/Keys';

// Styles
import './Pay.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
//import MonthYear from '../run/form/fields/MonthYear';
import PayHeader from './PayHeader';
import States from '../run/form/fields/states/States';
import Text from '../run/form/fields/text/Text';

// Managers
import TransactionManager from '../managers/TransactionManager';

const transactionManager = new TransactionManager();

const Pay = () => {
  const { theme } = useTheme();
  const {
    currentUser,
    payAmount,
    payMode,
    selectedApp,
  } = useContext(Global);

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvvNumber, setCVVNumber] = useState('');

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const loadPaymentMethods = async () => {
      const paymentMethods = await transactionManager.fetchPaymentMethodByUserKey(currentUser.key);
      if (paymentMethods.length > 0) {
        const paymentMethod = paymentMethods[0]; // Use the first payment method found
        setAddress(paymentMethod.cardAddress);
        setCity(paymentMethod.cardCity);
        setState(paymentMethod.cardState);
        setZip(paymentMethod.cardZip);
        setNameOnCard(paymentMethod.nameOnCard);
        setCardNumber(paymentMethod.cardNumber);
        setExpirationDate(paymentMethod.cardExpiry);
      }
    };

    setIsVisible(true);
    loadPaymentMethods();
  }, [currentUser.key]);

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleCityChange = (city) => {
    setCity(city);
  };

  const handleStateChange = (state) => {
    setState(state);
  };

  const handleZipChange = (zip) => {
    setZip(zip);
  };

  const handleNameOnCardChange = (nameOnCard) => {
    setNameOnCard(nameOnCard);
  };

  const handleCardNumberChange = (cardNumber) => {
    setCardNumber(cardNumber);
  };

  const handleCVVNumberChange = (cvvNumber) => {
    setCVVNumber(cvvNumber);
  };

  const handleBlur = async () => {
    return false;
  };

  const handlePay = async () => {
    const sanitizedCardNumber = cardNumber.replace(/\s+/g, '');
    const dateCreated = new Date();
    const dateModified = dateCreated;

    // Check if the payment method already exists
    const existingPaymentMethod = await transactionManager.fetchPaymentMethodByCardNumber(sanitizedCardNumber);
    const paymethodKey = existingPaymentMethod ? existingPaymentMethod.key : generateKey();

    // Create billing info
    const paymethodInfo = {
      key: paymethodKey,
      userKey: currentUser.key,
      nameOnCard,
      cardNumber: sanitizedCardNumber,
      cardExpiry: expirationDate,
      cardAddress: address,
      cardCity: city,
      cardState: state,
      cardZip: zip,
      dateCreated,
      dateModified,
    };

    try {
      // Save payment method information if it's a new one
      if (!existingPaymentMethod) {
        await transactionManager.addPaymentMethod(paymethodKey, paymethodInfo);
      }

      if (payMode === 'PAYONCE' || payMode === 'DONATE') {
        // Record transaction
        const transactionKey = generateKey();
        const transaction = {
          key: transactionKey,
          userKey: currentUser.key,
          userEmail: currentUser.email,
          appKey: selectedApp.key,
          appTitle: selectedApp.title,
          appVersion: selectedApp.version,
          payeeKey: selectedApp.userKey,
          payMode,
          paymethodKey,
          amount: payAmount,
          dateCreated,
          dateModified,
        };

        await transactionManager.addTransaction(transactionKey, transaction);

      } else if (payMode === 'SUBSCRIBE') {
        // Record subscription
        const subscriptionKey = generateKey();
        const subscription = {
          key: subscriptionKey,
          appKey: selectedApp.key,
          userKey: currentUser.key,
          paymethodKey,
          subscriptionAmount: payAmount,
          dateStarted: dateCreated,
          dateCreated,
          dateModified,
        };

        await transactionManager.addSubscription(subscriptionKey, subscription);

      }

    } catch (error) {
      console.error('Error processing payment: ', error);
      alert('There was an error processing your payment. Please try again.');
    }
  };

  const formatCurrency = (value) => {
    return value ? parseFloat(value).toFixed(2) : '';
  };

  return (
    <div 
      className={`pay-overlay ${isVisible ? 'visible' : ''}`}
      style={{
        backgroundColor: theme.backgroundColor,
      }}
    >
      <div className="pay-container">
        <PayHeader />
        <div className="pay-amount"
          style={{color: theme.foregroundColor}}
        >
          ${formatCurrency(payAmount)}
          {payMode === "SUBSCRIBE" &&
            <>
              /Month
            </>
          }
        </div>
        <div className="pay-recipient"
          style={{color: theme.foregroundColorFaded}}
        >
          to {selectedApp.userFirstName} {selectedApp.userLastName}
        </div>
        <div className="pay-model-container">
          <div className="pay-model-label"
            style={{color: theme.foregroundColor}}
          >
            Billing Address
          </div>
          <Text
            placeholder="Street"
            defaultValue={address}
            onFieldChange={handleAddressChange}
            onFieldBlur={handleBlur}
          />
          <Text
            placeholder="City"
            defaultValue={city}
            onFieldChange={handleCityChange}
            onFieldBlur={handleBlur}
          />
          <States
            placeholder="State"
            defaultValue={state}
            onFieldChange={handleStateChange}
            onFieldBlur={handleBlur}
          />
          <Text
            placeholder="Zip/Postal Code"
            defaultValue={zip}
            onFieldChange={handleZipChange}
            onFieldBlur={handleBlur}
          />
          <div className="pay-model-label">Card Information</div>
          <Text
            placeholder="Name On Card"
            defaultValue={nameOnCard}
            onFieldChange={handleNameOnCardChange}
            onFieldBlur={handleBlur}
          />
          <Text
            placeholder="Card Number"
            defaultValue={cardNumber}
            onFieldChange={handleCardNumberChange}
            onFieldBlur={handleBlur}
          />
          <div className="pay-model-card-container">
            <div className="pay-model-card-left">
              {/**
              <MonthYear
                placeholder="Expiration Date (MM/DD)"
                defaultValue={expirationDate}
                onFieldChange={handleExpirationDateChange}
                onFieldBlur={handleBlur}
              />
               */}
            </div>
            <div className="pay-model-card-right">
              <Text
                placeholder="CVV"
                defaultValue={cvvNumber}
                onFieldChange={handleCVVNumberChange}
                onFieldBlur={handleBlur}
              />
            </div>
          </div>
          <div className="pay-button-wrapper">
            <button type="button"
              onClick={handlePay}
              className="pay-button">
              {payMode === "SUBSCRIBE" ? (
                <>
                  Subscribe
                </>
              ) : (
                <>
                  Pay Now
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;
