import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './TitleBar.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import SettingsIcon from '../svg/SettingsIcon';

// Components
import AppSettings from '../foundation/appsettings/AppSettings';
import Login from '../welcome/login/Login';
import LogoSmall from './LogoSmall';
import Modal from './Modal';
import Signup from '../account/Signup';
import ThemeToggle from './ThemeToggle';
import UserButton from './UserButton';
import UserSettings from './UserSettings';

const TitleBar = () => {
    const { theme } = useTheme();
    const {
        changePage,
        currentTheme,
        currentUser,
        loginMode,
        loginOpen,
        selectedApp,
        setAccountVisible,
        setAccountBillingVisible,
        setAccountAboutVisible,
        setCurrentDevice,
        setCurrentCanvas,
        setFields,
        setLoginOpen,
        setModels,
        setObjects,
        setSelectedApp,
        setSelectedField,
        setSelectedModel,
        studio,
        userRole
    } = useContext(Global);

    const [isAppSettingsOpen, setAppSettingsOpen] = useState(false);
    const [logoSrc, setLogoSrc] = useState(null);

    useEffect(() => {
        if (!selectedApp) return;

        if (currentTheme === 'DARK') {
            if (selectedApp.logoDark) {
                setLogoSrc(selectedApp.logoDark);
            }
        } else {
            if (selectedApp.logoLight) {
                setLogoSrc(selectedApp.logoLight);
            }
        }
    }, [selectedApp, currentTheme]);

    const handleLogoClick = () => {
        setAccountVisible(false);
        setAccountBillingVisible(false);
        setAccountAboutVisible(false);

        setSelectedModel(null);
        setSelectedField(null);
        setSelectedModel(null);
        setObjects([]);
        setModels([]);
        setFields([]);
        setSelectedApp(null);
        setCurrentDevice("MOBILE");
        setCurrentCanvas("FORM");

        if (currentUser) {
            changePage('HOME');
        } else {
            changePage('WELCOME');
        }
    }

    return (
        <div className="title-bar-background"
            style={selectedApp ? {
                backgroundColor: theme.backgroundColor
            } : {
                backgroundColor: studio.backgroundColor
            }}
        >
            <div className="title-bar-left">
                <div className="title-bar-logo"
                    onClick={handleLogoClick}>
                    <LogoSmall />
                </div>
                {!isMobile && selectedApp && currentUser &&
                    <div className="title-bar-app">
                        <div className="title-bar-app-slash"
                            style={selectedApp ? {
                                color: theme.foregroundColor
                            } : {
                                color: studio.foregroundColor
                            }}
                        >/</div>
                        {logoSrc &&
                            <img src={logoSrc} alt="App Logo" className="title-bar-app-logo-edit" />
                        }
                        <div
                            className="title-bar-app-title"
                            style={selectedApp ? {
                                color: theme.foregroundColor,
                                fontFamily: selectedApp && selectedApp.fontFamily
                            } : {
                                color: studio.foregroundColor
                            }}
                        >
                            {selectedApp.title}
                        </div>
                        {userRole === 'ADMIN' &&
                            <div className="title-bar-app-edit"
                                onClick={() => setAppSettingsOpen(true)}>
                                <SettingsIcon
                                    color={selectedApp ?
                                        theme.foregroundColorFaded :
                                        studio.foregroundColorFaded
                                    }
                                    width="16"
                                    height="16"
                                />
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="title-bar-right">
                <div className="title-bar-pages">
                    {selectedApp && currentUser &&
                        <>

                            {selectedApp &&
                                <ThemeToggle />
                            }

                        </>
                    }

                    {/**
                    <ReportsButton />
                     */}
                </div>

                {currentUser &&
                    <div className="title-bar-current-user" >
                        <UserButton />
                    </div>
                }
            </div>

            <Modal
                title={loginMode === "LOGIN" ? "Log In" : loginMode === "SIGNUP" ? "Sign Up" : "My Profile"}
                isOpen={loginOpen} onClose={() => setLoginOpen(false)}
                width="300px">
                {loginMode === "LOGIN" ?
                    <Login />
                    :
                    loginMode === "SIGNUP" ?
                        <Signup />
                        :
                        <UserSettings />
                }
            </Modal>

            {selectedApp &&
                <AppSettings
                    appKey={selectedApp.key}
                    isAppSettingsOpen={isAppSettingsOpen}
                    setAppSettingsOpen={setAppSettingsOpen}
                />
            }
        </div>
    );
};

export default TitleBar;
