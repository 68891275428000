import React, { useContext, useEffect, useRef, useState } from 'react';

// Global context and styles
import { Global } from '../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Drag and drop
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Formatter
import { formatDayAndDate } from '../../utilities/Formatters';

// Managers
import EventManager from '../../managers/EventManager';

// Components
import CalendarEvent from './CalendarEvent';

// Styles
import './CalendarDay.css';

// Theme
import { useTheme } from '../../ThemeContext';

const eventManager = new EventManager();

const CalendarDay = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        events,
        monthPickerVisible,
        selectedApp,
        setSelectedTime,
        setActiveDate,
        setCalendarTitle,
        setEventVisible,
        setSelectedEvent
    } = useContext(Global);

    // State Variables
    const [todaysEvents, setTodaysEvents] = useState([]);

    // Settings
    let hourLabelWidth = 60;
    let overlapIndent = 30;
    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    // Scroll to the current hour
    const hourBlocksRef = useRef(null);
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70; // Adjust if needed
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    // Initialize date
    useEffect(() => {
        if (!activeDate) {
            // Initialize with today's date if not set
            setActiveDate(new Date());
        } else {
            // Update calendar title based on activeDate
            setCalendarTitle(formatDayAndDate(new Date(activeDate)));
        }
    }, [activeDate, setCalendarTitle, setActiveDate]);

    // Gather events for the active date
    useEffect(() => {
        if (activeDate) {
            const eventManager = new EventManager();
            setTodaysEvents(eventManager.getEventsOnDate(events, activeDate));
        }
    }, [selectedApp, activeDate, events]);

    const handleEventClick = (eventKey) => {
        const event = events.find(e => e.key === eventKey);
        setSelectedEvent(event);
        setEventVisible(true);
    }

    const handleHourClick = async (hourIndex) => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Create a new date object from activeDate
        const selected = new Date(activeDate.getTime());
        selected.setHours(hourIndex);
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Display the event form
        setEventVisible(true);
    }

    const handleDrop = async (item, hourIndex) => {
        // Create a new date object from activeDate for the selected start time
        const selected = new Date(activeDate.getTime());
        selected.setHours(hourIndex);
        selected.setMinutes(0);
        selected.setSeconds(0);

        // Calculate the duration of the event
        const originalStart = item.event.startDate.toDate();
        const originalEnd = item.event.endDate.toDate();
        const duration = originalEnd - originalStart; // Duration in milliseconds

        // Calculate the new end time based on the duration
        const newEnd = new Date(selected.getTime() + duration);

        // Create the updated data object
        const data = {
            ...item.event,
            startDate: Timestamp.fromDate(selected),
            endDate: Timestamp.fromDate(newEnd)
        };

        try {
            // Update the event in the database
            await eventManager.update(selectedApp.key, item.event.key, data);
        } catch (error) {
            console.error("Error updating event: ", error);
        }
    }

    const Event = ({ event, index }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'event',
            item: { event },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));

        const eventStyle = getEventStyle(event, index);

        return (
            <>
                <div
                    ref={drag}
                    className="calendar-day-event"
                    onClick={() => handleEventClick(event.key)}
                    style={{ ...eventStyle, opacity: isDragging ? 0.5 : 1 }}>
                    <CalendarEvent key={index} event={event} index={index} />
                </div>
            </>
        );
    };

    const HourBlock = ({ hour, index }) => {
        const [, drop] = useDrop(() => ({
            accept: 'event',
            drop: (item) => handleDrop(item, index),
        }));

        return (
            <div className="calendar-day-hour-block" key={index}
                onClick={() => handleHourClick(index)}
                ref={drop}
            >
                <div className="calendar-day-hour-label"
                    style={{
                        color: theme.foregroundColor,
                        borderRightColor: theme.backgroundColorFaded,
                        width: hourLabelWidth + "px"
                    }}
                >
                    {hour}
                </div>
                <div className="calendar-day-hour-contents"
                    style={{ borderBottomColor: theme.backgroundColorFaded }}
                ></div>
            </div>
        );
    };

    const getEventStyle = (event, index) => {
        const startTime = event.startDate.toDate();
        const endTime = event.endDate.toDate();

        // Calculate top position and height
        const top = (startTime.getHours() + startTime.getMinutes() / 60) * 70; // Assuming 70px per hour block
        const height = ((endTime - startTime) / (1000 * 60 * 60)) * 70 - 2; // Duration in hours * height per hour

        // Calculate left position for overlapping events
        let left = 0;
        let overlapCount = 0;

        // Check for overlapping events
        for (let i = 0; i < index; i++) {
            const otherEvent = todaysEvents[i];
            const otherStartTime = otherEvent.startDate.toDate();
            const otherEndTime = otherEvent.endDate.toDate();

            // Check if events overlap
            if ((startTime >= otherStartTime && startTime < otherEndTime) || (endTime > otherStartTime && endTime <= otherEndTime)) {
                overlapCount++;
            }
        }

        left = hourLabelWidth + (overlapCount * overlapIndent); // Adjust this value to set the indent for overlapping events

        return {
            top: `${top}px`,
            height: `${height}px`,
            left: `${left}px`,
            backgroundColor: theme.highlightBackgroundColor,
            borderColor: theme.backgroundColor,
            overflow: 'hidden', // Hide overflow
        };
    };

    // Calculate the dynamic height based on the state
    const wrapperHeight = `calc(100vh - ${monthPickerVisible ? '315px' : '100px'})`;

    // Use inline style to adjust height dynamically
    const wrapperStyle = {
        height: wrapperHeight,
        overflowY: 'auto',
        width: '100%',
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={wrapperStyle} ref={hourBlocksRef}>
                <div className="calendar-day-container" ref={hourBlocksRef}>
                    {/* Hour blocks */}
                    {hours.map((hour, index) => (
                        <HourBlock key={index} hour={hour} index={index} />
                    ))}

                    {/* Events overlay, positioned to overlap the hour blocks but still part of the scrollable content */}
                    <div className="calendar-day-events-overlay">
                        {todaysEvents && todaysEvents.map((event, index) => (
                            <Event key={index} event={event} index={index} />
                        ))}
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default CalendarDay;
