import React from 'react';

// Styles
import './DeletedHeadline.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const DeletedHeadline = ({ headline, handleUserClick, handleModelClick, timestamp }) => {
    const { theme } = useTheme();

    return (
        <>
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleUserClick(headline.userKey)}>
                {headline.userFirstName} {headline.userLastName}
            </span>&nbsp;
            removed {headline.objectTitle} from &nbsp;
            <span
                className="run-home-token"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleModelClick(headline.modelKey)}>
                {headline.modelTitle}
            </span>.&nbsp;
            {timestamp}
        </>
    );
};

export default DeletedHeadline;
