import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { getElapsedTime } from '../../utilities/DateTime';

// Styles
import './ConversationSummary.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Inline Styles
import { createGlobalStyle } from 'styled-components';

// Components
import UserDot from '../../foundation/UserDot';

// Styled Components
const InlineStyles = createGlobalStyle`
    .conversation-summary-user:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const ConversationSummary = ({ conversation }) => {
    const { theme } = useTheme();
    const { currentUser } = useContext(Global);

    // Function to extract first and last initials from a name
    const getInitials = (name) => {
        const nameParts = name.split(' ');
        const firstInitial = nameParts[0][0].toUpperCase();
        return { firstInitial };
    };

    // Get the non-current-user participants
    const nonCurrentUserParticipants = conversation.participants
        ? conversation.participants.filter(p => p.userKey !== currentUser.key)
        : [];

    // Get the first participant who isn't the current user
    const firstParticipant = nonCurrentUserParticipants.length > 0
        ? nonCurrentUserParticipants[0]
        : null;

    // Get the number of additional participants
    const additionalParticipantsCount = nonCurrentUserParticipants.length - 1;

    // Set the display name for the conversation summary
    const displayName = firstParticipant
        ? `${firstParticipant.userName}${additionalParticipantsCount > 0 ? ` + ${additionalParticipantsCount} Others` : ''}`
        : '';

    const initials = firstParticipant ? getInitials(firstParticipant.userName) : { firstInitial: '' };

    // Trim the last message text to 200 characters
    const trimmedLastMessage = conversation.lastMessage
        ? conversation.lastMessage.text.slice(0, 200)
        : '';

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="conversation-summary-container">
                <div className="conversation-summary-left">
                    {firstParticipant && (
                        <UserDot
                            firstInitial={initials.firstInitial}
                            size="32"
                        />
                    )}
                </div>
                <div className="conversation-summary-right">
                    <div className="conversation-summary-top">
                        <div className="conversation-summary-name"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {displayName}
                        </div>
                        {conversation && conversation.lastMessage && conversation.lastMessage.dateCreated &&
                            <div className="conversation-summary-elapsed"
                                style={{
                                    color: theme.backgroundColorFaded
                                }}>
                                {getElapsedTime(conversation.lastMessage.dateCreated)}
                            </div>
                        }
                    </div>
                    <div className="conversation-summary-last-message"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {trimmedLastMessage && (
                            <div className={conversation.lastMessageRead ? "conversation-summary-last-read" : "conversation-summary-last-unread"}>
                                {trimmedLastMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConversationSummary;
