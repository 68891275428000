import React from 'react';

// Styles
import './RunHomeToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import RunHomeMenu from './RunHomeMenu';

const RunHomeToolBar = () => {
    const { theme } = useTheme();

    return (
        <div className="run-home-toolbar-container">
            <div className="run-home-toolbar-left"
                style={{color: theme.foregroundColorFaded}}
                >
                Latest Updates
            </div>
            <div className="run-home-toolbar-right">
                <div className="run-home-toolbar-edit-wrapper">
                    <RunHomeMenu />
                </div>
            </div>
        </div>
    );
};

export default RunHomeToolBar;
