import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Styles
import './PayHeader.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import BackButton from '../run/BackButton';

const PayHeader = () => {
    const { theme } = useTheme();
    const { 
        payMode,
        setPayVisible,
    } = useContext(Global);

    /**
     * BACK BUTTON CLICK
     */
    const handleBack = () => {
        setPayVisible(false);
    }

    return (

        <div className="pay-header-container">
            <div className="pay-header-back-wrapper"
                onClick={handleBack}>
                <BackButton />
            </div>
            <div className="pay-header-title" 
                style={{color: theme.foregroundColorFaded}}
            >
                {payMode === "SUBSCRIBE" ? (
                    <>
                        Subscribe
                    </>
                ) : (
                    <>
                        Pay
                    </>
                )}
            </div>
        </div>

    );
};

export default PayHeader;
